import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Popconfirm, Modal, Input, message, Icon, Switch, Row, Col } from 'antd';
import moment from 'moment';
import { State, Target } from '@/store/types/state';
import request from '@/http/request';
import IconFont from '@/components/IconFont';
import {
  createGetWxFriendListAction,
  createGetWxSessionListAction,
  createGetGroupMemberListAction,
  createSetCurrentTargetAction,
} from '@/store/action';
import GroupMembers from './GroupMembers';
import MessageSender from '../../../../component/MessageSender';
import EmojiSuggestions from '../../../../component/EmojiSuggestions';

moment.locale('zh-cn');
const DELETEANDRETURNAPI = '/v1/group/quit';
const CHANGEQUNNAMEAPI = '/v1/group/change_name';

interface CustomerInfoState {
  changeNameDialogShow: boolean;
  showQRCodeModalVisible: boolean;
  showAnnouncementVisible: boolean;
  qRCodeUrlInfo: any;
  auditStatus: number;
  detailData: any;
  currRole: number;
  qunName: string;
  pos: any;
  editorValue: string;
}

const rowStyle = {
  marginBottom: 16,
};

class GroupPanel extends Component<any, CustomerInfoState> {
  state = {
    changeNameDialogShow: false,
    showQRCodeModalVisible: false,
    showAnnouncementVisible: false,
    detailData: null,
    auditStatus: 0, // auditStatus == 2 表示群主开启了进群审核
    currRole: 0,
    qRCodeUrlInfo: {
      qrcodeUrl: '',
      avatar: '', // 头像
      name: '', // 群头像
      createAt: '', // 创建时间
      memberCount: 0,
    },
    qunName: '',
    pos: { start: 0, end: 0 },
    editorValue: '',
  };

  nextIndex: number = 0;

  loading: boolean = false;

  addQunMemberChildCopyData: any;

  child: any;

  componentDidUpdate(prevProps: any) {
    if (
      (!prevProps.info && this.props.info) ||
      (prevProps.info && prevProps.info.wxGroup.groupWxId && prevProps.info.wxGroup.groupWxId) !==
        (this.props.info &&
          this.props.info.wxGroup.groupWxId &&
          this.props.info.wxGroup.groupWxId) ||
      (prevProps.info &&
        prevProps.info.chatType &&
        prevProps.info.chatType !== this.props.info.chatType)
    ) {
      this.getDetailData();
      this.getMemberList();
    }
  }

  getDetailData = () => {
    const {
      target,
      info: { owner, wxGroup },
    } = this.props;
    if (target && target.type === 'TARGET_TYPE_SESSION') {
      this.setState({
        detailData: wxGroup,
        currRole: owner.role,
        auditStatus: wxGroup.auditStatus,
      });
    }
  };

  // 删除并退出
  deleteAndReturn = () => {
    const { getSessionList, setCurrentTarget, currGroupId, clients, currSessionId } = this.props;
    request
      .post(DELETEANDRETURNAPI, {
        session_id: currSessionId,
        group_id: currGroupId,
        wx_id: clients.current.wxId,
      })
      .then(() => {
        setCurrentTarget(null);
        getSessionList();
      });
  };

  reload = () => {
    this.getDetailData();
    this.getMemberList();
  };

  getMemberList = (searchKey: any = '') => {
    this.props.newGetMemeberList({
      groupId: this.props.currGroupId,
      start: 0,
      q: searchKey,
    });
  };

  // deleteMember = (wxId: number, currClientWxId: any) => {
  //   request
  //     .post(DELETEMEMBERAPI, {
  //       group_id: this.props.currGroupId,
  //       member_list: `${wxId}`,
  //       from_wx_id: currClientWxId,
  //     })
  //     .then(() => {
  //       this.getMemberList(true);
  //       this.props.init();
  //     });
  // };

  qunNameChangeFn(e: any) {
    this.setState({ qunName: e.target.value });
  }

  qunNameChangeSubmitFn = () => {
    const { qunName } = this.state;
    const {
      currentGroupInfo: {
        group: { groupName },
      },
    } = this.props;
    if (!qunName) {
      message.warning('群名称不能为空');
      return;
    }
    if (groupName === qunName) {
      message.warning('群名称无修改！');
      return;
    }
    if (qunName.length > 16) {
      message.warning('输入的群聊名称超出了16个字上限');
      return;
    }
    request
      .post(CHANGEQUNNAMEAPI, {
        new_name: qunName,
        group_id: this.props.currGroupId,
        wx_id: this.props.clients.current.wxId,
      })
      .then(() => {
        this.setState({ qunName: '' });
        this.switchChangeQunNameDialogFn();
        this.props.getSessionList();
        this.reload();
      });
  };

  refreshQunList = () => {
    this.getMemberList();
  };

  switchChangeQunNameDialogFn = () => {
    const {
      currentGroupInfo: {
        group: { groupName },
      },
    } = this.props;
    this.setState((prevState: any) => ({
      changeNameDialogShow: !prevState.changeNameDialogShow,
      qunName: !prevState.changeNameDialogShow ? groupName : '',
    }));
  };

  getQunQRCodeInfo = () => {
    request.get(`im/v2/groups/${this.props.currGroupId}/qrcode`).then((res: any) => {
      this.setState({
        showQRCodeModalVisible: true,
        qRCodeUrlInfo: res.data,
        auditStatus: res.data.auditStatus,
      });
    });
  };

  handlerChangeAudit = (e: any) => {
    request
      .put(
        `im/v2/groups/${this.props.currGroupId}/audit`,
        {
          status: e ? 1 : 0, // status:1 开启审核
          wxId: this.props.clients.current.wxId,
        },
        true,
      )
      .then((res: any) => {
        this.setState({ auditStatus: res.data.auditStatus });
      });
  };

  changeAnnouncementHandle = () => {
    const { currentGroupInfo } = this.props;
    this.setState({
      editorValue:
        (currentGroupInfo && currentGroupInfo.group && currentGroupInfo.group.announcement) || '',
      showAnnouncementVisible: true,
    });
  };

  handlerImgTextChange = (value: string) => {
    const { editorValue, pos } = this.state;
    const newString =
      editorValue.substring(0, pos.start) +
      value +
      editorValue.substring(pos.end, editorValue.length);
    this.setState({
      editorValue: newString,
      pos: {
        start: pos.start + value.length,
        end: pos.start + value.length,
      },
    });
  };

  handleChange = (e: any) => {
    const s = this.state;
    this.setState({
      ...s,
      ...e,
    });
  };

  changeAnnouncement = () => {
    const { editorValue } = this.state;
    if (editorValue.length > 1000) {
      message.warning('输入的群公告超出了1000个字上限');
      return;
    }
    request
      .post('im/v2/group/announcements', {
        announcement: editorValue,
        groupId: this.props.currGroupId,
        senderWxId: this.props.clients.current.wxId,
      })
      .then(() => {
        this.setState({ editorValue: '' });
        this.setState({ showAnnouncementVisible: false });
        this.props.getSessionList();
        this.reload();
      });
  };

  render() {
    const {
      changeNameDialogShow,
      showQRCodeModalVisible,
      showAnnouncementVisible,
      qRCodeUrlInfo,
      auditStatus,
      detailData,
      currRole,
      pos,
      editorValue,
    } = this.state;
    const { target, currentGroupInfo } = this.props;
    return (
      <>
        {detailData === null ? null : (
          <>
            <div className="functionWindow-tabs-header">
              <span className="im-title-font-large" style={{ height: '32px', lineHeight: '32px' }}>
                群资料
                <IconFont
                  style={{ fontSize: '15px', marginLeft: '10px' }}
                  iconfont="iconshuaxin"
                  onClick={this.reload}
                ></IconFont>
              </span>
            </div>

            <div className="scroll-box" id="scroll-box-5">
              <Card
                className="im-card im-body-font-small"
                bordered={false}
                bodyStyle={{ padding: '0 12px' }}
              >
                <Row style={rowStyle}>
                  <Col span={6}>群聊名称</Col>
                  <Col span={18}>
                    <span className="im-title-font-small">
                      {(detailData as any).groupName || '未命名'}
                      <IconFont
                        onClick={this.switchChangeQunNameDialogFn}
                        style={{ marginLeft: 5 }}
                        iconfont="iconbianji"
                      ></IconFont>
                    </span>
                  </Col>
                </Row>
                <Row style={rowStyle}>
                  <Col span={6}>所属分组</Col>
                  <Col span={18}>
                    <span className="im-title-font-small">
                      {((detailData as any).division && (detailData as any).division.name) || '无'}
                    </span>
                  </Col>
                </Row>
                <Row style={rowStyle}>
                  <Col span={6}>群公告</Col>
                  <Col span={18}>
                    <span className="im-title-font-small">
                      <IconFont
                        onClick={this.changeAnnouncementHandle}
                        style={{ marginLeft: 5 }}
                        iconfont="iconbianji"
                      ></IconFont>
                      {/* {(currentGroupInfo &&
                        currentGroupInfo.group &&
                        currentGroupInfo.group.announcement) ||
                        '无'}
                      {currRole === 0 ? (
                        ''
                      ) : (
                        <IconFont
                          onClick={this.changeAnnouncementHandle}
                          style={{ marginLeft: 5 }}
                          iconfont="iconbianji"
                        ></IconFont>
                      )} */}
                    </span>
                  </Col>
                </Row>
                <Row style={rowStyle}>
                  <Col span={6}>群二维码</Col>
                  <Col span={18}>
                    <Icon
                      type="qrcode"
                      style={{ zoom: '200%' }}
                      onClick={() => {
                        this.getQunQRCodeInfo();
                      }}
                    ></Icon>
                  </Col>
                </Row>
                {/* {currRole === 2 && currentGroupInfo && currentGroupInfo.group.memberCount > 39 ? (
                  <div className="im-title-font im-bold" style={{ marginBottom: '12px' }}>
                    群管理权限
                    {target.chatId !== '' && currentGroupInfo !== null ? (
                      <GroupManager
                        groupId={target.chatId}
                        getMoreMemeberList={this.props.newGetMemeberList}
                      />
                    ) : null}
                  </div>
                ) : (
                  ''
                )} */}
                <div className="im-title-font im-bold" style={{ marginBottom: '12px' }}>
                  其他设置
                </div>
                <div>
                  {currRole === 1 || currRole === 2 ? (
                    <p className="im-title-font-small">
                      群聊邀请确认：
                      <Switch
                        checked={!!auditStatus}
                        onChange={this.handlerChangeAudit}
                        size="small"
                      />
                    </p>
                  ) : (
                    ''
                  )}
                  {(currRole === 2 &&
                    currentGroupInfo !== null &&
                    currentGroupInfo.list &&
                    currentGroupInfo.list.length <= 1) ||
                  currRole !== 2 ? (
                    <Popconfirm
                      title={`确认删除${(detailData as any).groupName || '未命名'}？`}
                      okText="确认"
                      cancelText="取消"
                      onConfirm={this.deleteAndReturn}
                    ></Popconfirm>
                  ) : (
                    ''
                  )}
                </div>
              </Card>
              <Card
                bodyStyle={{ padding: '0 12px' }}
                bordered={false}
                className="group-style im-card"
              >
                {target.chatId !== '' && currentGroupInfo !== null ? (
                  <GroupMembers
                    groupId={target.chatId}
                    count={currentGroupInfo.count}
                    currRole={currRole}
                    getMoreMemeberList={this.props.newGetMemeberList}
                  />
                ) : null}
                <Modal
                  onCancel={this.switchChangeQunNameDialogFn}
                  okText="确定"
                  cancelText="取消"
                  onOk={this.qunNameChangeSubmitFn}
                  title="修改群名称"
                  visible={changeNameDialogShow}
                  centered={false}
                  closable={false}
                >
                  <Input
                    style={{ margin: '0 auto', width: '100%' }}
                    placeholder="请输入群名，上限16"
                    title="添加成员"
                    onChange={(e: any) => this.qunNameChangeFn(e)}
                    allowClear
                    value={this.state.qunName}
                  />
                </Modal>
                <Modal
                  bodyStyle={{
                    padding: '0 24px 24px',
                  }}
                  title="群二维码"
                  visible={showQRCodeModalVisible}
                  onCancel={() => {
                    this.setState({ showQRCodeModalVisible: false });
                  }}
                  footer={null}
                >
                  <div>
                    <div>
                      <img alt="headImg" className="qrCode-headImg" src={qRCodeUrlInfo.avatar} />
                      <span className="qrCode-qunName">{qRCodeUrlInfo.name || '未命名'}</span>
                    </div>
                    <img alt="qrCode" className="qrCode-size" src={qRCodeUrlInfo.qrcodeUrl} />
                    {!auditStatus && qRCodeUrlInfo.memberCount < 100 ? (
                      <div className="qrCode-tips im-caption-font-large">
                        该二维码7天内（
                        {moment(
                          24 * 60 * 60 * 1000 * 7 + Date.parse(qRCodeUrlInfo.createAt),
                        ).format('MM月DD日前')}
                        ）有效，重新进入将更新
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </Modal>
                <Modal
                  width={618}
                  visible={showAnnouncementVisible}
                  onCancel={() => {
                    this.setState({ showAnnouncementVisible: false });
                  }}
                  okText="确定"
                  cancelText="取消"
                  onOk={this.changeAnnouncement}
                  title="修改群公告"
                  centered={false}
                  closable={false}
                >
                  <div className="chat-sub-title">群公告</div>
                  <div className="chat-pannel1">
                    <div className="chat-pannel-header1">
                      <div className="chat-panel-header-left">
                        <EmojiSuggestions onSelectChange={this.handlerImgTextChange} />
                      </div>
                    </div>
                    <div className="chat-pannel-content1">
                      <MessageSender
                        pos={pos}
                        editorValue={editorValue.replace(/(^\n*)|(^\r)|(^\r\n)/, '')}
                        onChange={this.handleChange}
                        placeholder="限制1000个字"
                      ></MessageSender>
                    </div>
                  </div>
                </Modal>
              </Card>
            </div>
          </>
        )}
      </>
    );
  }
}

function mapStateToProps(state: State) {
  return {
    target: state.currentTarget,
    friendList: state.friendList,
    clients: state.clients,
    currentGroupInfo: state.currentGroupInfo,
    info: state.info,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    setCurrentTarget: (data: Target) => dispatch(createSetCurrentTargetAction(data)),
    getFriendList: () => dispatch(createGetWxFriendListAction({})),
    getSessionList: () => dispatch(createGetWxSessionListAction({ limit: 5000 })),
    newGetMemeberList: (param: any, merge: boolean) =>
      dispatch(createGetGroupMemberListAction(param, merge)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupPanel);

import React, { useState } from 'react';
import { Checkbox } from 'antd';

interface CheckboxGroupProps {
  record: any;
  addSelected: (param: any) => void;
  delSelected: (param: any) => void;
}
const CheckboxGroup = (props: CheckboxGroupProps) => {
  const { record, addSelected, delSelected } = props;
  const [selectedCheckbox, setSelectedCheckbox] = useState<any>([]);

  const handleChange = (e: any, r: any, i: number) => {
    if (e.target.checked) {
      addSelected({
        groupId: r.groupId,
        playbookName: r.playbooksInfo[i].playbookName,
        taskId: e.target.value,
      });
      setSelectedCheckbox([e.target.value]);
    } else {
      delSelected({
        groupId: r.groupId,
        playbookName: r.playbooksInfo[i].playbookName,
        taskId: e.target.value,
      });
      setSelectedCheckbox([]);
    }
  };

  return (
    <Checkbox.Group value={selectedCheckbox} style={{ width: '100%' }}>
      {record.playbooksInfo.map((item: any, index: number) => (
        <div key={item.taskId} style={{ height: 25, marginTop: 5 }}>
          <Checkbox value={item.taskId} onChange={(e: any) => handleChange(e, record, index)} />
        </div>
      ))}
    </Checkbox.Group>
  );
};

export default CheckboxGroup;

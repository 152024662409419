import React, { useEffect, useRef, useState } from 'react';
import {
  Layout,
  Empty,
  Button,
  Tabs,
  Table,
  message,
  Tag,
  Popconfirm,
  Form,
  Select,
  Modal,
} from 'antd';
import request from '@/http/request';
import CheckboxGroup from './CheckboxGroup';

const { TabPane } = Tabs;

interface SendDataProps {
  currGroupId: string;
  currentWindowTab: any;
}
const SendData: React.FC<SendDataProps> = props => {
  const timerRef = useRef<NodeJS.Timeout>();
  const { currGroupId, currentWindowTab } = props;
  const [tableData, setTableData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [perpage, setPerpage] = useState(20);
  const [current, setCurrent] = useState(0);
  const [total, setTotal] = useState(0);
  const [finalTaskIds, setFinalTaskIds] = useState<any>([]);
  const [taskIds, setTaskIds] = useState<any>([]);
  const [selectedPlaybook, setSelectedPlaybook] = useState<any>([]);
  const [winTab, setCurrentWinTab] = useState<any>('0');
  const [showBatchModal, setShowBatchModal] = useState<boolean>(false);
  const [playBookList, setPlayBookList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [playbookId, setPlaybookId] = useState<number>(0);
  const [divisionIds, setDivisionIds] = useState<string[]>([]);
  const [playingNum, setPlayingNum] = useState<number>(0);

  const statusMap = ['未执行', '执行中', '暂停', '播放完成'];

  const getData = (cur?: any) => {
    if (+winTab !== 0) {
      return;
    }
    setLoading(true);
    request
      .get('/im/v2/auto/task', {
        perpage,
        page: cur || current,
      })
      .then((res: any) => {
        setLoading(false);
        if (res.code === 200 && res.data.list.length) {
          setTableData(res.data.list);
          setTotal(res.data.count);
          let temp = 0;
          res.data.list.forEach((el: any) => {
            el.playbooksInfo.forEach((item: any) => {
              if (item.playStatus === 1) {
                temp += 1;
              }
            });
          });
          setPlayingNum(temp);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const batchAction = (send: boolean) => {
    request
      .post('/im/v2/submit/auto/task', {
        taskIds,
        send,
      })
      .then((res: any) => {
        if (res.code === 200) {
          message.success(res.msg);
          getData();
        } else {
          message.error(res.msg);
        }
      });
  };

  const quickBatchAction = (send: boolean) => {
    setLoading2(true);
    const url = send ? '/im/v2/auto/task/fast_start' : '/im/v2/auto/task/fast_pause';
    request
      .post(url, {
        divisionIds: divisionIds.map(item => item.split('.')[0]),
        playbookId,
      })
      .then((res: any) => {
        setLoading2(false);
        setShowBatchModal(false);
        if (res.code === 200) {
          message.success(res.msg);
          getData();
        } else {
          message.error(res.msg);
        }
      })
      .catch(() => {
        setLoading2(false);
        setShowBatchModal(false);
      });
  };

  const addSelected = (params: any) => {
    const temp = finalTaskIds;
    const temp2 = temp.filter((item: any) => item.groupId === params.groupId);
    if (temp2 && temp2.length) {
      temp2[0].taskId = params.taskId;
      temp2[0].playbookName = params.playbookName;
      setFinalTaskIds([...finalTaskIds]);
    } else {
      setFinalTaskIds([...finalTaskIds, ...[params]]);
    }
  };

  const delSelected = (params: any) => {
    if (params) {
      const temp = finalTaskIds;
      setFinalTaskIds(temp.filter((i: any) => +i.taskId !== +params.taskId));
    }
  };

  const columns = [
    {
      title: '群名',
      dataIndex: 'groupName',
      rowKey: 'groupName',
      width: 20,
    },
    {
      title: '发送进度',
      dataIndex: 'playbooksInfo',
      rowKey: 'playbooksInfo',
      width: 60,
      render: (record: any) => (
        <div>
          {record.map((item: any) => (
            <div key={item.taskId} style={{ height: 25, marginTop: 5 }}>
              <p>{`${item.sendFinishNum}/${item.allItemsNum}`}</p>
            </div>
          ))}
        </div>
      ),
    },
    {
      title: `发送状态\t正在播放${playingNum}`,
      rowKey: 'status',
      render: (record: any) => (
        <div>
          {record.playbooksInfo.map((item: any) => (
            <div key={item.taskId} style={{ height: 25, marginTop: 5 }}>
              <p>
                {item.playbookName}
                <span
                  style={
                    +item.playStatus === 1
                      ? { color: 'green', paddingLeft: 5 }
                      : { color: '#000', paddingLeft: 5 }
                  }
                >
                  {statusMap[item.playStatus]}
                </span>
              </p>
            </div>
          ))}
        </div>
      ),
    },
    {
      title: '操作',
      rowKey: 'checked',
      width: 20,
      render: (record: any) => (
        <div>
          <CheckboxGroup
            record={record}
            addSelected={(param: any) => addSelected(param)}
            delSelected={(param: any) => delSelected(param)}
          />
        </div>
      ),
    },
  ];

  const pagination: any = {
    pageSize: perpage,
    total,
    current: current + 1,
    showTotal: () => `共${total} 条`,
    onChange: (cur: any, pageSize: number) => {
      setCurrent(cur - 1);
      setPerpage(pageSize);
      getData(cur - 1);
    },
  };

  useEffect(() => {
    if (currGroupId) {
      getData();
    }
  }, [currGroupId]);

  useEffect(() => {
    const temp: any[] = [];
    const temp2: any[] = [];
    finalTaskIds.forEach((element: any) => {
      if (element.taskId && element.taskId !== undefined) {
        temp.push(element.taskId);
        temp2.push(element.playbookName);
      }
    });
    setTaskIds(Array.from(new Set(temp)));
    setSelectedPlaybook(Array.from(new Set(temp2)));
  }, [finalTaskIds]);

  useEffect(() => {
    request.get('/md/v2/user/playbooks').then((res: any) => {
      setPlayBookList(res.data.list);
    });
    request.get('/md/v2/user/divisions').then((res: any) => {
      setGroupList(res.data.list);
    });
  }, []);

  useEffect(() => {
    timerRef.current = setInterval(() => {
      getData();
    }, 5000);
    return () => clearInterval(timerRef.current as NodeJS.Timeout);
  }, [tableData]);

  useEffect(() => {
    setCurrentWinTab(currentWindowTab);
    getData();
  }, [currentWindowTab]);

  return (
    <div>
      {currGroupId ? (
        <Layout className="sendData-wrapper">
          <div
            style={{
              marginBottom: '10px',
              position: 'sticky',
              top: -10,
              background: 'rgba(233, 233, 233, 1)',
              zIndex: 10,
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div>
                <Popconfirm
                  style={{ marginTop: 5, marginBottom: 5 }}
                  title="确定开始吗?"
                  onConfirm={() => batchAction(true)}
                  okText="确定"
                  cancelText="取消"
                >
                  <Button className="im-btn-default im-btn-icon" type="primary">
                    批量开始
                  </Button>
                </Popconfirm>
              </div>
              <div>
                <Popconfirm
                  title="确定暂停吗?"
                  onConfirm={() => batchAction(false)}
                  okText="确定"
                  cancelText="取消"
                >
                  <Button
                    style={{ marginTop: 5, marginBottom: 5 }}
                    className="im-btn-icon"
                    type="danger"
                    ghost
                  >
                    批量暂停
                  </Button>
                </Popconfirm>
              </div>
              <div>
                <Button
                  className="im-btn-default im-btn-icon"
                  style={{ marginTop: 5, marginBottom: 5 }}
                  type="primary"
                  ghost
                  onClick={() => setShowBatchModal(true)}
                >
                  快速批量操作
                </Button>
              </div>
            </div>
            <div style={{ color: '#333' }}>
              <div>已选群个数: {taskIds.length}</div>
              <div>
                已选择剧本:
                {selectedPlaybook.map((item: any) => (
                  <Tag style={{ marginLeft: 3, marginTop: 5 }} key={item} color="blue">
                    {item}
                  </Tag>
                ))}
              </div>
            </div>
          </div>
          <div className="scroll-box">
            <Tabs hideAdd defaultActiveKey="1">
              <TabPane tab="发送详情" key="1">
                {tableData.length ? (
                  <Table
                    className="im-table"
                    columns={columns}
                    dataSource={tableData}
                    loading={loading}
                    bordered
                    pagination={pagination}
                  />
                ) : (
                  <Empty className="im-empty-status" description="暂无数据" />
                )}
              </TabPane>
              <TabPane tab="其他" key="2">
                <Empty className="im-empty-status" description="暂无数据" />
              </TabPane>
            </Tabs>
          </div>
        </Layout>
      ) : (
        <Empty image="/null.png" className="im-empty-status" description="没有选择聊天" />
      )}
      <Modal
        title="导入剧本快速批量操作"
        visible={showBatchModal}
        footer={null}
        onCancel={() => setShowBatchModal(false)}
        width={600}
      >
        <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
          <Form.Item label="选择群组">
            <Select
              placeholder="选择群组"
              defaultActiveFirstOption={false}
              style={{ width: '300px', display: 'block' }}
              mode="multiple"
              onChange={(item: any) => {
                console.log(item);
                setDivisionIds(item);
              }}
            >
              {groupList.map((item: any) => (
                <Select.Option value={`${item.id}.${item.groupCount}`} key={item.id}>
                  {item.name}({item.groupCount})
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="选择剧本">
            <Select
              defaultActiveFirstOption={false}
              style={{ width: 240 }}
              placeholder="选择剧本"
              {...props}
              onSelect={(item: any) => setPlaybookId(+item)}
            >
              {playBookList &&
                playBookList.map((item: any) => (
                  <Select.Option key={item.id} value={String(item.id)}>
                    {item.name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          当前选择群数:
          {divisionIds
            .map(item => parseInt(item.split('.')[1], 10))
            .reduce((a, b) => {
              return a + b;
            }, 0)}
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>
            <Popconfirm
              title="确定开始吗?"
              onConfirm={() => quickBatchAction(true)}
              okText="确定"
              cancelText="取消"
            >
              <Button style={{ marginRight: 5 }} type="primary">
                批量开始
              </Button>
            </Popconfirm>
            <Popconfirm
              title="确定暂停吗?"
              onConfirm={() => quickBatchAction(false)}
              okText="确定"
              cancelText="取消"
            >
              <Button ghost type="danger">
                批量暂停
              </Button>
            </Popconfirm>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default SendData;

import React from 'react';
import moment from 'moment';
import { Button, Icon, Table } from 'antd';
import request from '@/http/request';
import './index.less';

interface State {
  page: number;
  perpage: number;
  total: number;
  imageTextLinkList: any;
  selectItem: any;
}
class ImageTextLink extends React.Component<any, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      page: 0,
      perpage: 20,
      total: 0,
      imageTextLinkList: [],
      selectItem: {},
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    request
      .get('/im/v2/collect/list', {
        perpage: this.state.perpage,
        page: this.state.page ? this.state.page - 1 : 0,
      })
      .then((res: any) => {
        this.setState({
          total: res.data.total,
          perpage: res.data.perpage,
          imageTextLinkList: res.data.list,
        });
      });
  };

  handleSelect = (row: any) => {
    this.setState({
      selectItem: row,
    });
  };

  deleteSelect = () => {
    this.setState({
      selectItem: {},
    });
  };

  columns = [
    {
      title: '发布时间',
      dataIndex: 'sendTime',
      key: 'sendTime',
      render: (sendTime: string) => moment(sendTime).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: '网页标题',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: '摘要',
      dataIndex: 'desc',
      key: 'desc',
    },
    {
      title: '链接',
      dataIndex: 'url',
      key: 'url',
      render: (url: string) => (
        <a href={url} target="_blank" rel="noopener noreferrer">
          查看链接
        </a>
      ),
    },
    {
      title: '操作',
      dataIndex: 'mid',
      key: 'mid',
      render: (mid: string, row: any) => <a onClick={() => this.handleSelect(row)}>选择</a>,
    },
  ];

  render() {
    const { perpage, total, imageTextLinkList, selectItem } = this.state;
    return (
      <div>
        {selectItem.id ? (
          <div className="share-card-container">
            <div className="share-card">
              <Icon type="close" className="share-click" onClick={this.deleteSelect} />
              <div className="share-title">{selectItem.title}</div>
              <div className="share-content">
                <div className="share-text">{selectItem.desc}</div>
                <img alt="图片" className="share-img" src={selectItem.imageUrl} />
              </div>
            </div>
            <Button
              type="primary"
              className="share-button"
              onClick={() =>
                this.props.onOk({
                  ...selectItem,
                  linkUrl: selectItem.url,
                  mediaUrl: selectItem.imageUrl,
                  description: selectItem.desc,
                })
              }
            >
              确认发送
            </Button>
          </div>
        ) : null}
        <Table
          dataSource={imageTextLinkList}
          columns={this.columns}
          rowKey={(record: any) => record.id}
          scroll={{ y: 400 }}
          pagination={{
            current: this.state.page - 0,
            pageSize: perpage,
            total,
            showTotal: () => `共${total} 条`,
            onChange: (page: number) => {
              this.setState(
                {
                  page,
                },
                () => {
                  this.fetchData();
                },
              );
            },
          }}
        />
      </div>
    );
  }
}

export default ImageTextLink;

import React, { Fragment, memo, useCallback, useState } from 'react';
import { Divider, List, Popover, Button, Row, Col } from 'antd';
import request from '@/http/request';
import { WxSession } from '@/store/types/state';
import ShowModal from '@/components/ShowModal';
import {
  TARGET_TYPE_NEW_FRIEND,
  TARGET_TYPE_NEW_GROUP,
  TARGET_TYPE_SESSION,
} from '../../../../../../store/constant';
import './index.less';

const miniHeadImage = (headImg: string = '') => {
  const size = headImg.indexOf('wework') > -1 ? 100 : 132;
  return headImg && headImg.substr(-2) === '/0'
    ? `${headImg.substr(0, headImg.length - 1)}${size}`
    : headImg;
};

const ConfirmModal = memo((props: any) => {
  return (
    <div>
      <p>用管理员回复会增加封群概率是否确认？</p>
      <div>
        <Button
          onClick={() => {
            ShowModal.close();
          }}
        >
          取消
        </Button>
        <Button style={{ marginLeft: '20px' }} type="primary" onClick={props.onConfirmClick}>
          确认
        </Button>
      </div>
    </div>
  );
});

interface Iprops {
  currentClient: any;
  topic: any;
  owner: any;
  wxGroup: any;
  type: string;
  chatType: number;
  id: number;
  roleId: number;
  groupGenderInfo: {
    male: number;
    female: number;
    unknown: number;
  };
  replaceOneSession: (data: { session: WxSession; oldId: number }) => any;
}

function useSwitchAccount(props: Iprops) {
  const [visible, setVisible] = useState(false);
  const [managers, setManagers] = useState<any[]>([]);
  const [pubs, setPubs] = useState<any[]>([]);
  const [shills, setShills] = useState<any[]>([]);

  const handleConirm = useCallback(
    (item: any) => {
      ShowModal.close();
      const putData = async () => {
        const res = await request.put(`/im/v2/groups/${props.owner.groupId}/manager`, {
          wxId: item.wxId,
        });
        props.replaceOneSession({ session: res.data, oldId: props.id });
      };
      if (props.owner.wxId !== item.wxId) putData();
    },
    [props.owner, props.id, props.replaceOneSession],
  );

  const handleClick = useCallback(
    (item: any, isManager?: boolean) => {
      setVisible(false);
      if (isManager) {
        ShowModal({
          props: {
            onConfirmClick: () => {
              handleConirm(item);
            },
          },
          destroyOnClose: 'destroyOnClose',
          hasFooter: false,
          childrens: [ConfirmModal],
        });
      } else {
        handleConirm(item);
      }
    },
    [props.owner, props.id, props.replaceOneSession],
  );

  const handleVisible = useCallback(
    (val: boolean) => {
      if (props.chatType === 3) return;
      const fetchData = async () => {
        const res = await request.get(`/im/v2/groups/${props.owner.groupId}/manager`);
        setManagers(res.data.managers || []);
        setPubs(res.data.pubs || []);
        setShills(res.data.shills || []);
      };
      if (val) fetchData();
      setVisible(val);
    },
    [setVisible, props.chatType, props.owner],
  );

  return {
    managers,
    pubs,
    shills,
    visible,
    handleClick,
    handleVisible,
  };
}

const ChatPanelHeader: React.FC<Iprops> = props => (
  <Fragment>
    {(() => {
      const { topic, wxGroup, owner, groupGenderInfo, roleId } = props;
      const { male = 0, female = 0, unknown = 0 } = groupGenderInfo || {};
      const knownCount = male + female; // wxGroup.memberCount有延迟
      const { managers, pubs, shills, visible, handleClick, handleVisible } = useSwitchAccount(
        props,
      );
      let roleClassName = '';
      let roleName = '';
      if (roleId === 1) {
        roleName = '管理号';
        roleClassName = 'manager-box';
      } else if (roleId === 2) {
        roleName = '发布号';
        roleClassName = 'pub-box';
      } else if (roleId === 3) {
        roleName = '水军号';
        roleClassName = 'shills-box';
      }
      switch (props.type) {
        case TARGET_TYPE_SESSION:
          return (
            <div style={{ padding: '0 16px' }}>
              <div className="main-header session">
                <Row type="flex">
                  <Col>
                    <img
                      alt="头像"
                      className="target-img"
                      src={topic.invokerAvatar || wxGroup.groupHeadImg || '/defaultHead.jpg'}
                    />
                  </Col>
                  <Col>
                    <span>{topic.invokerNickname || wxGroup.groupName}</span>
                    <span style={{ color: 'red' }}>
                      {wxGroup.secureStatus ? ' [违规被举报] ' : ''}
                    </span>
                    <div style={{ fontSize: '12px' }}>
                      (总人数: {knownCount + unknown}，男: {male}，女: {female}，女性占比:{' '}
                      {Math.round((female / knownCount) * 10000) / 100.0}%)
                    </div>
                  </Col>
                </Row>
                {topic.id ? <h4 className="qun-title">{wxGroup.groupName}</h4> : ''}
                <Popover
                  autoAdjustOverflow
                  arrowPointAtCenter
                  content={
                    <>
                      <List
                        bordered
                        itemLayout="horizontal"
                        dataSource={managers}
                        renderItem={(item: any) => (
                          <List.Item
                            key={item.wxId}
                            className="account-list"
                            style={{
                              background: owner.wxId === item.wxId ? '#ededed' : '',
                            }}
                            onClick={() => {
                              handleClick(item, item.onlineStatus && item.role === 1);
                            }}
                          >
                            <List.Item.Meta
                              title={
                                <span className="account-item">
                                  {item.role === 1 && (
                                    <img
                                      className="admin"
                                      src="//upload-image.oss-cn-hangzhou.aliyuncs.com/20210525-1811321190.png"
                                      alt=""
                                    />
                                  )}
                                  <span className="manager-box">管理号</span>
                                  {item.nickname}
                                  <span style={{ color: 'red' }}>
                                    {item.onlineStatus ? '' : ' [离线] '}
                                  </span>
                                  <img
                                    alt="头像"
                                    style={{ width: '20px', height: '20px', margin: '-3px 10px 0' }}
                                    src={item.headImg}
                                  />
                                </span>
                              }
                            />
                          </List.Item>
                        )}
                      />
                      <Divider />
                      {pubs.length > 0 ? (
                        <>
                          <List
                            bordered
                            itemLayout="horizontal"
                            dataSource={pubs}
                            renderItem={(item: any) => (
                              <List.Item
                                key={item.wxId}
                                className="account-list"
                                style={{
                                  background: owner.wxId === item.wxId ? '#ededed' : '',
                                }}
                                onClick={() => {
                                  handleClick(item, item.onlineStatus && item.role === 1);
                                }}
                              >
                                <List.Item.Meta
                                  title={
                                    <span className="account-item">
                                      {item.role === 1 && (
                                        <img
                                          className="admin"
                                          src="//upload-image.oss-cn-hangzhou.aliyuncs.com/20210525-1811321190.png"
                                          alt=""
                                        />
                                      )}
                                      <span className="pub-box">发布号</span>
                                      {item.nickname}
                                      <span style={{ color: 'red' }}>
                                        {item.onlineStatus ? '' : ' [离线] '}
                                      </span>
                                      <img
                                        alt="头像"
                                        style={{
                                          width: '20px',
                                          height: '20px',
                                          margin: '-3px 10px 0',
                                        }}
                                        src={item.headImg}
                                      />
                                    </span>
                                  }
                                />
                              </List.Item>
                            )}
                          />
                        </>
                      ) : null}
                      <Divider />
                      {shills.length > 0 ? (
                        <List
                          bordered
                          itemLayout="horizontal"
                          dataSource={shills}
                          renderItem={(item: any) => (
                            <List.Item
                              key={item.wxId}
                              className="account-list"
                              style={{
                                background: owner.wxId === item.wxId ? '#ededed' : '',
                              }}
                              onClick={() => {
                                handleClick(item, item.onlineStatus && item.role === 1);
                              }}
                            >
                              <List.Item.Meta
                                title={
                                  <span className="account-item">
                                    {item.role === 1 && (
                                      <img
                                        className="admin"
                                        src="//upload-image.oss-cn-hangzhou.aliyuncs.com/20210525-1811321190.png"
                                        alt=""
                                      />
                                    )}
                                    <span className="shills-box">水军号</span>
                                    {item.nickname}
                                    <span style={{ color: 'red' }}>
                                      {item.onlineStatus ? '' : ' [离线] '}
                                    </span>
                                    <img
                                      alt="头像"
                                      style={{
                                        width: '20px',
                                        height: '20px',
                                        margin: '-3px 10px 0',
                                      }}
                                      src={item.headImg}
                                    />
                                  </span>
                                }
                              />
                            </List.Item>
                          )}
                        />
                      ) : null}
                    </>
                  }
                  overlayStyle={{ width: '288px' }}
                  placement="bottom"
                  trigger="click"
                  onVisibleChange={() => handleVisible(!visible)}
                  visible={visible}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ color: '#8C8C8C' }}>
                      <span className={roleClassName}>{roleName}</span>
                      {owner.nickname}
                      <span style={{ color: 'red' }}>{owner.onlineStatus ? '' : ' [离线] '}</span>
                    </span>
                    <img
                      alt="头像"
                      className="client-img"
                      src={miniHeadImage(owner.avatar) || '/defaultHead.jpg'}
                    />
                    {owner.role === 1 && (
                      <img
                        className="admin"
                        src="//upload-image.oss-cn-hangzhou.aliyuncs.com/20210525-1811321190.png"
                        alt=""
                      />
                    )}
                  </div>
                </Popover>
              </div>
              <Divider style={{ margin: 0 }} />
            </div>
          );
        case TARGET_TYPE_NEW_FRIEND:
          return (
            <div className="main-header apply">
              <div className="apply-title">新好友申请</div>
              <div className="apply-text">可以在后台（个人号-账号管理）设置好友自动通过规则</div>
            </div>
          );
        case TARGET_TYPE_NEW_GROUP:
          return (
            <div className="main-header apply">
              <div className="apply-title">新入群申请</div>
              <div className="apply-text">可以在后台（社群-自动通过）设置群自动通过</div>
            </div>
          );
        default:
          return null;
      }
    })()}
  </Fragment>
);

export default ChatPanelHeader;

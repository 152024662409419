import React, { Component } from 'react';
import { message, Input, Button, Modal, Checkbox, Row, Col, Select } from 'antd';
import debounce from 'lodash/debounce';
import request from '@/http/request';
import RecommendModal from './RecommendModal';

const { Option } = Select;

interface State {
  list: any[];
  optionsObj: any;
  recommendIndex: any;
  showRecommendModal: boolean;
  images: any[];
  promotionCode: string;
  confirmLoading: boolean;
}

class RecommendInfo extends Component<any, State> {
  constructor(props: {}) {
    super(props);
    this.state = {
      list: [],
      optionsObj: {
        hasCoupon: true,
        source: 1,
      },
      recommendIndex: null,
      showRecommendModal: false,
      images: [],
      promotionCode: '',
      confirmLoading: false,
    };
  }

  isEnd: number = 0;

  page: number = 0;

  child: any;

  init = () => {
    const {
      keyWord,
      hasCoupon,
      commissionRate,
      priceStart,
      priceEnd,
      source,
    } = this.state.optionsObj;
    request
      .get('/im/v2/commodity/search', {
        keyWord,
        couponOnly: hasCoupon ? 1 : 0,
        startCommissionRate: commissionRate,
        priceStart,
        priceEnd,
        source,
        page: this.page,
      })
      .then((res: any) => {
        this.page += 1;
        this.isEnd = res.data.total - this.page * 20;
        const previousList = this.state.list;
        if (this.page === 1) {
          this.setState({
            list: res.data.list,
          });
        } else {
          this.setState({
            list: [...previousList, ...res.data.list],
          });
        }
      });
  };

  getRecommendDetail = (shouldSet?: boolean) => {
    const { itemId, title, pictUrl } = this.state.list[this.state.recommendIndex];
    const { source } = this.state.optionsObj;
    return request.get('/im/v2/commodity/detail', { itemId, title, source }).then((res: any) => {
      if (!shouldSet) {
        this.setState({
          images: (res.data.itemInfo && res.data.itemInfo.images) || [pictUrl],
          promotionCode: res.data.promotionCode,
        });
      }
      return res.data;
    });
  };

  setRecommendModal = async (val: boolean) => {
    const data = await this.getRecommendDetail(true);
    const { pictUrl } = this.state.list[this.state.recommendIndex];
    this.setState(
      {
        images: (data.itemInfo && data.itemInfo.images) || [pictUrl],
        promotionCode: data.promotionCode,
      },
      () => {
        this.setState({ showRecommendModal: val });
      },
    );
  };

  handlerInput = (val: any, name: string) => {
    this.setState((prevState: any) => {
      return {
        optionsObj: {
          ...prevState.optionsObj,
          [name]: val,
        },
      };
    });
  };

  handlerOk = () => {
    const { checkedStatus } = this.child.state;
    const { images } = this.state;
    let content = this.child.state.note;
    let atList: any[] = [];
    const { target, info } = this.props;
    if (!(target && target.id)) {
      message.error('请选择会话');
      return;
    }
    if (!content || !images.length) {
      message.error('选择图片或者文案为空');
      return;
    }
    this.setState(
      {
        confirmLoading: true,
      },
      () => {
        debounce(async () => {
          const { source } = this.state.optionsObj;
          if (target.chatType === 3 && info && info.showAite) {
            atList = [
              {
                display: info.topic.invokerNickname,
                id: info.topic.invokeWxId,
              },
            ];
            content = `@${atList[0].display} ${content}`;
          }
          this.props.sendText({
            sessionId: target.id,
            content,
            atList: atList.length ? [atList[0].id] : atList,
            msgType: 1,
            recommendInfo: { ...this.state.list[this.state.recommendIndex], source },
          });
          const imgs = images.filter((item: any, index: number) => checkedStatus[index]);
          const imgObj = {
            sessionId: target.id,
            msgType: 3,
          };
          imgs.forEach((img: string, index: number) => {
            const sendImgObj = {
              ...imgObj,
              imageUrl: img,
            };
            setTimeout(() => {
              this.props.sendImg(sendImgObj);
            }, 1000 * (index + 1));
          });
          await request.put('/im/v2/commodity/recommend_count', { source });
          this.setState({
            showRecommendModal: false,
            confirmLoading: false,
          });
        }, 500)();
      },
    );
  };

  render() {
    const { list, recommendIndex, showRecommendModal, confirmLoading } = this.state;
    const {
      keyWord,
      hasCoupon,
      commissionRate,
      priceStart,
      priceEnd,
      source,
    } = this.state.optionsObj;
    return (
      <div>
        <div className="functionWindow-tabs-header">
          <span>推荐商品</span>
        </div>
        <div className="recommend-commodity-search">
          <Input.Group compact>
            <Select
              defaultValue={source}
              style={{ marginLeft: '16px' }}
              onChange={(val: number) => {
                this.setState((prevState: any) => {
                  return {
                    optionsObj: {
                      ...prevState.optionsObj,
                      source: val,
                    },
                    list: [],
                    recommendIndex: null,
                  };
                });
                this.isEnd = 0;
              }}
            >
              <Option value={1}>&nbsp;淘&nbsp;宝&nbsp;</Option>
              <Option value={2}>拼多多</Option>
            </Select>
            <Input.Search
              style={{ width: '297px' }}
              placeholder="请输入关键字"
              value={keyWord}
              allowClear
              enterButton="搜索"
              onChange={(e: any) => this.handlerInput(e.target.value, 'keyWord')}
              onSearch={() => {
                this.page = 0;
                this.init();
              }}
            />
          </Input.Group>
        </div>
        <div className="recommend-commodity-layout">
          <div style={{ marginLeft: '6px', marginTop: '12px', width: 'calc(100% - 16px)' }}>
            <Row gutter={8}>
              <Col span={15}>
                <span>价 &nbsp;&nbsp;格：</span>
                <Input
                  className="im-input small"
                  size="small"
                  value={priceStart}
                  type="text"
                  placeholder="最小价格"
                  onChange={(e: any) => this.handlerInput(e.target.value, 'priceStart')}
                />
                <span> - </span>
                <Input
                  className="im-input small"
                  size="small"
                  value={priceEnd}
                  placeholder="最大价格"
                  onChange={(e: any) => this.handlerInput(e.target.value, 'priceEnd')}
                />
              </Col>
              <Col span={9}>
                <span>佣金率≥ </span>
                <Input
                  className="im-input small"
                  size="small"
                  value={commissionRate}
                  placeholder="佣金率"
                  onChange={(e: any) => this.handlerInput(e.target.value, 'commissionRate')}
                />
                %
              </Col>
            </Row>
            <Row>
              <Col span={24} style={{ lineHeight: '28px' }}>
                <Checkbox
                  defaultChecked={hasCoupon}
                  onChange={(e: any) => {
                    this.setState((prevState: any) => {
                      return {
                        optionsObj: {
                          ...prevState.optionsObj,
                          hasCoupon: e.target.checked,
                        },
                      };
                    });
                  }}
                >
                  优惠券
                </Checkbox>
              </Col>
            </Row>
          </div>
          <div className="scroll-box" id="scroll-box-4">
            {list.length
              ? list.map((item, index) => (
                  <div
                    key={index}
                    className="recommend-commodity-card"
                    onMouseEnter={() => {
                      this.setState({ recommendIndex: index }, () => {
                        this.getRecommendDetail();
                      });
                    }}
                  >
                    {recommendIndex === index ? (
                      <div
                        className="recommend-tips"
                        onClick={(e: any) => {
                          this.setRecommendModal(true);
                          e.stopPropagation();
                        }}
                      >
                        推荐商品
                      </div>
                    ) : (
                      ''
                    )}
                    <img alt="图片" src={item.pictUrl} className="card-img" />
                    <div className="recommend-commodity-info">
                      <div
                        className="title"
                        onClick={(e: any) => {
                          if (item.itemUrl || this.state.promotionCode) {
                            window.open(item.itemUrl || this.state.promotionCode, 'blank');
                          }
                          e.stopPropagation();
                        }}
                      >
                        {item.title}
                      </div>
                      <div className="flex-item">
                        <div>
                          券额：<ins>{item.couponAmount}</ins>
                        </div>
                        <div>
                          券后价：<ins>{item.payPrice}</ins>
                        </div>
                      </div>
                      <div className="flex-item">
                        <div>销量：{item.volume}</div>
                        <div>佣金率：{`${item.commissionRate}%`}</div>
                      </div>
                      <div className="flex-item">
                        <span className="platform">
                          {(() => {
                            if (source === 1) {
                              return item.shopType ? '天猫' : '淘宝';
                            }
                            switch (item.shopType) {
                              case 1:
                                return '个人';
                              case 2:
                                return '企业';
                              case 3:
                                return '旗舰店';
                              case 4:
                                return '专卖店';
                              case 5:
                                return '专营店';
                              default:
                                return '普通店';
                            }
                          })()}
                        </span>
                        <span>{item.shopTitle}</span>
                      </div>
                    </div>
                  </div>
                ))
              : ''}
            {this.isEnd > 0 ? (
              <Button style={{ margin: '0 auto', display: 'block' }} onClick={this.init}>
                加载更多
              </Button>
            ) : (
              ''
            )}
          </div>
        </div>
        <Modal
          visible={showRecommendModal}
          width={640}
          style={{ top: 40 }}
          title="选择推广素材"
          okText="确定"
          cancelText="取消"
          onCancel={() => this.setRecommendModal(false)}
          onOk={this.handlerOk}
          confirmLoading={confirmLoading}
          destroyOnClose
        >
          <RecommendModal
            commodity={{ ...list[recommendIndex], source }}
            onRef={(ref: any) => {
              this.child = ref;
            }}
            images={this.state.images}
            promotionCode={this.state.promotionCode}
          />
        </Modal>
      </div>
    );
  }
}

export default RecommendInfo;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Avatar,
  Button,
  Dropdown,
  Icon,
  Input,
  List,
  Menu,
  message,
  Modal,
  Popconfirm,
  Popover,
  Switch,
} from 'antd';
import debounce from 'lodash/debounce';
import moment from 'moment';
import { State } from '@/store/types/state';
import UserCard from '@/components/UserCard';
import request from '@/http/request';
// import LazyLoad from 'react-lazyload';
import {
  createGetWxFriendListAction,
  createGetWxSessionListAction,
  createGetGroupMemberListAction,
  createSyncSendMessage,
} from '@/store/action';
import BlackPreviewModal from '@/components/BlackPreviewModal';
import RelationChainModel from '@/components/RelationChainModel';
import { env } from '@/util';
import AddQunMember from '../AddQunMember';

moment.locale('zh-cn', {
  relativeTime: {
    future: '%s内',
    past: '%s前',
    s: '几秒',
    ss: '%d秒',
    m: '1分钟',
    mm: '%d分钟',
    h: '1小时',
    hh: '%d小时',
    d: '1天',
    dd: '%d天',
    M: '1个月',
    MM: '%d个月',
    y: '1年',
    yy: '%d年',
  },
});

const DELETEMEMBERAPI = '/v1/group/member/remove';
const QunIdentity = (props: any) => {
  const {
    nickname,
    role,
    inviteCount = 0,
    smallHeadUrl = '/defaultHead.jpg',
    showHead,
    yipaiCount = 0,
    lastMsgTime,
    joinTime,
  } = props;
  const IsQunMaster = role ? (
    <span className="isQunMaster">{role === 2 ? '群主' : '管理员'}</span>
  ) : (
    ''
  );

  const size = smallHeadUrl.indexOf('wework') > -1 ? 100 : 132;
  const userHead =
    smallHeadUrl.substr(-2) === '/0'
      ? `${smallHeadUrl.substr(0, smallHeadUrl.length - 1)}${size}`
      : smallHeadUrl;
  return (
    <Popover
      placement="topLeft"
      content={`进群时间：${moment(joinTime).format('YYYY-MM-DD HH:mm:ss')} ${moment(
        joinTime,
      ).fromNow()}`}
    >
      <div className="flex" style={{ width: '100%', alignItems: 'center' }}>
        <div style={{ width: '70%' }}>
          <List.Item.Meta
            className="im-list-item-meta"
            avatar={
              <Avatar
                src={showHead ? userHead : '/defaultHead.jpg'}
                style={{ width: '38px', height: '38px' }}
              />
              // <LazyLoad
              //   height={38}
              //   resize
              //   overflow
              //   placeholder={
              //     <Avatar src="/defaultHead.jpg" style={{ width: '38px', height: '38px' }} />
              //   }
              // >
              //   <Avatar
              //     src={showHead ? userHead : '/defaultHead.jpg'}
              //     style={{ width: '38px', height: '38px' }}
              //   />
              // </LazyLoad>
            }
            title={
              <div style={{ userSelect: 'none' }}>
                <div>
                  {nickname}
                  {IsQunMaster}
                </div>
                {lastMsgTime && lastMsgTime !== '0001-01-01T00:00:00Z' && (
                  <div style={{ fontSize: '12px' }}>
                    {moment(lastMsgTime).format('MM-DD HH:mm:ss')} &nbsp;
                    {moment(lastMsgTime).fromNow()}
                  </div>
                )}
              </div>
            }
          />
        </div>
        <div style={{ width: '15%', textAlign: 'center' }}>{inviteCount}</div>
        <div style={{ width: '15%', textAlign: 'center' }}>{yipaiCount}</div>
      </div>
    </Popover>
  );
};

class GroupMembers extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      addQunModalVisible: false,
      validateVisible: false,
      reason: '',
      qunListSearchWord: '',
      isSort: false,
      showHead: false,
    };
  }

  nextIndex: number = 0;

  loading: boolean = false;

  qunName: any = '';

  addQunMemberChildCopyData: any[] = [];

  child: any;

  // componentDidMount() {
  //   this.props.newGetMemeberList({ groupId: this.props.groupId, start: 0 });
  // }

  componentDidUpdate(prevProps: any) {
    if ((this.props.target && this.props.target.id) !== (prevProps.target && prevProps.target.id)) {
      this.switchGroupHandler();
      // this.props.newGetMemeberList({
      //   groupId: this.props.target.chatId || this.props.target.id,
      //   start: 0,
      // });
    }
  }

  handleAt = (item: any) => {
    this.props.syncMessage({
      rawMsg: `\u200b@${item.nickname}\u200c`,
      editorValue: ` @[${item.nickname}](${item.wxId}) `,
      newPlainTextValue: ` \u200b@${item.nickname}\u200c `,
      msgType: 0,
      stamp: new Date().getTime(),
      atList: [{ id: item.wxId, display: `\u200b@${item.nickname}\u200c`, childIndex: 0 }],
    });
  };

  // 跳转到个人号聊天对应会话窗口
  openStandardIm = (item: any) => {
    const { wxId } = item;
    const { chatId } = this.props.target;
    const currentUser = sessionStorage.getItem('currentUser');

    request.get(`/im/v2/accounts/${wxId}/info`).then((res: any) => {
      if (res.data.userId === +currentUser) {
        window.open(
          `//${window.location.host.indexOf('wework') > -1 ? 'bee.wework' : 'bee'}.${env &&
            `${env}.`}yipiandian.com?wxId=${wxId}&chatId=${chatId}`,
        );
      } else {
        message.warn('该账户不在你名下，分配到你名下后再操作');
      }
    });
  };

  onLoadMore = () => {
    const { currentGroupInfo, groupId } = this.props;
    this.props.getMoreMemeberList(
      {
        groupId,
        start: currentGroupInfo.nextIndex,
      },
      true,
    );
  };

  // 移除群成员
  deleteMember = (wxId: number, currentClient: any) => {
    request
      .post(DELETEMEMBERAPI, {
        group_id: this.props.groupId,
        member_list: `${wxId}`,
        from_wx_id: currentClient,
      })
      .then(() => {
        this.props.newGetMemeberList(
          {
            groupId: this.props.groupId,
            start: 0,
            count: 1000,
            sortByInviteCount: this.state.isSort,
          },
          false,
        );
      });
  };

  // 加入黑名单
  addToBlackList = () => {
    // request
    //   .post('/im/v2/wx_user/black/list', {
    //     groupId: this.props.groupId,
    //     wxId,
    //   })
    //   .then(() => {

    //   });
    setTimeout(
      this.props.newGetMemeberList(
        {
          groupId: this.props.groupId,
          start: 0,
          count: 500,
          sortByInviteCount: this.state.isSort,
        },
        false,
      ),
      1500,
    );
  };

  switchGroupHandler = () => {
    this.setState({
      qunListSearchWord: '',
      showHead: false,
    });
  };

  openAddQunMember = () => {
    this.props.getFriendList();
    this.setState({
      addQunModalVisible: true,
    });
  };

  confirmAddQunMember = () => {
    if (this.props.auditStatus === 2 && this.props.currRole === 0) {
      // auditStatus == 2 表示群主开启了进群审核
      // role:0
    } else {
      this.addToQun();
    }
  };

  confirmAddQunValidate = () => {
    this.addToQun();
  };

  // 群添加新成员
  addToQun = () => {
    const wxIds = this.addQunMemberChildCopyData.map((item: any) => item.wxId);
    request
      .post('v2/group/members', {
        wxId: this.props.currentClient.wxId,
        groupId: this.props.groupId,
        wxIds,
        reason: this.state.reason,
      })
      .then((res: any) => {
        if (res.code === 200 && res.msg === '操作成功') {
          this.child.clearQunData();
          setTimeout(
            this.props.newGetMemeberList(
              {
                groupId: this.props.groupId,
                start: 0,
                count: 1000,
                sortByInviteCount: this.state.isSort,
              },
              false,
            ),
            1000,
          );
        }
      });
    this.setState({
      addQunModalVisible: false,
    });
  };

  qunMemberSearch = debounce(() => {
    const { groupId } = this.props;
    this.props.getMoreMemeberList(
      {
        groupId,
        start: 0,
        q: this.state.qunListSearchWord,
      },
      false,
    );
  }, 500);

  qunMemberSearchInput = (e: any) => {
    this.setState(
      {
        qunListSearchWord: e.target.value,
      },
      () => {
        this.qunMemberSearch();
      },
    );
  };

  sortGroupMembers = () => {
    this.setState(
      (preState: any) => {
        return {
          isSort: !preState.isSort,
        };
      },
      () => {
        this.props.newGetMemeberList(
          {
            groupId: this.props.groupId,
            sortByInviteCount: this.state.isSort,
            start: 0,
            count: 1000,
          },
          false,
        );
      },
    );
  };

  render() {
    const {
      addQunModalVisible,
      qunListSearchWord,
      btnStatus,
      validateVisible,
      reason,
      isSort,
      showHead,
    } = this.state;
    const { currentGroupInfo, groupName, friendList, currentClient } = this.props;
    const { list, isEnd } = currentGroupInfo;
    const loadMore =
      !this.loading && !isEnd ? (
        <div className="qun-member-loadmore">
          <Button onClick={this.onLoadMore}>加载更多</Button>
        </div>
      ) : null;

    return (
      <>
        <Modal
          visible={addQunModalVisible}
          width={1220}
          closable={false}
          style={{ top: 40 }}
          className="add-modal"
          onCancel={() => {
            this.setState({ addQunModalVisible: false });
          }}
          footer={[
            <Button
              key="back"
              className="im-modal-cancel-btn"
              onClick={() => this.setState({ addQunModalVisible: false })}
            >
              取消
            </Button>,
            <Button
              key="submit"
              className="im-modal-confirm-btn"
              onClick={this.confirmAddQunMember}
              type="primary"
              disabled={!btnStatus}
            >
              确认
            </Button>,
          ]}
        >
          <AddQunMember
            friendList={friendList}
            memberlist={list}
            currClientNickname={currentClient.nickname}
            onRef={(ref: any) => {
              this.child = ref;
            }}
            onRefCopyData={(data: any) => {
              if (!this.addQunMemberChildCopyData.length && data.length) {
                this.setState({
                  btnStatus: true,
                });
              } else if (this.addQunMemberChildCopyData.length && !data.length) {
                this.setState({
                  btnStatus: false,
                });
              }
              this.addQunMemberChildCopyData = [...data];
            }}
          />
        </Modal>
        <Modal
          visible={validateVisible}
          onCancel={() => {
            this.setState({ validateVisible: false });
          }}
          onOk={this.confirmAddQunValidate}
        >
          <div>
            <div>群主或管理员已启用“群聊邀请确认”，邀请朋友进群可向群主或管理员描述原因。</div>
            <Input
              placeholder="说明邀请理由"
              allowClear
              value={reason}
              onChange={(e: any) => {
                this.setState({ reason: e.target.value });
              }}
            />
          </div>
        </Modal>

        <List
          className="qun-member-list"
          split={false}
          header={
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span className="im-title-font im-bold">{`群成员：${currentGroupInfo.group.memberCount}`}</span>
              <Button
                className="im-btn-icon"
                onClick={this.openAddQunMember}
                ghost
                type="primary"
                size="small"
              >
                <Icon type="plus" />
                添加成员
              </Button>
            </div>
          }
          loading={false}
          loadMore={loadMore}
          size="small"
        >
          <List.Item className="qun-search">
            <Input
              style={{ marginRight: '10px', width: '300px' }}
              placeholder="搜索群成员"
              onChange={this.qunMemberSearchInput}
              value={qunListSearchWord}
              allowClear
            />
          </List.Item>
          <List.Item>
            <div className="flex" style={{ width: '100%' }}>
              <div style={{ width: '20%' }}>用户信息</div>
              <div style={{ width: '40%' }}>
                显示头像
                <Switch
                  checkedChildren="开"
                  unCheckedChildren="关"
                  checked={showHead}
                  size="small"
                  onChange={val => {
                    this.setState({ showHead: val });
                  }}
                />
              </div>
              <div style={{ width: '25%', cursor: 'pointer' }} onClick={this.sortGroupMembers}>
                {isSort ? (
                  <>
                    <span style={{ color: '#1890ff' }}>邀请人数</span>
                    <Icon type="caret-down" style={{ color: '#1890ff' }} />
                  </>
                ) : (
                  <>
                    <span>邀请人数</span>
                    <Icon type="caret-down" />
                  </>
                )}
              </div>
              <div style={{ width: '15%' }}>已拍数</div>
            </div>
          </List.Item>
          {list.map((item: any) => {
            if (currentClient.wxId !== item.wxId) {
              if (item.role !== 2) {
                return (
                  <Dropdown
                    key={item.id}
                    overlay={
                      <Menu>
                        <Menu.Item key="0">
                          <div onClick={() => this.handleAt(item)}>@{item.nickname}</div>
                        </Menu.Item>
                        <Menu.Item key="1">
                          <Popconfirm
                            title={`确认将 ${item.nickname} 从 ${groupName ||
                              '未命名的群'} 中移除？`}
                            okText="确认"
                            cancelText="取消"
                            onConfirm={() => {
                              this.deleteMember(item.wxId, currentClient.wxId);
                            }}
                          >
                            <div>移除该成员</div>
                          </Popconfirm>
                        </Menu.Item>
                        <Menu.Item key="2">
                          <div
                            onClick={() =>
                              BlackPreviewModal({ onOk: this.addToBlackList, wxId: item.wxId })
                            }
                          >
                            加入黑名单
                          </div>
                        </Menu.Item>
                        <Menu.Item key="4">
                          <div
                            onClick={() => {
                              RelationChainModel({ fromWxid: item.wxId, toWxid: item.groupId });
                            }}
                          >
                            查看关系链
                          </div>
                        </Menu.Item>
                        {/* 是工作人员账号 */}
                        {item.isStaff && (
                          <Menu.Item
                            key="5"
                            onClick={() => {
                              this.openStandardIm(item);
                            }}
                          >
                            <div>个人号聊天</div>
                          </Menu.Item>
                        )}
                      </Menu>
                    }
                    trigger={['contextMenu']}
                  >
                    <Popover
                      placement="leftBottom"
                      trigger="click"
                      content={
                        <UserCard
                          groupId={item.groupId}
                          memberWxId={item.wxId}
                          wxId={currentClient.wxId}
                        ></UserCard>
                      }
                    >
                      <List.Item className="group-item">
                        <QunIdentity {...item} showHead={showHead} />
                      </List.Item>
                    </Popover>
                  </Dropdown>
                );
              }
              return (
                <Dropdown
                  key={item.id}
                  overlay={
                    <Menu>
                      <Menu.Item key="1">
                        <div onClick={() => this.handleAt(item)}>@{item.nickname}</div>
                      </Menu.Item>
                      <Menu.Item key="2">
                        <div
                          onClick={() =>
                            BlackPreviewModal({ onOk: this.addToBlackList, wxId: item.wxId })
                          }
                        >
                          加入黑名单
                        </div>
                      </Menu.Item>
                      <Menu.Item key="4">
                        <div
                          onClick={() => {
                            RelationChainModel({ fromWxid: item.wxId, toWxid: item.groupId });
                          }}
                        >
                          查看关系链
                        </div>
                      </Menu.Item>
                      <Menu.Item key="5">
                        <div
                          onClick={() => {
                            this.openStandardIm(item);
                          }}
                        >
                          个人号聊天
                        </div>
                      </Menu.Item>
                    </Menu>
                  }
                  trigger={['contextMenu']}
                >
                  <Popover
                    key={item.id}
                    placement="leftBottom"
                    trigger="click"
                    content={
                      <UserCard
                        groupId={item.groupId}
                        memberWxId={item.wxId}
                        wxId={currentClient.wxId}
                      ></UserCard>
                    }
                  >
                    <List.Item className="group-item">
                      <QunIdentity {...item} showHead={showHead} />
                    </List.Item>
                  </Popover>
                </Dropdown>
              );
            }
            return (
              <Dropdown
                key={item.id}
                overlay={
                  <Menu>
                    <Menu.Item key="5">
                      <div
                        onClick={() => {
                          this.openStandardIm(item);
                        }}
                      >
                        个人号聊天
                      </div>
                    </Menu.Item>
                  </Menu>
                }
                trigger={['contextMenu']}
              >
                <List.Item className="group-item">
                  <QunIdentity {...item} showHead={showHead} />
                </List.Item>
              </Dropdown>
            );
          })}
        </List>
      </>
    );
  }
}

function mapStateToProps(state: State) {
  return {
    target: state.currentTarget,
    friendList: state.friendList,
    currentClient: state.clients.current,
    currentGroupInfo: state.currentGroupInfo,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    getFriendList: () => dispatch(createGetWxFriendListAction({})),
    getSessionList: () => dispatch(createGetWxSessionListAction({ limit: 5000 })),
    syncMessage: (param: any) => dispatch(createSyncSendMessage(param)),
    newGetMemeberList: (param: any, merge: boolean) =>
      dispatch(createGetGroupMemberListAction(param, merge)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupMembers);

/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState } from 'react';
import {
  Layout,
  List,
  Tooltip,
  Empty,
  Button,
  Tabs,
  Icon,
  Select,
  Spin,
  message,
  Popconfirm,
  Modal,
  Badge,
  BackTop,
} from 'antd';
import request from '@/http/request';
import './index.less';
import ShowModal from '@/components/ShowModal';

const { TabPane } = Tabs;
const { Option } = Select;

interface PlayInfoProps {
  currGroupId: string;
  currentWindowTab: any;
}
const PlayInfo: React.FC<PlayInfoProps> = props => {
  const timerRef = useRef<NodeJS.Timeout>();
  const { currGroupId, currentWindowTab } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [playbookItemList, setPlaybookItemList] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [currentPlayStatus, setCurrentPlayStatus] = useState('0');
  const [tabs, setTabs] = useState<any>([]);
  const [currentActiveTabKey, setCurrentActiveTabKey] = useState('0');
  const [list, setList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [value, setValue] = useState<number[]>([]);
  const [groups, setGroups] = useState<number[]>([]);
  const [winTab, setCurrentWinTab] = useState<any>('9');

  const getDataItems = () => {
    if (+currentActiveTabKey !== 0) {
      request
        .get('/im/v2/auto/task/items', {
          taskId: currentActiveTabKey,
        })
        .then((res2: any) => {
          setPlaybookItemList(res2.data.list);
        });
    }
  };

  const getData = (flag?: boolean, curr?: any) => {
    if (+winTab !== 9) {
      return;
    }
    setLoading(true);
    request
      .get('/im/v2/auto/task', {
        groupId: curr || currGroupId,
      })
      .then((res: any) => {
        setLoading(false);
        if (res.code === 200) {
          setTabs(res.data.list);
          if (res.data.list[0] && res.data.list[0].playbooksInfo[0] && flag) {
            res.data.list.forEach((el: any) => {
              const temp = el.playbooksInfo.filter((item: any) => +item.playStatus === 1);
              if (temp && temp.length) {
                setCurrentActiveTabKey(String(temp[0].taskId));
              } else {
                setCurrentActiveTabKey(String(res.data.list[0].playbooksInfo[0].taskId));
              }
            });
            res.data.list.forEach((el: any) => {
              el.playbooksInfo.forEach((item: any) => {
                if (+item.taskId === +res.data.list[0].playbooksInfo[0].taskId) {
                  setCurrentPlayStatus(item.playStatus);
                }
              });
            });
          } else {
            res.data.list.forEach((el: any) => {
              el.playbooksInfo.forEach((item: any) => {
                if (+item.taskId === +currentActiveTabKey) {
                  setCurrentPlayStatus(item.playStatus);
                }
              });
            });
          }
          if (res.data.list.length && currentActiveTabKey) {
            getDataItems();
          }
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (currGroupId) {
      getData(true, currGroupId);
    }
  }, [currGroupId]);

  useEffect(() => {
    if (currGroupId) {
      getData();
    }
  }, [currentActiveTabKey]);

  useEffect(() => {
    setCurrentWinTab(currentWindowTab);
    getData();
  }, [currentWindowTab]);

  useEffect(() => {
    if (currentActiveTabKey && +currentActiveTabKey !== 0) {
      timerRef.current = setInterval(() => {
        getData();
      }, 4000);
    } else {
      clearInterval(timerRef.current as NodeJS.Timeout);
    }
    return () => {
      clearInterval(timerRef.current as NodeJS.Timeout);
    };
  });

  useEffect(() => {
    request.get('/md/v2/user/playbooks').then((res: any) => {
      setList(res.data.list);
    });
    request.get('/md/v2/user/divisions').then((res: any) => {
      setGroupList(res.data.list);
    });
  }, []);

  const throttle = (fn: { apply: (arg0: any, arg1: any[]) => void }, t: number) => {
    let flag = true;
    const interval = t || 500;
    function res(this: any, ...args: any) {
      if (flag) {
        fn.apply(this, args);
        flag = false;
        setTimeout(() => {
          flag = true;
        }, interval);
      }
    }
    return res;
  };

  const handleSetPlaybook = () => {
    setLoading2(true);
    request
      .post('/im/v2/auto/tasks', {
        playbookIds: value,
        divisionIds: groups,
      })
      .then((res: any) => {
        setLoading2(false);
        if (res.code === 200) {
          message.success(res.msg);
          getData();
          setIsModalVisible(false);
        } else {
          message.error(res.msg);
        }
      })
      .catch((res: any) => {
        message.error(res.response.data.msg);
        setLoading2(false);
      });
  };

  const throttleHandleSetPlaybook = throttle(handleSetPlaybook, 1000);

  const role = (id: number) => {
    if (id < 1) {
      return null;
    }
    if (id === 1) {
      return <span className="role">发布号</span>;
    }
    return <span className="role">水军号{id - 1}</span>;
  };

  const role2 = (num: number) => {
    if (num !== 0) {
      return <span className="role role2">{num}秒后</span>;
    }
    return null;
  };

  const role3 = (num: number) => {
    if (+num !== 0) {
      return <span className="role role3">满{num}人</span>;
    }
    return null;
  };

  const handleStart = () => {
    clearInterval(timerRef.current as NodeJS.Timeout);
    request
      .post('/im/v2/submit/auto/task', {
        taskIds: [currentActiveTabKey],
        send: true,
      })
      .then((res: any) => {
        if (res.code === 200) {
          message.success(res.msg);
          getData();
        } else {
          message.error(res.msg);
        }
      });
  };

  const handleRestart = (id: any) => {
    clearInterval(timerRef.current as NodeJS.Timeout);
    request
      .post('/im/v2/retry/auto/task/item', {
        itemId: id,
      })
      .then((res: any) => {
        if (res.code === 200) {
          message.success(res.msg);
          getData();
        } else {
          message.error(res.msg);
        }
      });
  };

  const handlePause = () => {
    const temp = [];
    temp.push([currentActiveTabKey]);
    request
      .post('/im/v2/submit/auto/task', {
        taskIds: temp,
        send: false,
      })
      .then((res: any) => {
        if (res.code === 200) {
          message.success(res.msg);
          getData();
        } else {
          message.error(res.msg);
        }
      });
  };

  const showContent = (item: any) => {
    if (item.playbookTaskItem.msgType === 3) {
      return <img width={100} height={100} alt="logo" src={item.playbookTaskItem.content} />;
    }
    if (item.playbookTaskItem.msgType === 43) {
      return (
        <video width={120} height={100} controls>
          <source src={item.playbookTaskItem.content} type="video/mp4"></source>
          <track kind="captions" src={item.playbookTaskItem.content} />
        </video>
      );
    }
    return item.playbookTaskItem.content;
  };

  const onEdit = (targetKey: any, action: any) => {
    if (action === 'remove') {
      ShowModal({
        props,
        width: 520,
        bodyStyle: {
          display: 'flex',
          justifyContent: 'center',
          textAlign: 'center',
        },
        destroyOnClose: 'destroyOnClose',
        title: '确认删除吗',
        onOk: () => {
          request
            .delete('/im/v2/auto/task', {
              taskId: targetKey,
            })
            .then((res: any) => {
              if (res.code === 200) {
                message.success(res.msg);
                getData(true);
              } else {
                message.error(res.msg);
              }
            });
        },
      });
    }
  };

  return (
    <div id="playinfo-wrapper">
      {currGroupId ? (
        <Layout className="playinfo-wrapper">
          <div className="scroll-box" id="scroll-box">
            <div style={{ marginBottom: '10px' }}>
              <Button
                className="im-btn-default im-btn-icon"
                onClick={() => {
                  setIsModalVisible(true);
                  setValue([]);
                  setGroups([]);
                }}
                type="primary"
                icon="plus"
              >
                导入剧本
              </Button>
            </div>

            <div>
              {tabs.length > 0 ? (
                <Tabs
                  hideAdd
                  onEdit={(targetKey: any, action: any) => onEdit(targetKey, action)}
                  type="editable-card"
                  activeKey={String(currentActiveTabKey)}
                  onChange={(activeKey: string) => {
                    setCurrentActiveTabKey(String(activeKey));
                  }}
                >
                  {tabs[0].playbooksInfo.map((item: any, index: number) => (
                    <TabPane tab={item.playbookName} key={String(item.taskId)}>
                      <div>
                        <div className="send-info-wrap">
                          <div>
                            <div>
                              发送进度：{tabs[0].playbooksInfo[index].sendFinishNum}/
                              {tabs[0].playbooksInfo[index].allItemsNum}
                            </div>
                            {tabs[0].playbooksInfo[index] &&
                            tabs[0].playbooksInfo[index].failReason ? (
                              <div>异常原因：{tabs[0].playbooksInfo[index].failReason}</div>
                            ) : null}
                          </div>
                          <div className="send-action">
                            {+currentPlayStatus === 1 ? (
                              <Popconfirm
                                title="确定暂停吗?"
                                onConfirm={() => handlePause()}
                                okText="确定"
                                cancelText="取消"
                              >
                                <Icon className="pause" type="pause-circle" />
                              </Popconfirm>
                            ) : (
                              <Popconfirm
                                title="确定开始吗?（自动跳过失败消息）"
                                onConfirm={() => handleStart()}
                                okText="确定"
                                cancelText="取消"
                              >
                                <Icon className="play" type="play-circle" />
                              </Popconfirm>
                            )}
                          </div>
                        </div>
                        {loading ? (
                          <Spin style={{ position: 'absolute', left: '45%', zIndex: 10 }} />
                        ) : null}
                        {playbookItemList.length && tabs.length ? (
                          <List
                            size="small"
                            dataSource={playbookItemList}
                            renderItem={(item2, index2) => (
                              <div className="list-wrapper">
                                {item2.taskItem.status === 1 ? (
                                  <div className="success-modal"></div>
                                ) : null}
                                <List.Item
                                  key={index2}
                                  style={{
                                    padding: '0px',
                                    borderBottom: '1px solid rgba(38,38,38,0.17)',
                                  }}
                                  extra={
                                    <Tooltip
                                      placement="left"
                                      title={
                                        item2.taskItem && item2.taskItem.failReason
                                          ? item2.taskItem.failReason
                                          : ''
                                      }
                                    >
                                      <div className="message-block">
                                        <div>
                                          {role(item2.playbookTaskItem.roleId)}
                                          {role2(item2.playbookTaskItem.delaySeconds)}
                                          {role3(item2.playbookTaskItem.activeNum)}
                                        </div>
                                        <div>
                                          {item2.taskItem.status === 0 ? (
                                            <Badge status="default" />
                                          ) : item2.taskItem.status === 1 ? (
                                            <Badge status="success" />
                                          ) : (
                                            <Badge color="#bf351f" />
                                          )}
                                          {+item2.playbookTaskItem.announce === 1 ? (
                                            <span style={{ color: 'red', paddingRight: 5 }}>
                                              @所有人
                                            </span>
                                          ) : null}
                                          {showContent(item2)}
                                          {item2.taskItem.status === 2 ? (
                                            <Popconfirm
                                              title="确定重新开始吗?"
                                              onConfirm={() => handleRestart(item2.taskItem.id)}
                                              okText="确定"
                                              cancelText="取消"
                                            >
                                              <a className="singlePlay">补发</a>
                                            </Popconfirm>
                                          ) : null}
                                        </div>
                                      </div>
                                    </Tooltip>
                                  }
                                ></List.Item>
                              </div>
                            )}
                          />
                        ) : (
                          <Empty className="im-empty-status" description="暂无数据" />
                        )}
                      </div>
                    </TabPane>
                  ))}
                </Tabs>
              ) : null}
              <BackTop
                target={() => document.querySelector('#scroll-box') as HTMLElement}
                visibilityHeight={10}
              />
            </div>
          </div>
        </Layout>
      ) : (
        <Empty image="/null.png" className="im-empty-status" description="没有选择聊天" />
      )}
      <Modal
        title="导入剧本"
        visible={isModalVisible}
        footer={null}
        onCancel={() => setIsModalVisible(false)}
        width={700}
      >
        <div>
          {loading2 ? (
            <Spin size="large" />
          ) : (
            <>
              <div style={{ display: 'inline-block', marginBottom: 200 }}>
                <span style={{ color: '#8C8C8C', fontSize: '16px' }}>选择群组：</span>
                <Select
                  placeholder="选择群组"
                  style={{ width: 240 }}
                  mode="multiple"
                  maxTagCount={10}
                  size="small"
                  onSelect={(item: any) => setGroups(groups.concat(+item))}
                  onDeselect={(item: any) => setGroups(groups.filter((i: any) => +i !== +item))}
                >
                  {groupList.map((item: any) => (
                    <Select.Option value={String(item.id)} key={item.id}>
                      {item.id} {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </div>
              <div style={{ marginLeft: 10, display: 'inline-block' }}>
                <span style={{ color: '#8C8C8C', fontSize: '16px' }}>选择剧本：</span>
                <Select
                  style={{ width: 240 }}
                  placeholder="选择剧本"
                  {...props}
                  mode="multiple"
                  maxTagCount={10}
                  size="small"
                  onSelect={(item: any) => setValue(value.concat(+item))}
                  onDeselect={(item: any) => setValue(value.filter((i: any) => +i !== +item))}
                >
                  {list &&
                    list.map((item: any) => (
                      <Option key={item.id} value={String(item.id)}>
                        {item.name}
                      </Option>
                    ))}
                </Select>
              </div>
              <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>
                <Button type="primary" onClick={() => throttleHandleSetPlaybook()}>
                  保存
                </Button>
              </div>
            </>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default PlayInfo;

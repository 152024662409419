import { BaseAction } from '../types/action';
import { ACTION_SET_TAB_UNREAD_COUNT } from '../constant';

export default (
  state: any = { qunUnReadCount: 0, subUnReadCount: 0, undeliveredCount: 0 },
  action: BaseAction,
) => {
  switch (action.type) {
    case ACTION_SET_TAB_UNREAD_COUNT:
      return { ...state, ...action.data };
    default:
      return state;
  }
};

import React from 'react';
import MessageBox from '../MessageBox';

interface Prop {
  messageList: any[];
  onScrollToTop: () => any;
  wxId: string;
  // target: any;
}

class ChatWindow extends React.PureComponent<Prop> {
  render() {
    return (
      <div className="chat-window">
        <MessageBox {...this.props} />
      </div>
    );
  }
}

export default ChatWindow;

import { WxSession } from '../types/state';
import { BaseAction } from '../types/action';
import {
  ACTION_SET_SESSION_LIST,
  ACTION_UPDATE_SINGLE_SESSION,
  ACTION_TOP_SINGLE_SESSION,
  ACTION_GET_MOCK_SESSION_LIST,
  ACTION_REPLACE_SESSION,
} from '../constant/index';

export default (state: WxSession[] = [], action: BaseAction) => {
  const { data } = action;
  switch (action.type) {
    case ACTION_GET_MOCK_SESSION_LIST:
    case ACTION_SET_SESSION_LIST:
      return data;
    case ACTION_UPDATE_SINGLE_SESSION:
      return state.map(session => {
        if (session.id === data.sessionId) {
          return {
            ...session,
            ...data,
          };
        }
        return session;
      });

    case ACTION_REPLACE_SESSION: {
      return state.map(session => {
        if (session.id === data.oldId) {
          return { ...data.session };
        }
        return { ...session };
      });
    }
    // 重排序
    case ACTION_TOP_SINGLE_SESSION: {
      // 更新方法, 支持传入数组同时更新多个会话
      // chatType = 3 是子会话
      // data 传入的 sessionlist数组
      if (Array.isArray(data)) {
        // const uniqueElementsBy = (arr: any[], fn: (a: any, b: any) => void) =>
        //   arr.reduce((acc, v) => {
        //     if (!acc.some((e: any) => fn(v, e))) acc.push(v);
        //     return acc;
        //   }, []);

        // const mergeData = uniqueElementsBy(
        //   data.concat(state),
        //   (a: WxSession, b: WxSession) => a.id === b.id,
        // );
        const newData = [...state];
        data.forEach(d => {
          const index = newData.findIndex(item => item.id === d.id);
          if (index > -1) {
            newData.splice(index, 1, d);
          } else {
            newData.splice(0, 0, d);
          }
        });
        return newData;
        // const resultStick = mergeData.filter((sessionItem: WxSession) => sessionItem.status);
        // .sort((a: WxSession, b: WxSession) => {
        //   return a.sortTime && a.sortTime
        //     ? new Date(b.sortTime).getTime() - new Date(a.sortTime).getTime()
        //     : -1;
        // });
        // const resultNormal = mergeData.filter((sessionItem: WxSession) => !sessionItem.status);
        // .sort((a: WxSession, b: WxSession) => {
        //   return a.sortTime && a.sortTime
        //     ? new Date(b.sortTime).getTime() - new Date(a.sortTime).getTime()
        //     : -1;
        // });
        // if (getParamValue('enable_log')) {
        //   console.table(
        //     data.map((item: any) => {
        //       return {
        //         time: item.sortTime
        //           .split('T')
        //           .join(' ')
        //           .split('+')[0],
        //         name: item.nickname,
        //         newUnread: item.newUnread,
        //         msg: item.lastMsgV2,
        //         status: item.status,
        //         wxId: item.wxId,
        //       };
        //     }),
        //   );
        //   const d = resultStick
        //     .concat(resultNormal)
        //     .slice(0, 20)
        //     .map((item: any) => {
        //       return {
        //         time: item.msgTime
        //           .split('T')
        //           .join(' ')
        //           .split('+')[0],
        //         name: item.nickname,
        //         newUnread: item.newUnread,
        //         msg: item.lastMsgV2,
        //         status: item.status,
        //       };
        //     });
        //   console.table(d.filter((item: any) => item.status));
        //   console.table(d.filter((item: any) => !item.status));
        // }

        // return resultStick.concat(resultNormal);
      }
      return [];
    }
    default:
      return state;
  }
};

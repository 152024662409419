import React, { Component } from 'react';
import {
  Menu,
  Layout,
  Button,
  List,
  Icon,
  Modal,
  Input,
  // Dropdown,
  Radio,
  Upload,
  message,
  Empty,
} from 'antd';
import MessageSender from '../../../component/MessageSender';
import EmojiSuggestions from '../../../component/EmojiSuggestions';
import request from '@/http/request';

const { Content, Sider } = Layout;

interface State {
  groupList: any[];
  quickReplayList: any[];
  currGroupId: string;
  currReplyId: string;
  activeKey: string;
  showAddGroupDialog: boolean;
  showAddQuickReplayDialog: boolean;
  quickReplayType: number;
  pos: any;
  groupName: string;
  editorValue: string;
  editorImg: string;
  isEdit: boolean;
  uploadProps: any;
  fileList: any[];
}

class QuickReplaySetting extends Component<any, State> {
  constructor(props: {}) {
    super(props);
    this.state = {
      groupList: [],
      quickReplayList: [],
      currGroupId: '',
      currReplyId: '',
      activeKey: '',
      showAddGroupDialog: false,
      showAddQuickReplayDialog: false,
      quickReplayType: 0,
      pos: { start: 0, end: 0 },
      groupName: '',
      editorValue: '',
      editorImg: '',
      isEdit: false,
      uploadProps: {
        action: '/api/v2/upload/image',
        listType: 'picture-card',
        className: 'upload-list-inline',
      },
      fileList: [],
    };
  }

  componentDidMount() {
    this.init();
  }

  async init() {
    const groupList = (await request.get('/im/v2/quick_reply/groups')).data.list;
    const currGroupId = groupList[0] && groupList[0].id;
    this.setState({
      groupList,
      activeKey: '0',
    });
    if (!groupList.length) {
      return;
    }
    this.getQuickReplayList(currGroupId);
  }

  getGroupList = () => {
    return request.get('/im/v2/quick_reply/groups').then((res: any) => {
      this.setState({ groupList: res.data.list });
      return res.data.list;
    });
  };

  getQuickReplayList = (groupId?: any, replyId?: any) => {
    const currGroupId = groupId || this.state.currGroupId;
    request.get('/im/v2/quick_replys', { groupId: currGroupId }).then((res: any) => {
      const currReplyId = replyId || (res.data.list[0] && res.data.list[0].id);
      this.setState({
        currGroupId,
        currReplyId,
        quickReplayList: res.data.list,
      });
    });
  };

  openAddGroupNameDialog = (isEdit: boolean) => {
    const { groupList, activeKey } = this.state;
    this.setState({
      showAddGroupDialog: true,
      groupName: isEdit ? groupList[+activeKey].name : '',
      isEdit,
    });
  };

  addGroupName = async () => {
    const { isEdit, groupName, currGroupId } = this.state;
    if (groupName.length > 10) {
      message.error('分组名称不能超过10个字');
      return;
    }
    if (isEdit) {
      await request.put('/im/v2/quick_reply/group', { name: groupName, groupId: currGroupId });
      this.getGroupList();
    } else {
      await request.post('/im/v2/quick_reply/group', { name: groupName });
      this.init();
    }
    this.setState({ showAddGroupDialog: false, isEdit: false });
  };

  deleteGroupName = async () => {
    await request.delete('/im/v2/quick_reply/group', { groupId: this.state.currGroupId }, true);
    this.init();
  };

  openAddQuickReplayDialog = (isEdit: boolean, index?: number) => {
    const { quickReplayList, currGroupId, currReplyId } = this.state;
    if (!currGroupId && !currReplyId) {
      message.error('请先创建分组');
      return;
    }
    const node = quickReplayList[index || 0];
    const content = node && node.content;
    const type = (node && node.msg_type) || 0;
    const data = {
      uid: '-1',
      name: 'current.png',
      status: 'done',
      url: content,
      thumbUrl: content,
    };
    this.setState({
      showAddQuickReplayDialog: true,
      editorValue: isEdit && !type ? content : '',
      uploadProps: {
        action: '/api/v2/upload/image',
        listType: 'picture',
        className: 'upload-list-inline',
      },
      fileList: isEdit && type ? [data] : [],
      quickReplayType: type,
      isEdit,
    });
  };

  addQuickReplay = async () => {
    const {
      isEdit,
      currGroupId,
      currReplyId,
      editorValue,
      editorImg,
      quickReplayType,
    } = this.state;
    if (isEdit) {
      await request.put('/im/v2/quick_reply', {
        replyId: currReplyId,
        msgType: quickReplayType,
        content: quickReplayType === 0 ? editorValue : editorImg,
      });
      this.getQuickReplayList('', currReplyId);
    } else {
      await request.post('/im/v2/quick_reply', {
        groupId: currGroupId,
        msgType: quickReplayType,
        content: quickReplayType === 0 ? editorValue : editorImg,
      });
      this.getQuickReplayList();
    }
    this.setState({
      showAddQuickReplayDialog: false,
      isEdit: false,
      editorImg: '',
      editorValue: '',
    });
  };

  deleteQuickReplay = async (index: number) => {
    await request.delete(
      '/im/v2/quick_reply',
      { replyId: this.state.quickReplayList[index].id },
      true,
    );
    this.getQuickReplayList();
  };

  sortGroup = async (e: any) => {
    const { currGroupId, activeKey, groupList } = this.state;
    const id = parseInt(e.target.getAttribute('id'), 0);
    const key = parseInt(activeKey, 0);
    if ((key === 0 && (id === 1 || id === 3)) || (key === groupList.length - 1 && id === 2)) {
      return;
    }
    await request.put(
      '/im/v2/quick_reply/group/order',
      {
        groupId: currGroupId,
        reorder: id,
      },
      true,
    );
    const list = await this.getGroupList();
    list.filter((item: any, index: number) => {
      if (item.id === currGroupId) {
        this.setState({ activeKey: `${index}` });
      }
      return null;
    });
  };

  sortQuickReplay = async (e: any) => {
    const { currReplyId, quickReplayList } = this.state;
    const id = parseInt(e.target.getAttribute('id'), 0);
    let key;
    quickReplayList.filter((item: any, index: number) => {
      if (item.id === currReplyId) {
        key = index;
      }
      return null;
    });
    if ((key === 0 && (id === 1 || id === 3)) || (key === quickReplayList.length - 1 && id === 2)) {
      return;
    }
    await request.put(
      '/im/v2/quick_reply/order',
      {
        replyId: currReplyId,
        reorder: id,
      },
      true,
    );
    this.getQuickReplayList('', currReplyId);
  };

  handlerImgTextChange = (value: string) => {
    const { editorValue, pos } = this.state;
    const newString =
      editorValue.substring(0, pos.start) +
      value +
      editorValue.substring(pos.end, editorValue.length);
    this.setState({
      editorValue: newString,
      pos: {
        start: pos.start + value.length,
        end: pos.start + value.length,
      },
    });
  };

  handleChange = (e: any) => {
    const s = this.state;
    this.setState({
      ...s,
      ...e,
    });
  };

  render() {
    const {
      groupList,
      currReplyId,
      quickReplayList,
      activeKey,
      showAddGroupDialog,
      showAddQuickReplayDialog,
      quickReplayType,
      pos,
      editorValue,
      groupName,
      uploadProps,
      fileList,
      isEdit,
    } = this.state;
    const list = quickReplayList.map(item => ({
      id: item.id,
      content: item.content,
      type: item.msg_type,
    }));
    return (
      <div>
        <Layout className="quick-reply-layout">
          {/* {groupList.length ? ( */}
          <>
            <Sider theme="light">
              <Menu
                style={{ width: 303, height: '90.5%', overflow: 'auto', border: 0 }}
                selectedKeys={[activeKey]}
                onClick={(e: any) => {
                  // if (e.domEvent.target.nodeName !== 'DIV') {
                  //   return;
                  // }
                  this.setState({ activeKey: e.key });
                  this.getQuickReplayList(groupList[+e.key].id);
                }}
              >
                {groupList &&
                  groupList.map((item, index) => (
                    <Menu.Item key={index} className="quick-reply-item">
                      {/* <Dropdown overlay={groupMenu()} trigger={['contextMenu']}> */}
                      <div className="quick-reply-item-menu">
                        {item.name}
                        {activeKey === index.toString() ? (
                          <div className="quick-reply-item-menu-op">
                            <span
                              onClick={() => {
                                this.openAddGroupNameDialog(true);
                              }}
                            >
                              编辑
                            </span>
                            <span
                              onClick={() => {
                                this.deleteGroupName();
                              }}
                            >
                              删除
                            </span>
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                      {/* </Dropdown> */}
                    </Menu.Item>
                  ))}
              </Menu>
              <div style={{ width: '303px', marginTop: '16px' }}>
                <Button
                  size="small"
                  onClick={() => {
                    this.openAddGroupNameDialog(false);
                  }}
                >
                  <Icon type="plus" />
                  添加分组
                </Button>
                <div className="link-group" onClick={this.sortGroup}>
                  <a id="1">上移</a>
                  <a id="2">下移</a>
                  <a id="3">置顶</a>
                </div>
              </div>
            </Sider>
            <Content className="quick-reply-content">
              {list.length ? (
                <List
                  className="list-style"
                  size="small"
                  // bordered
                  dataSource={list}
                  renderItem={(item, index) => (
                    <List.Item
                      key={index}
                      style={{
                        background: currReplyId === item.id ? 'rgba(38, 38, 38, 0.07)' : '',
                      }}
                      className="quick-reply-item-li"
                      extra={
                        <div
                          className="quick-reply-item-content"
                          onClick={() => {
                            this.setState({ currReplyId: item.id });
                          }}
                        >
                          {item.type ? (
                            <img width={100} height={100} alt="logo" src={item.content} />
                          ) : (
                            item.content
                          )}
                          <div
                            className="quick-reply-item-menu-op"
                            style={{ top: '50%', transform: 'translateY(-50%)' }}
                          >
                            <span
                              onClick={() => {
                                this.openAddQuickReplayDialog(true, index);
                              }}
                            >
                              编辑
                            </span>
                            <span
                              onClick={() => {
                                this.deleteQuickReplay(index);
                              }}
                            >
                              删除
                            </span>
                          </div>
                        </div>
                        // </Dropdown>
                      }
                    ></List.Item>
                  )}
                />
              ) : (
                <Empty
                  className="im-empty-status"
                  style={{ height: '90.5%', overflow: 'auto' }}
                  image="/null.png"
                  description="没有快捷回复"
                />
              )}
              <div className="button-content">
                <Button
                  size="small"
                  onClick={() => {
                    this.openAddQuickReplayDialog(false);
                  }}
                >
                  <Icon type="plus" />
                  添加快捷回复
                </Button>
                <div className="link-group" onClick={this.sortQuickReplay}>
                  <a id="1">上移</a>
                  <a id="2">下移</a>
                  <a id="3">置顶</a>
                </div>
              </div>
            </Content>
          </>
          {/* ) : (
            <Empty
              style={{ height: '90.5%', overflow: 'auto' }}
              image="/noreply.png"
              description="未设置快捷回复"
            />
          )} */}
        </Layout>
        <Modal
          okText="确定"
          cancelText="取消"
          title={isEdit ? '编辑分组' : '新增分组'}
          visible={showAddGroupDialog}
          centered={false}
          onOk={() => this.addGroupName()}
          onCancel={() => {
            this.setState({ showAddGroupDialog: false });
          }}
        >
          <span style={{ paddingLeft: '52px', color: '#8C8C8C', fontSize: '16px' }}>
            分组名称：
          </span>
          <Input
            style={{ margin: '0 auto', width: '60%' }}
            placeholder="请输入分组名称，10个字以内"
            title="分组名称"
            value={groupName}
            onChange={(e: any) => this.setState({ groupName: e.target.value })}
            allowClear
          />
        </Modal>
        <Modal
          width={618}
          // height={300}
          okText="确定"
          cancelText="取消"
          title={isEdit ? '编辑快捷回复' : '新增快捷回复'}
          visible={showAddQuickReplayDialog}
          centered={false}
          onOk={() => this.addQuickReplay()}
          onCancel={() => {
            this.setState({ showAddQuickReplayDialog: false, editorImg: '', editorValue: '' });
          }}
        >
          <div className="chat-sub-title">
            类型：
            <Radio.Group
              value={this.state.quickReplayType}
              onChange={(e: any) => {
                this.setState({ quickReplayType: e.target.value, editorValue: '' });
              }}
            >
              <Radio value={0}>文本</Radio>
              <Radio value={1}>图片</Radio>
            </Radio.Group>
            <div className="chat-pannel1">
              <div className="chat-pannel-header1">
                <div className="chat-panel-header-left">
                  {quickReplayType === 0 ? (
                    <EmojiSuggestions onSelectChange={this.handlerImgTextChange} />
                  ) : (
                    <Upload
                      {...uploadProps}
                      accept=".png,.jpg,.tif,.jpeg"
                      disabled={!!fileList.length}
                      fileList={fileList.length ? fileList : []}
                      listType="picture-card"
                      onChange={fileObject => {
                        const { file, fileList: fileLists } = fileObject;
                        if (file.status !== 'uploading') {
                          this.setState({
                            editorImg: file.url || (file.response.data && file.response.data.url),
                          });
                        }
                        this.setState({ fileList: fileLists });
                      }}
                    >
                      <Button size="small" disabled={!!fileList.length}>
                        <Icon type="upload" /> 选择图片
                      </Button>
                    </Upload>
                  )}
                </div>
              </div>
              {quickReplayType === 0 ? (
                <div className="chat-pannel-content1">
                  <MessageSender
                    pos={pos}
                    editorValue={editorValue}
                    onChange={this.handleChange}
                  ></MessageSender>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default QuickReplaySetting;

import { all } from 'redux-saga/effects';
import { watchGetAccountDetail } from './accountDetail';
import { watchGetFriendList } from './friend';
import { watchGetGroupList } from './group';
import { watchMessage } from './message';
import { watchSession } from './session';
import { watchClient } from './client';
import { watchOperation } from './operation';
import { watchGetMediaLibraryList } from './media';

export default function*() {
  yield all([
    watchGetFriendList(),
    watchGetGroupList(),
    watchMessage(),
    watchSession(),
    watchClient(),
    watchGetAccountDetail(),
    watchOperation(),
    watchGetMediaLibraryList(),
  ]);
}

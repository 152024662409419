import React, { useState } from 'react';
import { Radio, Input } from 'antd';
import request from '@/http/request';
import ShowModal from '@/components/ShowModal';

const BlackPreviewModal = (props: any) => {
  const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
  };

  let cacheValue = 1;
  let cacheText = '';
  const blackText = ['发广告', '恶意刷屏', '私加好友', '说脏话、骂人'];
  const BlackModal = () => {
    const [value, setValue] = useState(1);
    const [text, setText] = useState('');
    return (
      <div style={{ textAlign: 'left' }}>
        <p style={{ color: '#aaa' }}>
          加入黑名单后，该用户将从所有群中被踢出，后续的好友申请和入群申请将不再自动通过
        </p>
        <p>拉黑原因：</p>
        <Radio.Group
          onChange={(e: any) => {
            cacheValue = e.target.value;
            setValue(cacheValue);
          }}
          value={value}
        >
          <Radio style={radioStyle} value={1}>
            {blackText[0]}
          </Radio>
          <Radio style={radioStyle} value={2}>
            {blackText[1]}
          </Radio>
          <Radio style={radioStyle} value={3}>
            {blackText[2]}
          </Radio>
          <Radio style={radioStyle} value={4}>
            {blackText[3]}
          </Radio>
          <Radio style={radioStyle} value={5}>
            其他......
            {value === 5 ? (
              <Input
                style={{ width: 340, marginLeft: 12 }}
                value={text}
                onChange={(e: any) => {
                  cacheText = e.target.value;
                  setText(cacheText);
                }}
              />
            ) : (
              ''
            )}
          </Radio>
        </Radio.Group>
      </div>
    );
  };

  ShowModal({
    props,
    width: 520,
    bodyStyle: {
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'center',
    },
    destroyOnClose: 'destroyOnClose',
    title: '加入黑名单',
    childrens: [BlackModal],
    onOk: () => {
      cacheText = cacheValue !== 5 ? blackText[cacheValue - 1] : cacheText;
      request
        .post('/im/v2/wx_user/black/list', {
          wxId: props.wxId,
          remark: cacheText,
          mid: props.mid,
        })
        .then((res: any) => {
          props.onOk();
        });
    },
  });
};

export default BlackPreviewModal;

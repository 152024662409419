import { BaseAction } from '../types/action';
import { ACTION_SET_CURRENT_SESSION_LIST_FILTER_CONDITION } from '../constant';

export function setCurrentSessionListFilterCondition(data: any): BaseAction {
  console.log(data);
  return {
    type: ACTION_SET_CURRENT_SESSION_LIST_FILTER_CONDITION,
    data,
  };
}

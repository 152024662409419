import React, { Fragment, useCallback, useState } from 'react';
import { Divider, List, Popover, Tooltip } from 'antd';
import clx from 'classnames';
import request from '@/http/request';
import { WxSession } from '@/store/types/state';

const miniHeadImage = (headImg: string = '') => {
  const size = headImg.indexOf('wework') > -1 ? 100 : 132;
  return headImg && headImg.substr(-2) === '/0'
    ? `${headImg.substr(0, headImg.length - 1)}${size}`
    : headImg;
};

interface Iprops {
  currentClient: any;
  owner: any;
  wxGroup: any;
  chatType: number;
  id: number;
  replaceOneSession: (data: { session: WxSession; oldId: number }) => any;
}

function useSwitchAccount(props: Iprops) {
  const [visible, setVisible] = useState(false);
  const [managers, setManagers] = useState<any[]>([]);
  const [shills, setShills] = useState<any[]>([]);
  const handleClick = useCallback(
    (item: any) => {
      setVisible(false);
      const putData = async () => {
        const res = await request.put(`/im/v2/groups/${props.owner.groupId}/manager`, {
          wxId: item.wxId,
        });
        if (res) {
          // props.replaceOneSession({ session: res.data, oldId: props.id });
          window.location.replace(window.location.href.replace(props.id.toString(), res.data.id));
        }
      };
      if (props.owner.wxId !== item.wxId) putData();
    },
    [props.owner, props.id, props.replaceOneSession],
  );

  const handleVisible = useCallback(
    (val: boolean) => {
      if (props.chatType === 3) return;
      const fetchData = async () => {
        const res = await request.get(`/im/v2/groups/${props.owner.groupId}/manager`);
        setManagers(res.data.managers || []);
        setShills(res.data.shills || []);
      };
      if (val) fetchData();
      setVisible(val);
    },
    [setVisible, props.chatType, props.owner],
  );

  return {
    managers,
    shills,
    visible,
    handleClick,
    handleVisible,
  };
}

const ChatPanelHeader: React.FC<Iprops> = props => (
  <Fragment>
    {(() => {
      const { wxGroup, owner } = props;
      const { managers, shills, visible, handleClick, handleVisible } = useSwitchAccount(props);
      return (
        <div style={{ padding: '0 6px' }}>
          <div className="main-header session">
            <div
              className={clx('header-box', {
                'reported-box': wxGroup.secureStatus,
              })}
            >
              <img
                alt="头像"
                className="target-img"
                src={wxGroup.groupHeadImg || '/defaultHead.jpg'}
              />
              <div className="ellipsis-name">
                <Tooltip title={wxGroup.groupName}>{wxGroup.groupName}</Tooltip>
              </div>
            </div>
            <Popover
              autoAdjustOverflow
              arrowPointAtCenter
              content={
                <>
                  <List
                    bordered
                    itemLayout="horizontal"
                    dataSource={managers}
                    renderItem={(item: any, index: number) => (
                      <List.Item
                        key={index}
                        className="account-list"
                        style={{
                          background: owner.wxId === item.wxId ? '#ededed' : '',
                        }}
                        onClick={() => {
                          handleClick(item);
                        }}
                      >
                        <List.Item.Meta
                          title={
                            <span className="account-item">
                              {item.nickname}
                              <span style={{ color: 'red' }}>
                                {item.onlineStatus ? '' : ' [离线] '}
                              </span>
                              <img
                                alt="头像"
                                style={{ width: '20px', height: '20px', margin: '-3px 10px 0' }}
                                src={item.headImg}
                              />
                            </span>
                          }
                        />
                      </List.Item>
                    )}
                  />
                  <Divider />
                  {shills.length > 0 ? (
                    <List
                      bordered
                      itemLayout="horizontal"
                      dataSource={shills}
                      renderItem={(item: any, index: number) => (
                        <List.Item
                          key={index}
                          className="account-list"
                          style={{
                            background: owner.wxId === item.wxId ? '#ededed' : '',
                          }}
                          onClick={() => {
                            handleClick(item);
                          }}
                        >
                          <List.Item.Meta
                            title={
                              <span className="account-item">
                                {item.nickname}
                                <span style={{ color: 'red' }}>
                                  {item.onlineStatus ? '' : ' [离线] '}
                                </span>
                                <img
                                  alt="头像"
                                  style={{
                                    width: '20px',
                                    height: '20px',
                                    margin: '-3px 10px 0',
                                  }}
                                  src={item.headImg}
                                />
                              </span>
                            }
                          />
                        </List.Item>
                      )}
                    />
                  ) : null}
                </>
              }
              overlayStyle={{ width: '288px' }}
              placement="bottom"
              trigger="click"
              onVisibleChange={() => handleVisible(!visible)}
              visible={visible}
            >
              <div
                className={clx({
                  'offline-box': !owner.onlineStatus,
                })}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <div className="ellipsis-name" style={{ color: '#8C8C8C' }}>
                  <Tooltip title={owner.nickname}>{owner.nickname}</Tooltip>
                </div>
                <img
                  alt="头像"
                  className="client-img"
                  src={miniHeadImage(owner.avatar) || '/defaultHead.jpg'}
                />
              </div>
            </Popover>
          </div>
          <Divider style={{ margin: 0 }} />
        </div>
      );
    })()}
  </Fragment>
);

export default ChatPanelHeader;

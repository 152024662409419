import React from 'react';
import { Layout, Empty } from 'antd';
import { connect } from 'react-redux';
import { State, Target, WxSession, TabUnReadCount } from '@/store/types/state';
import { TARGET_TYPE_SESSION } from '@/store/constant';
import './index.less';
import {
  createSetCurrentTargetAction,
  createSetTabUnReadCountAction,
  createGetWxMessageListAction,
  createCreateWxSessionAction,
  createMergeWxMessageListAction,
  createTopSingleSessionAction,
  createGetGroupMemberListAction,
  createReplaceSessionAction,
} from '@/store/action';
import createWebSocket from '@/ws';
import httpConfig from '@/http/config';
import request from '@/http/request';
import eventBus from '@/ws/event';
import { WebSocketType } from '../../../../ws/types';
import ChatPanelHeader from './components/ChatPanelHeader';
import ChatWindow from './components/ChatWindow';

let throttleSessionList = false;
function filterArrayObj(unRead: any) {
  const obj: any = {};
  if (unRead) {
    if (unRead.chatType === 3) {
      obj.subUnReadCount = unRead.unReadCount;
    } else {
      obj.qunUnReadCount = unRead.unReadCount;
      obj.undeliveredCount = unRead.undeliveredCount;
    }
  }
  return obj;
}

interface Prop extends React.HTMLAttributes<HTMLDivElement> {
  target: any;
  info: any;
  clients: any;
  setCurrentTarget: (data: Target) => any;
  setTabUnReadCount: (data: TabUnReadCount) => any;
  getMessageList: (param: any, replace: boolean) => any;
  getMemeberList: (param: any, merge: boolean) => void;
  sessionList: any;
  createSession: (chatId: string) => any;
  mergeMessage: (
    data: any,
    sessionId: any,
    isNew: boolean,
    isUpdate: boolean,
    isReplace: boolean,
  ) => any;
  topSession: (data: any) => any;
  message: any;
  currentSiderTab: string;
  currentSessionListFilterCondition: any;
  replaceOneSession: (data: { session: WxSession; oldId?: number }) => any;
}

class ChatPanel extends React.Component<Prop> {
  componentDidMount() {
    eventBus.on('getSessionMessageList', (data: any) => {
      this.getSessionMessageList(data.replace, data.isNew);
    });
    request
      .get('/im/v2/session/overview', {
        category: 0, // 0普通会话 1裂变会话
      })
      .then((res: any) => {
        this.props.setTabUnReadCount({
          ...filterArrayObj(res.data['2']),
          ...filterArrayObj(res.data['3']),
        });
      });
  }

  componentDidUpdate(prevProps: Prop) {
    if (this.props.clients.currentUserId && !prevProps.clients.currentUserId) {
      this.WSInit();
    }
    this.messageListUpdate(prevProps);
  }

  ws: WebSocketType | null = null;

  throttleSessionListData: any = [];

  initLoading: boolean = true;

  getSessionMessageList = (replace: boolean = true, isNew: boolean = true, limit: number = 30) => {
    const {
      target,
      getMessageList,
      message: { messageNextIndex },
    } = this.props;
    if (target && target.type === TARGET_TYPE_SESSION) {
      const param = {
        chatType: target.data.chatType,
        topicId:
          target.data.chatType === 3 ? target.data.chatId.split('@topic')[0] : target.data.chatId,
        limit,
        isNew,
        sessionId: target.id,
        nextIndex: messageNextIndex,
      };
      getMessageList(param, replace);
    }
  };

  messageListUpdate = (preProps: any) => {
    const { target } = this.props;
    const preTarget = preProps.target;
    // 添加胖多逻辑, 发消息时不触发获取会话列表
    if (!!preTarget && !!target && preTarget.id === target.id) {
      return;
    }
    if (!preTarget || !target) {
      this.getSessionMessageList(true, false);
    } else if (preTarget.id !== target.id) {
      const LIMIT = 30;
      // messageList一次加载的数量条件
      // 若当前会话
      this.getSessionMessageList(
        true,
        false,
        target.currSessionUnreadNum &&
          target.currSessionUnreadNum > LIMIT &&
          target.currSessionUnreadNum < LIMIT * 2
          ? target.currSessionUnreadNum
          : LIMIT,
      );
    }
  };

  handlerDetailClick = (chatId: string) => {
    this.props.createSession(chatId);
  };

  handlerScrollToTop = () => {
    if (this.props.message && this.props.message.messageIsEnd) return;
    this.getSessionMessageList(false, false);
  };

  // 处理当前会话信息
  // PREF: 性能优化
  handlerSession = (receiveData: any) => {
    const { target, topSession } = this.props;
    const tempData: any = { ...receiveData };
    // 如果处于当前会话，则推送的消息newUnread置为0
    if (target && target.id === receiveData.id) {
      tempData.newUnread = 0;
      tempData.newUnreadAt = 0;
    }

    // 消息位置重排
    // 这里会导致页面卡顿, 添加时间锁及队列来批量更新会话
    this.throttleSessionListData = {
      ...this.throttleSessionListData,
      [tempData.id]: tempData,
    };
    if (!throttleSessionList) {
      throttleSessionList = true;
      setTimeout(() => {
        throttleSessionList = false;
        topSession(
          Object.keys(this.throttleSessionListData).map(key => this.throttleSessionListData[key]),
        );
        this.throttleSessionListData = {};
      }, 1200);
    }
  };

  handlerWsMsg = (msg: any): any => {
    const { data } = msg;
    const { target, currentSiderTab, currentSessionListFilterCondition } = this.props;
    // cmdId：返回对应操作数，对应某个操作
    // msg.cmdId = 9 返回数组
    if (Array.isArray(data)) {
      this.props.setTabUnReadCount({ ...filterArrayObj(data[0]), ...filterArrayObj(data[1]) });
    }
    switch (msg.cmdId) {
      // 新消息
      case 2: {
        // 若接收到的消息为当前账号发送的（微信客户端发送，或者系统控制自动回复），则会话未读数不进行更新
        // if (data.lastMsg && data.lastMsg.fromWxid === currClientWxId) {
        //   delete data.newUnread;
        // }
        if (
          parseInt(currentSiderTab, 10) === 2 &&
          data.chatType === 2 &&
          ((parseInt(currentSessionListFilterCondition.focus, 10) === 1 && data.newUnread === 0) ||
            (parseInt(currentSessionListFilterCondition.focus, 10) === 5 && data.undelivered === 0))
        ) {
          return;
        }
        if (parseInt(currentSiderTab, 10) === data.chatType) {
          this.handlerSession(data);
        }
        // 新消息隶属当前聊天窗口，拉取message即可
        if (target && target.type === TARGET_TYPE_SESSION && target.id === data.id) {
          this.getSessionMessageList(false, true);
        }
        break;
      }
      // 消息操作
      case 6: {
        this.updateMessageList(data);
        break;
      }
      case 7: {
        // 更新右侧功能区的群成员列表, 需要判断是不是当前的group
        if (target && target.data && target.data.chatId === data.chatId) {
          this.props.getMemeberList(
            {
              groupId: data.chatId,
              start: 0,
              count: 1000,
            },
            false,
          );
        }
        break;
      }
      default:
        break;
    }
  };

  updateMessageList = (data: any) => {
    this.props.mergeMessage(data, data.sessionId, false, false, true);
  };

  WSInit = async () => {
    if (this.ws) {
      // 账号切换时，传递code 4444，不对ws关闭进行重连操作，避免同时维护多个ws导致的消息串号
      if (this.ws.wsObj) {
        this.ws.wsObj.close(4444);
        this.ws.wsObj = null;
      }
      this.ws = null;
    }
    const protocol = window.location.protocol === 'http:' ? 'ws' : 'wss';
    this.ws = createWebSocket(
      `${protocol}://${httpConfig.websocket}/websocket/accounts/${this.props.clients.currentUserId}`,
      false,
    );
    if (this.initLoading) {
      if (this.ws) {
        this.ws.onMessage(this.handlerWsMsg);
      }
      this.initLoading = false;
    }
  };

  render() {
    const { target, clients, info } = this.props;
    return (
      <Layout.Content className="main-layout">
        {target && target.type && info ? (
          <ChatPanelHeader
            currentClient={clients.current}
            {...info}
            type={target.type}
            replaceOneSession={data => {
              this.props.replaceOneSession(data);
              eventBus.emit('handlerChatItemClick', data.session);
            }}
          />
        ) : (
          ''
        )}
        {(() => {
          if (!target || (target.type === 'TARGET_TYPE_SESSION' && !target.id)) {
            return (
              <Empty
                className="no-choosed-session-container im-empty-status"
                description="没有选择聊天"
              />
            );
          }

          switch (target.type) {
            case TARGET_TYPE_SESSION:
              if (!target.data) return null;
              return (
                <ChatWindow
                  messageList={target.data.messageList}
                  onScrollToTop={this.handlerScrollToTop}
                  target={target}
                  wxId={clients.current.wxId}
                />
              );
            default:
              return null;
          }
        })()}
      </Layout.Content>
    );
  }
}

function getTarget(state: State) {
  if (!state.currentTarget || !state.currentTarget.id) return null;
  let data;
  const { type, id, currSessionUnreadNum } = state.currentTarget;
  switch (type) {
    case TARGET_TYPE_SESSION:
      data = {
        ...state.sessionList.find(session => session.id === id),
        messageList: state.messageMap[id as number],
        nickname: state.currentTarget.nickname,
      };
      break;
    default:
      data = null;
      break;
  }
  return { type, id, data, currSessionUnreadNum };
}

function mapStateToProps(state: State) {
  return {
    clients: state.clients,
    target: getTarget(state),
    sessionList: state.sessionList,
    message: state.messageMap,
    currentSiderTab: state.currentSiderTab,
    currentGroupInfo: state.currentGroupInfo,
    currentSessionListFilterCondition: state.currentSessionListFilterCondition,
    info: state.info,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    setCurrentTarget: (data: Target) => dispatch(createSetCurrentTargetAction(data)),
    setTabUnReadCount: (data: TabUnReadCount) => dispatch(createSetTabUnReadCountAction(data)),
    getMessageList: (param: any, replace: boolean) =>
      dispatch(createGetWxMessageListAction(param, replace)),
    createSession: (chatId: string) => dispatch(createCreateWxSessionAction({ chatId })),
    mergeMessage: (
      data: any,
      sessionId: any,
      isNew: boolean,
      isUpdate: boolean,
      isReplace: boolean,
    ) => dispatch(createMergeWxMessageListAction(data, sessionId, isNew, isUpdate, isReplace)),
    topSession: (data: any) => dispatch(createTopSingleSessionAction(data)),
    getMemeberList: (param: any, merge: boolean) =>
      dispatch(createGetGroupMemberListAction(param, merge)),
    replaceOneSession: (data: { session: WxSession; oldId?: number }) =>
      dispatch(createReplaceSessionAction(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatPanel);

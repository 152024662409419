import React, { PureComponent } from 'react';
import { Provider } from 'react-redux';
import { Layout } from 'antd';
import { BrowserRouter } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import store from '@/store';
import routes from './router';
import './App.less';

const { Content } = Layout;

class App extends PureComponent {
  render() {
    return (
      <Provider store={store}>
        <BrowserRouter>
          <Layout>
            <Content
              style={{
                background: '#fff',
                height: '100vh',
                width: '100vw',
                overflow: 'hidden',
              }}
            >
              {renderRoutes(routes)}
            </Content>
          </Layout>
        </BrowserRouter>
      </Provider>
    );
  }
}

export default App;

import React, { PureComponent } from 'react';
import { Layout } from 'antd';
import { connect } from 'react-redux';
import { State, WxSession } from '@/store/types/state';
import { combineDraftAndSessionList } from '@/util/draft';
import SiderTab from './component/SiderTab';
import styles from './SessionPanel.module.less';

class SessionPanel extends PureComponent<any> {
  render() {
    return (
      <Layout.Sider className={styles.sider}>
        {this.props.currentClient ? <SiderTab sessionList={this.props.sessionList} /> : null}
      </Layout.Sider>
    );
  }
}

function mapStateToProps(state: State) {
  const { sessionList, currentTarget } = state;
  const list: WxSession[] = combineDraftAndSessionList(sessionList, currentTarget);
  return {
    sessionList: list,
    currentClient: state.clients.current,
  };
}

export default connect(mapStateToProps)(SessionPanel);

import React, { Component } from 'react';
import { Typography, Checkbox, Input } from 'antd';
import request from '@/http/request';

const { Paragraph, Text } = Typography;

const { TextArea } = Input;

interface State {
  note: string;
  checkedStatus: boolean[];
}

class RecommendModal extends Component<any, State> {
  constructor(props: {}) {
    super(props);
    this.state = {
      note: '',
      checkedStatus: [],
    };
  }

  componentDidMount() {
    const { title, payPrice, source } = this.props.commodity;
    const { images, promotionCode, onRef } = this.props;
    const { length } = images;
    const note =
      source === 1
        ? `${title}\n【券后价】${payPrice}元\n-----------------\n(${promotionCode})\n复/制本段/文字，打开~陶~宝`
        : `${title}\n券后价：${payPrice}元\n商品链接：${promotionCode}`;
    const checkedStatus = new Array(length);
    if (length) {
      checkedStatus[0] = true;
    }
    this.setState({
      note,
      checkedStatus,
    });
    onRef(this);
  }

  handlerCheckBox = (index: number) => {
    this.setState(prevState => {
      const newCheckdStatus = [...prevState.checkedStatus];
      newCheckdStatus[index] = !newCheckdStatus[index];
      return {
        checkedStatus: newCheckdStatus,
      };
    });
  };

  render() {
    const { note, checkedStatus } = this.state;
    const { images } = this.props;
    return (
      <Typography className="recommend-modal">
        <Text strong>图片素材</Text>
        <Paragraph style={{ display: 'flex' }}>
          {images.length
            ? images.map((img: any, index: number) => {
                return index > 4 ? (
                  ''
                ) : (
                  <div
                    className="recommend-modal-img-container"
                    key={index}
                    onClick={() => this.handlerCheckBox(index)}
                  >
                    <img className="recommend-modal-img" src={img} alt="商品图片" />
                    <Checkbox className="recommend-modal-checkbox" checked={checkedStatus[index]} />
                  </div>
                );
              })
            : ''}
        </Paragraph>
        <Text strong>文案素材</Text>
        <Paragraph>
          <TextArea
            name="note"
            id="note"
            style={{ resize: 'none', width: '580px', height: '118px' }}
            onChange={e => {
              this.setState({
                note: e.target.value,
              });
            }}
            placeholder="请输入客户备注，200个字以内"
            value={note}
          ></TextArea>
        </Paragraph>
      </Typography>
    );
  }
}

export default RecommendModal;

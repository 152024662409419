import { BaseAction } from '../types/action';
import { ACTION_SET_GROUPMEMBER_LIST, ACTION_MERGE_GROUPMEMBER_LIST } from '../constant';

export default (state: any = null, action: BaseAction) => {
  switch (action.type) {
    case ACTION_SET_GROUPMEMBER_LIST:
      return action.data;
    case ACTION_MERGE_GROUPMEMBER_LIST:
      return {
        ...state,
        isEnd: action.data.isEnd,
        nextIndex: action.data.nextIndex,
        count: state.count + action.data.count,
        list: state.list.concat(action.data.list),
      };
    default:
      return state;
  }
};

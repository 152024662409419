import React, { memo, useState } from 'react';
import { Modal, Icon } from 'antd';
import './Video.less';

const Video = memo((props: any) => {
  const [show, setShow] = useState(false);
  let video: any = null;

  return (
    <div className={props.className}>
      <div
        className={props.className ? 'message-video-detail-video' : 'video'}
        style={{
          background: `url(${props.imageUrl}) center center`,
        }}
        onClick={() => setShow(true)}
      >
        <div className="video-background">
          <Icon type="play-circle" className="video-icon" theme="filled" />
        </div>
      </div>

      <Modal
        visible={show}
        onCancel={() => {
          setShow(false);
          if (video) video.pause();
        }}
        footer={null}
        width={760}
        style={{ textAlign: 'center' }}
        centered
        title={<h3 className="send-title">视频预览</h3>}
      >
        <div style={{ textAlign: 'center' }}>
          <video
            autoPlay
            src={props.videoUrl}
            className="video-content see-img-modal"
            poster={props.imageUrl}
            controls
            ref={node => {
              video = node;
            }}
          >
            <track kind="captions" src={props.videoUrl}></track>
          </video>
        </div>
      </Modal>
    </div>
  );
});

export default Video;

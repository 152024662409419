import React, { memo, useState, useEffect, useRef, useCallback } from 'react';
import { PageHeader, Tabs, Icon } from 'antd';
import './index.less';
import MediaList from './MediaList';
import AllRecordList from './AllRecordList';

const { TabPane } = Tabs;

const RecordPannel = memo((props: any) => {
  const [showSearchDetail, setShowSearchDetail] = useState(false);
  const [message, setMessage] = useState({
    mid: '',
  });
  const scrollListRef = useRef<AllRecordList>(null);
  const goToMessage = useCallback(
    (messageItem: any) => {
      setShowSearchDetail(true);
      setMessage(messageItem);
    },
    [setShowSearchDetail, setMessage],
  );
  useEffect(() => {
    if (scrollListRef.current && showSearchDetail && message.mid) {
      scrollListRef.current.goToMessage(message);
    }
  }, [scrollListRef]);

  return (
    <div>
      <Tabs defaultActiveKey="1" tabBarStyle={{ textAlign: 'center', marginBottom: '16px' }}>
        <TabPane tab="全部" key="1">
          <AllRecordList {...props} canUp={false} searchBar></AllRecordList>
        </TabPane>
        <TabPane tab="图片" key="2">
          <MediaList {...props} queryType={2}></MediaList>
        </TabPane>
        <TabPane tab="视频" key="3">
          <MediaList {...props} queryType={3}></MediaList>
        </TabPane>
      </Tabs>
      {showSearchDetail && (
        <div className="fixed">
          <PageHeader
            onBack={() => {
              setShowSearchDetail(false);
            }}
            title="返回"
            backIcon={<Icon type="left" style={{ color: '#8C8C8C' }} />}
          >
            <AllRecordList
              {...props}
              mid={message.mid}
              ref={scrollListRef}
              canUp
              scrollImmediately
              key={1}
              searchBar={false}
            ></AllRecordList>
          </PageHeader>
        </div>
      )}
    </div>
  );
});

export default RecordPannel;

import { BaseAction } from '../types/action';
import { ACTION_SET_CURRENT_SESSION_LIST_FILTER_CONDITION } from '../constant';

export default (state: any = { focus: '0', divisionId: '0' }, action: BaseAction) => {
  switch (action.type) {
    case ACTION_SET_CURRENT_SESSION_LIST_FILTER_CONDITION:
      return action.data;
    default:
      return state;
  }
};

import React, { Component } from 'react';
import { Radio, Row, Col, Divider, Button, Input, Table, Select, Empty, Tooltip } from 'antd';
import request from '@/http/request';

const { Search } = Input;

const { Option } = Select;

const columns = [
  {
    title: '备注/昵称',
    dataIndex: 'nickname',
    render: (text: any) => (
      <Tooltip title={text}>
        <div>{text.length > 10 ? text.slice(0, 10).concat('...') : text}</div>
      </Tooltip>
    ),
  },
  {
    title: '性别',
    dataIndex: 'sex',
  },
  {
    title: '地区',
    dataIndex: 'address',
    render: (address: any) => (
      <Tooltip title={address}>
        <div>{address}</div>
      </Tooltip>
    ),
  },
  {
    title: '微信号',
    dataIndex: 'wxAlias',
    render: (wxAlias: any) => (
      <Tooltip title={wxAlias}>
        <div>{wxAlias}</div>
      </Tooltip>
    ),
  },
];

const locale = {
  emptyText: (
    <Empty
      className="no-data-container im-empty-status"
      description="暂无数据"
      image="/nodata2.png"
    ></Empty>
  ),
};

interface Props {
  friendList: any[];
  memberlist: any[];
  currClientNickname: string;
  onRefCopyData: (e: any) => any;
  onRef: (e: any) => any;
}

interface State {
  // list
  data: any[];
  selectedRowKeys: any[];
  selectedRows: any[];
  // search list
  searchData: any[];
  searchSelectedRowKeys: any[];
  searchSelectedRows: any[];
  searchTempData: any[];
  // copy list
  copyData: any[];
  doCopySearch: boolean;
  searchCopyData: any[];
  search: string;
  copySearch: string;
  btnValue: any;
  doSearch: boolean;
  labelValue: string;
  labels: any[];
  selectLabelRows: any;
}

class AddQunMember extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      data: [],
      selectedRowKeys: [],
      selectedRows: [],
      searchData: [],
      searchSelectedRowKeys: [],
      searchSelectedRows: [],
      searchTempData: [],
      copyData: [],
      doCopySearch: false,
      searchCopyData: [],
      search: '',
      copySearch: '',
      btnValue: '0',
      doSearch: false,
      labelValue: '0',
      labels: [],
      selectLabelRows: [],
    };
    this.filterDoSwitch = this.filterDoSwitch.bind(this);
    this.updateCopyData = this.updateCopyData.bind(this);
  }

  isLastSearch: boolean = false;

  timeout: any;

  currentValue: string = '';

  columns1 = [
    {
      title: '操作',
      render: (text: any, row: any, index: any) => (
        <Button
          size="small"
          className="member-cancel im-body-font-small"
          onClick={() => this.cancelSingleCopyData(text, row)}
        >
          取消
        </Button>
      ),
    },
  ];

  copyColumns = [...columns, ...this.columns1];

  cancelSingleCopyData = (text: any, row: any) => {
    let index;
    if (this.state.doSearch) {
      this.state.searchSelectedRows.filter((item, idx) => {
        if (item.wxId === row.wxId) {
          index = idx;
        }
        return 0;
      });
      this.state.searchSelectedRows.splice(index || 0, 1);
      this.state.searchSelectedRowKeys.splice(index || 0, 1);
      this.state.searchTempData.splice(index || 0, 1);
      this.state.copyData.filter((item, idx) => {
        if (item.wxId === row.wxId) {
          index = idx;
        }
        return 0;
      });
      this.state.copyData.splice(index || 0, 1);
    } else {
      this.state.selectedRows.filter((item, idx) => {
        if (item.wxId === row.wxId) {
          index = idx;
        }
        return 0;
      });
      this.state.selectedRows.splice(index || 0, 1);
      this.state.selectedRowKeys.splice(index || 0, 1);
      this.clearQunData = this.clearQunData.bind(this);
    }
    if (this.state.doCopySearch) {
      this.state.searchCopyData.filter((item, idx) => {
        if (item.wxId === row.wxId) {
          index = idx;
        }
        return 0;
      });
      this.state.searchCopyData.splice(index || 0, 1);
    }
    const {
      copyData,
      searchCopyData,
      selectedRows,
      searchSelectedRows,
      selectedRowKeys,
      searchSelectedRowKeys,
      searchTempData,
    } = this.state;
    this.setState({
      copyData,
      searchCopyData,
      selectedRows,
      searchSelectedRows,
      selectedRowKeys,
      searchSelectedRowKeys,
      searchTempData,
    });
  };

  cancelAllCopyData = () => {
    this.setState({
      copyData: [],
      selectedRows: [],
      searchSelectedRows: [],
      selectedRowKeys: [],
      searchSelectedRowKeys: [],
      searchTempData: [],
    });
  };

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }

  componentDidUpdate() {
    if (this.state.data.length || this.state.doSearch) return;
    this.getNormalLabels();
    this.combineData(this.props.friendList);
  }

  clearQunData() {
    this.setState({
      doSearch: false,
      selectedRowKeys: [],
      selectedRows: [],
      searchData: [],
      searchSelectedRowKeys: [],
      searchSelectedRows: [],
      searchTempData: [],
      copyData: [],
      doCopySearch: false,
      searchCopyData: [],
      search: '',
      copySearch: '',
      selectLabelRows: [],
    });
  }

  getNormalLabels = () => {
    request.get('v2/label/common').then((res: any) => {
      this.setState({
        labels: res.data,
      });
    });
  };

  handleSearch = (value: string) => {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }
    this.currentValue = value;
    this.timeout = setTimeout(() => {
      request.get('v2/label/search', { query: value }).then((res: any) => {
        if (this.currentValue === value) {
          const { data } = res;
          this.setState({
            labels: data,
          });
        }
      });
    }, 300);
  };

  combineData = (list: any[], isSearch?: boolean) => {
    let result: any[] = [];
    // let keyItem: any[] = [];
    // const ctxWx = this.props.currClientNickname;
    result = list.map((item, index) => {
      // if (this.state.data) {
      //   keyItem = this.state.data.filter(d => d.wxId === item.wxId);
      // }
      return {
        key: item.friendId || item.wxId,
        nickname: item.remarkName || item.nickname,
        sex: item.sex === 1 ? '男' : '女',
        address: item.city,
        wxId: item.friendId || item.wxId,
        wxAlias: item.wxAlias || item.friendId || item.wxId,
        // ctxWx,
      };
    });
    if (!isSearch) {
      this.setState({
        data: result,
      });
      this.clearQunData();
    } else {
      this.isLastSearch = isSearch;
      const { search, selectLabelRows, doSearch, searchTempData } = this.state;
      this.setState({
        doSearch: isSearch,
        searchData: result,
        searchTempData: (search || selectLabelRows.length) && !doSearch ? [] : searchTempData,
      });
    }
  };

  searchData = () => {
    let searchLabelsId = [];
    if (this.state.selectLabelRows.length) {
      searchLabelsId = this.state.selectLabelRows.map((item: any) => {
        return parseInt(item, 0);
      });
    }
    request
      .get('v2/friend/list', {
        page: 0,
        perpage: 5000,
        labelIds: JSON.stringify(searchLabelsId),
        labelExclude: parseInt(this.state.labelValue, 0),
        word: this.state.search,
        wordExclude: parseInt(this.state.btnValue, 0),
      })
      .then((res: any) => {
        this.combineData(res.data.list, true);
      });
  };

  searchCopyDatas = () => {
    const { copyData, copySearch } = this.state;
    if (copySearch) {
      let searchCopyData = copyData;
      searchCopyData = copyData.filter(
        item =>
          item.nickname.toLocaleLowerCase().indexOf(copySearch.toLocaleLowerCase()) >= 0 ||
          (item.remarkName &&
            item.remarkName.toLocaleLowerCase().indexOf(copySearch.toLocaleLowerCase()) >= 0) ||
          (item.wxAlias &&
            item.wxAlias.toLocaleLowerCase().indexOf(copySearch.toLocaleLowerCase()) >= 0),
      );
      this.setState({ searchCopyData, doCopySearch: true });
    } else {
      this.setState({ copyData, doCopySearch: false });
    }
  };

  onChangeHaneler = (keys: any, rows: any) => {
    if (!this.state.doSearch) {
      this.setState({
        selectedRowKeys: keys,
        selectedRows: rows,
        copyData: rows,
      });
    } else {
      this.setState({
        searchSelectedRowKeys: keys,
        searchSelectedRows: rows,
        // copyData: rows,
      });
    }
  };

  onSelectHandler = (item: any, selected: any) => {
    const { search, doSearch, searchTempData, selectLabelRows } = this.state;
    if (doSearch && (search || selectLabelRows.length)) {
      if (!searchTempData.length) {
        searchTempData.push({ item, selected });
      } else {
        for (let i = 0; i < searchTempData.length; i += 1) {
          if (item.wxId === searchTempData[i].item.wxId) {
            searchTempData[i].item = item;
            searchTempData[i].selected = selected;
            break;
          }
          if (i === searchTempData.length - 1) {
            searchTempData.push({ item, selected });
          }
        }
      }
      this.setState({ searchTempData }, () => {
        this.updateCopyData();
      });
    }
  };

  onSelectAllHandler = (selected: any, selectedRows: any, changeRows: any) => {
    const { search, doSearch, searchTempData, selectLabelRows } = this.state;
    if (doSearch && (search || selectLabelRows.length) && selectedRows) {
      if (!searchTempData.length) {
        selectedRows.forEach((item: any) => {
          searchTempData.push({ item, selected });
        });
      } else if (!selectedRows.length) {
        searchTempData.forEach((item: any) => {
          const innerItem = item;
          innerItem.selected = false;
        });
      } else {
        selectedRows.forEach((item: any) => {
          for (let i = 0; i < searchTempData.length; i += 1) {
            if (item.wxId === searchTempData[i].item.wxId) {
              searchTempData[i].item = item;
              searchTempData[i].selected = selected;
              break;
            }
            if (i === searchTempData.length - 1) {
              searchTempData.push({ item, selected });
            }
          }
        });
      }
      this.setState({ searchTempData }, () => {
        this.updateCopyData();
      });
    }
  };

  updateCopyData() {
    const { searchTempData, copyData } = this.state;
    if (searchTempData.length) {
      if (!copyData.length) {
        searchTempData.forEach(tempData => {
          if (tempData.selected) {
            copyData.push(tempData.item);
          }
        });
      } else {
        searchTempData.forEach(tempData => {
          for (let i = 0; i < copyData.length; i += 1) {
            if (tempData.item.wxId === copyData[i].wxId) {
              if (!tempData.selected) {
                copyData.splice(i, 1);
              }
              break;
            }
            if (i === copyData.length - 1 && tempData.selected) {
              copyData.push(tempData.item);
            }
          }
        });
      }
    }
    this.setState({ copyData });
  }

  setCheckboxStatus = (selectedRow: any) => {
    const result = this.props.memberlist.filter(item => item.wxId === selectedRow.wxId);
    const selections = this.state.selectedRows.filter(item => item.wxId === selectedRow.wxId);
    const searchSelections = this.state.searchSelectedRows.filter(
      item => item.wxId === selectedRow.wxId,
    );
    return {
      disabled: !!result.length,
      checked: !!result.length || !!selections.length || !!searchSelections.length,
    };
  };

  filterDoSwitch() {
    const {
      search,
      selectLabelRows,
      doSearch,
      selectedRows,
      searchData,
      searchSelectedRows,
      data,
      searchTempData,
    } = this.state;
    if (!this.isLastSearch) return;
    const keys: any[] = [];
    const rows: any[] = [];
    // 切换search tab时; 更新映射关系
    if (doSearch && (search || selectLabelRows.length)) {
      // selectedRows(data) ---> searchSelectedRows(searchData)
      selectedRows.forEach((d, index) => {
        searchData.filter((item, idx) => {
          if (item.wxId === d.wxId) {
            keys.push(item.wxId);
            rows.push(searchData[idx]);
          }
          return 0;
        });
      });
      if (searchTempData.length) {
        searchTempData.forEach(tempData => {
          for (let i = 0; i < searchSelectedRows.length; i += 1) {
            if (tempData.item.wxId === searchSelectedRows[i].wxId) {
              if (!tempData.selected) {
                searchSelectedRows.splice(i, 1);
              }
              break;
            }
            if (i === searchSelectedRows.length - 1 && tempData.selected) {
              searchSelectedRows.push(tempData.item);
            }
          }
        });
        searchSelectedRows.forEach((d, index) => {
          searchData.filter((item, idx) => {
            if (item.wxId === d.wxId) {
              keys.push(item.wxId);
            }
            return 0;
          });
        });
      }
      this.setState({
        searchSelectedRowKeys: keys,
        searchSelectedRows: rows,
      });
    } else {
      // searchSelectedRows(searchData) ---> selectedRows(data)
      if (!selectedRows.length) {
        searchTempData.forEach(tempData => {
          if (tempData.selected) {
            selectedRows.push(tempData.item);
          }
        });
      } else {
        searchTempData.forEach(tempData => {
          for (let i = 0; i < selectedRows.length; i += 1) {
            if (tempData.item.wxId === selectedRows[i].wxId) {
              if (!tempData.selected) {
                selectedRows.splice(i, 1);
              }
              break;
            }
            if (i === selectedRows.length - 1 && tempData.selected) {
              selectedRows.push(tempData.item);
            }
          }
        });
      }
      selectedRows.forEach((d, index) => {
        data.filter((item, idx) => {
          if (item.wxId === d.wxId) {
            keys.push(item.wxId);
          }
          return 0;
        });
      });
      this.setState({
        doSearch: false,
        selectedRowKeys: keys,
        selectedRows,
      });
    }
    this.isLastSearch = false;
  }

  handleChange = (e: any) => {
    if (!e.length && this.currentValue) {
      this.getNormalLabels();
    }
    this.setState({
      selectLabelRows: e, // 此处的e为数组，元素为labels.id
    });
  };

  render() {
    const {
      data,
      searchData,
      searchSelectedRowKeys,
      copyData,
      searchCopyData,
      search,
      copySearch,
      btnValue,
      doSearch,
      doCopySearch,
      labelValue,
      labels,
    } = this.state;
    this.filterDoSwitch();
    const options = labels.map((item, index) => <Option key={item.id}>{item.name}</Option>);
    this.props.onRefCopyData(this.state.copyData);
    const selectedRowKeys = doSearch ? searchSelectedRowKeys : this.state.selectedRowKeys;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onChangeHaneler,
      onSelect: this.onSelectHandler,
      onSelectAll: this.onSelectAllHandler,
      getCheckboxProps: (record: any) => this.setCheckboxStatus.bind(this, record)(),
    };
    return (
      <Row style={{ height: 610, overflowY: 'auto' }}>
        <Col span={12} style={{ paddingRight: '15px' }}>
          <div style={{ marginBottom: 6 }}>
            <span style={{ display: 'inline-block', width: 85, textAlignLast: 'justify' }}>
              标签
            </span>
            ：
            <Radio.Group
              value={labelValue}
              onChange={e => {
                this.setState({
                  labelValue: e.target.value,
                });
              }}
            >
              <Radio.Button value="0">包含</Radio.Button>
              <Radio.Button value="1">不包含</Radio.Button>
            </Radio.Group>
            <Select
              showSearch
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              allowClear
              mode="multiple"
              style={{ width: '47.8%', marginLeft: '8px', height: 'auto' }}
              placeholder="请选择标签"
              notFoundContent={
                <Empty
                  className="no-at-container im-empty-status"
                  description="搜索无结果"
                  image="/noresult.png"
                ></Empty>
              }
              onSearch={this.handleSearch}
              value={this.state.selectLabelRows}
              onChange={this.handleChange}
            >
              {options}
            </Select>
          </div>
          <div>
            <span style={{ display: 'inline-block', width: 85, textAlignLast: 'justify' }}>
              备注/微信号
            </span>
            ：
            <Radio.Group
              value={btnValue}
              onChange={e => {
                this.setState({
                  btnValue: e.target.value,
                });
              }}
            >
              <Radio.Button value="0">包含</Radio.Button>
              {/* <Radio.Button value="2">不包含</Radio.Button> */}
            </Radio.Group>
            <Input
              style={{ margin: '0 0 0 8px', width: '60%' }}
              placeholder="请输入备注/昵称/微信号查询"
              allowClear
              value={search}
              onChange={e => {
                this.setState({ search: e.target.value });
              }}
            />
          </div>
          <div style={{ textAlign: 'center' }}>
            <Button type="primary" className="modal-search-btn" onClick={this.searchData}>
              搜索
            </Button>
          </div>
          <Table
            locale={locale}
            className="im-table"
            rowSelection={rowSelection}
            columns={columns}
            dataSource={doSearch ? searchData : data}
            size="small"
          />
        </Col>
        <Divider className="qun-divider" type="vertical" />
        <Col span={12} style={{ paddingLeft: '15px' }}>
          <div>
            <span
              style={{ display: 'inline-block', width: '83px', height: '24px', lineHeight: '24px' }}
            >
              已选 <strong style={{ color: 'red' }}>{copyData.length}</strong> 好友
            </span>
            <Search
              placeholder="微信备注/微信昵称/微信号搜索"
              onSearch={this.searchCopyDatas}
              value={copySearch}
              enterButton="搜索"
              className="add-modal-search-input"
              onChange={e => {
                this.setState({
                  copySearch: e.target.value,
                });
              }}
            />
          </div>
          <div style={{ textAlign: 'right' }}>
            <Button className="all-cancel-btn im-body-font-large" onClick={this.cancelAllCopyData}>
              全部取消
            </Button>
          </div>
          <Table
            locale={locale}
            className="im-table"
            columns={this.copyColumns}
            dataSource={doCopySearch ? searchCopyData : copyData}
            size="small"
          />
        </Col>
      </Row>
    );
  }
}

export default AddQunMember;

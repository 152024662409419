import { BaseAction } from '../types/action';
import {
  ACTION_GET_SESSION_LIST,
  ACTION_SET_SESSION_LIST,
  ACTION_CREATE_SESSION,
  ACTION_UPDATE_SINGLE_SESSION,
  ACTION_TOP_SINGLE_SESSION,
  ACTION_GET_MOCK_SESSION_LIST,
  ACTION_REPLACE_SESSION,
} from '../constant';
import { WxSession } from '../types/state';

export function createGetWxSessionListAction(param: {
  limit: number;
  type?: number | string;
  typ?: number | string;
}): BaseAction {
  return {
    type: ACTION_GET_SESSION_LIST,
    param,
  };
}

export function createSetWxSessionListAction(data: WxSession[]): BaseAction {
  return {
    type: ACTION_SET_SESSION_LIST,
    data,
  };
}

export function createGetMockSessionListAction(data: any) {
  return {
    type: ACTION_GET_MOCK_SESSION_LIST,
    data,
  };
}

export function createCreateWxSessionAction(param: { chatId: string }): BaseAction {
  return {
    type: ACTION_CREATE_SESSION,
    param,
  };
}

export function createUpdateSingleSessionAction(data: { sessionId: number }): BaseAction {
  return {
    type: ACTION_UPDATE_SINGLE_SESSION,
    data,
  };
}

// 只替换新session，不置顶
export function createTopSingleSessionAction(data: WxSession[]): BaseAction {
  return {
    type: ACTION_TOP_SINGLE_SESSION,
    data,
  };
}

export function createReplaceSessionAction(data: {
  session: WxSession;
  oldId?: number;
}): BaseAction {
  return {
    type: ACTION_REPLACE_SESSION,
    data,
  };
}

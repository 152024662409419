export default {
  control: {
    // backgroundColor: '#fff',
    // fontSize: 14,
    // fontWeight: 'normal',
  },

  highlighter: {
    overflow: 'hidden',
  },

  input: {
    margin: 0,
  },

  '&singleLine': {
    control: {
      display: 'inline-block',

      width: 130,
    },

    highlighter: {
      padding: 1,
      border: '2px inset transparent',
    },

    input: {
      padding: 1,

      border: '2px inset',
    },
  },

  '&multiLine': {
    control: {
      // border: '1px solid silver',
    },

    highlighter: {
      padding: 0,
      display: 'none',
    },

    input: {
      padding: 0,
      overflow: 'auto',
      overflowX: 'hidden',
      height: '100%',
      outline: 0,
      border: 0,
      color: '#262626',
    },
  },

  suggestions: {
    overflowY: 'auto',
    maxHeight: '200px',
    width: '150px',
    top: 'initial',
    bottom: '100px',
    borderRadius: '8px 8px 8px 0px',
    border: '1px solid rgba(191,191,191,1)',
    list: {
      fontSize: 12,
      background: 'rgba(245, 245, 245, 1)',
    },

    item: {
      padding: '5px 15px',

      '&focused': {
        backgroundColor: 'rgba(56,158,13,0.2)',
        color: '#262626',
      },
    },
  },
};

/* *
 * 视频，图片，@我三种类型的列表，这三种类型按照时间从早到晚排序，只支持上拉加载
 * */

import React, { PureComponent } from 'react';
import { Spin, Empty } from 'antd';
import request from '@/http/request';
import Img from '@/components/MessageItem/Component/Img';
import Video from '@/components/MessageItem/Component/Video';
import './index.less';
import MessageItem from '@/components/MessageItem';

export default class MediaList extends PureComponent<any, any> {
  static defaultProps = {
    queryType: 0,
  };

  constructor(props: any) {
    super(props);
    this.state = {
      list: [],
      isLoading: false,
      isEnd: false,
      nextIndex: '',
    };
  }

  scroll: any = React.createRef();

  scrollList: any = null;

  componentDidMount() {
    this.loadMore();
    this.addScrollListener();
  }

  componentDidUpdate(perProps: any) {
    if (perProps.sessionId !== this.props.sessionId || perProps.mid !== this.props.mid) {
      this.reset();
    }
  }

  reset = () => {
    this.setState(
      {
        list: [],
        isLoading: false,
        isEnd: false,
        nextIndex: '',
      },
      () => {
        this.loadMore();
      },
    );
  };

  addScrollListener = () => {
    if (this.scroll) {
      this.scroll.current.addEventListener('scroll', (e: any) => {
        if (e.target.scrollTop + e.target.offsetHeight >= e.target.scrollHeight) {
          // 向下滚动，，加载最近时间的
          this.loadMore();
        }
      });
    }
  };

  loadMore() {
    if (this.state.isLoading || this.state.isEnd) return; // 没有了
    this.setState(
      (perState: any) => {
        return {
          isLoading: true,
        };
      },

      () => {
        const start = this.state.nextIndex;
        this.fetchData(start).then((res: any) => {
          if (res && res.data) {
            this.setState(
              (perState: any) => {
                return {
                  list: [...perState.list, ...res.data.list],
                  isEnd: res.data.isEnd,
                  nextIndex: res.data.nextIndex,
                };
              },
              () => {
                setTimeout(() => {
                  this.setState({
                    isLoading: false,
                  });
                }, 500);
              },
            );
          }
        });
      },
    );
  }

  goToMessage = (message: any) => {
    if (this.scrollList) this.scrollList.goToMessage(message);
  };

  fetchData = (start: string) => {
    return request
      .get(`/im/v2/sessions/${this.props.sessionId}/message/archive`, {
        start,
        upDownFlag: 0,
        queryType: this.props.queryType,
      })
      .catch(() => {
        this.setState({
          isLoading: false,
        });
      });
  };

  render() {
    const { list, isEnd, isLoading } = this.state;
    const { queryType, query } = this.props;
    return (
      <div className="record-list">
        <div className="scroll-box" ref={this.scroll}>
          {list.length <= 0 ? (
            <Empty
              className="no-session-container im-empty-status"
              style={{ color: '#8c8c8c' }}
              description="暂时没有消息"
            ></Empty>
          ) : (
            <div
              style={
                queryType === 2 || queryType === 3
                  ? {
                      display: 'flex',
                      flexWrap: 'wrap',
                      padding: '0 18px 0 13px',
                    }
                  : {}
              }
            >
              {list.map((item: any, index: number) => {
                switch (queryType) {
                  case 2:
                    return (
                      <Img
                        {...item.msgContent}
                        id={`message${item.mid}`}
                        className="img"
                        key={item.mid}
                      ></Img>
                    );
                  case 3:
                    if (item.msgContent && item.msgContent.videoUrl) {
                      return (
                        <Video
                          {...item.msgContent}
                          id={`message${item.mid}`}
                          className="img"
                          key={item.mid}
                        ></Video>
                      );
                    }
                    return '';
                  case 4:
                    return (
                      <MessageItem
                        hover={
                          <a
                            style={{ color: '#389E0D', fontSize: '12px' }}
                            onClick={() => this.props.onClick(item, query.q)}
                          >
                            查看上下文
                          </a>
                        }
                        item={item}
                        key={item.mid}
                        keyword={query.q}
                      ></MessageItem>
                    );

                  // case 0:
                  //   return (
                  //     <MessageItem
                  //       hover={<a onClick={() => this.props.onClick(item, query.q)}>查看上下文</a>}
                  //       item={item}
                  //       key={item.mid}
                  //       keyword={query.q}
                  //     ></MessageItem>
                  //   );
                  default:
                    return '';
                }
              })}
            </div>
          )}
          <Spin
            tip="Loading..."
            spinning={isLoading}
            style={{ margin: ' 10px auto', textAlign: 'center', width: '100%' }}
          ></Spin>
        </div>
      </div>
    );
  }
}

import React from 'react';
import { connect } from 'react-redux';
import { Tooltip, Menu, Dropdown, Tag, Badge } from 'antd';
import moment from 'moment';
import request from '@/http/request';
import {
  createUpdateCurrClientInfoAction,
  createSetCurrentTargetAction,
  createGetWxSessionListAction,
  createGetClientListAction,
  createTopSingleSessionAction,
} from '@/store/action';
import { Target, WxSession } from '@/store/types/state';
import './index.less';

interface Props {
  currentTarget: Target;
  clients: any;
  chatItemClick: () => void;
  newUnread?: number;
  msgType?: number;
  item: WxSession;
  setCurrentTarget: (data: any) => any;
  getClientList: () => any;
  getSessionList: () => void;
  topSession: (data: WxSession[]) => any;
  updateSingleClient: (data: any) => any;
  updateCurrSession: (data: any) => any;
}

class ChatItem extends React.Component<any, any> {
  handleClick = (item: any) => {
    const {
      chatItemClick,
      topSession,
      item: { newUnread },
    } = this.props;
    chatItemClick();
    if (newUnread > 0) {
      // 会话消息数清零 / 有人@我状态清零
      topSession([
        {
          ...item,
          newUnread: 0,
          newUnreadAt: 0,
        },
      ]);
    }
  };

  // 置顶操作
  // type；1：置顶；0：取消置顶
  placeToTopOperate = (type: number, session: any) => () => {
    request.put('/im/v2/account/sessions/top', { top: type, sessionId: session.id }).then(() => {
      this.props.getSessionList();
    });
  };

  setTag = (type: number, session: any) => {
    request.put(`/im/v2/sessions/${session.id}/set_label`, { labelType: type }).then(() => {
      this.props.getSessionList();
    });
  };

  render() {
    const {
      item,
      item: { newUnread, nickname, lastContent, draftContent, msgTime },
    } = this.props;
    const showName = nickname || '未命名';
    const showTime = (() => {
      if (!msgTime) return '';
      if (moment(Date.now()).isSame(msgTime, 'day')) {
        return moment(msgTime).format('HH:mm');
      }
      return moment(msgTime).format('MM/DD');
    })();

    const menu = () => (
      <Menu selectable={false}>
        {item.labelPriority === 0 ? (
          <Menu.Item key="1" onClick={() => this.setTag(2, item)}>
            <div>
              设置标记 <Badge color="red" />
            </div>
          </Menu.Item>
        ) : null}

        {item.labelPriority === 0 ? (
          <Menu.Item key="2" onClick={() => this.setTag(1, item)}>
            <div>
              设置标记 <Badge color="green" />
            </div>
          </Menu.Item>
        ) : null}

        {item.labelPriority === 1 || item.labelPriority === 2 ? (
          <Menu.Item key="3" onClick={() => this.setTag(0, item)}>
            <div>取消标记</div>
          </Menu.Item>
        ) : null}

        {item.imTopStatus && item.imTopStatus === 1 ? (
          <Menu.Item key="4" onClick={this.placeToTopOperate(0, item)}>
            <div>取消置顶</div>
          </Menu.Item>
        ) : (
          <Menu.Item key="4" onClick={this.placeToTopOperate(1, item)}>
            <div>置顶会话</div>
          </Menu.Item>
        )}
      </Menu>
    );

    return (
      <Tooltip placement="right" title={showName}>
        <Dropdown overlay={menu()} disabled={item.chatType === 3} trigger={['contextMenu']}>
          <div className="chat-item" onClick={() => this.handleClick(item)}>
            <div className="chat-item-text-box">
              <div className="flex flex-space-between" style={{ lineHeight: '19px' }}>
                <span className="chat-item-name">{showName}</span>
                <div className="flex">
                  <span>
                    {+item.playMark === 1 ? (
                      <span style={{ color: 'red', fontSize: 12, paddingRight: 3 }}>[暂停]</span>
                    ) : null}
                  </span>
                  <span>
                    {item.labelPriority && item.labelPriority !== 0 ? (
                      <Tag
                        color={item.labelPriority === 2 ? '#f50' : '#87d068'}
                        style={{ height: '12px', lineHeight: '12px' }}
                      >
                        {' '}
                      </Tag>
                    ) : null}
                  </span>
                  <span className="chat-item-time">{showTime}</span>
                </div>
              </div>
              <div className="flex flex-space-between" style={{ marginTop: '5px' }}>
                {draftContent ? (
                  <span className="chat-item-msg">
                    <span className="draft-content">[草稿]</span>
                    {draftContent}
                  </span>
                ) : (
                  <span className="chat-item-msg">{lastContent}</span>
                )}
                {newUnread > 0 ? (
                  <span className="ant-badge-count session-list-badge">{newUnread}</span>
                ) : null}
              </div>
            </div>
          </div>
        </Dropdown>
      </Tooltip>
    );
  }
}

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch: any) {
  return {
    getClientList: () => dispatch(createGetClientListAction()),
    setCurrentTarget: (data: any) => dispatch(createSetCurrentTargetAction(data)),
    getSessionList: () => dispatch(createGetWxSessionListAction({ limit: 5000 })),
    updateSingleClient: (data: any) => dispatch(createUpdateCurrClientInfoAction(data)),
    topSession: (data: WxSession[]) => dispatch(createTopSingleSessionAction(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatItem);

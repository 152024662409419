import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Layout } from 'antd';
import { createGetClientListAction, setCurrentUserAction } from '@/store/action';
import DragSider from '@/components/DragSider';
import request from '@/http/request';
import SessionPanel from './container/SessionPanel';
import ChatPanel from './container/ChatPanel';
import OperationPanel from './container/OperationPanel';

import './index.less';

class WechatIM extends PureComponent<any, any> {
  componentDidMount() {
    this.props.getClientList('init');
    this.getCurrUser();
  }

  getCurrUser = () => {
    request.get('/v2/current_user').then((res: any) => {
      (window as any).user = res.data.username;
      this.props.setCurrentUser({ id: res.data.id });
      window.sessionStorage.setItem('currentUser', res.data.id);
    });
  };

  render() {
    return (
      <Layout className="wechat-im">
        <DragSider resizeOrientation="right" minWidth={200} maxWidth={408}>
          <SessionPanel style={{ width: '100%' }} />
        </DragSider>
        <ChatPanel style={{ flex: 1 }} />
        <DragSider resizeOrientation="left" minWidth={320} maxWidth={500}>
          <OperationPanel style={{ width: '100%' }} />
        </DragSider>
      </Layout>
    );
  }
}

function mapDispatchToProps(dispatch: any) {
  return {
    getClientList: (type?: string) => dispatch(createGetClientListAction(type)),
    setCurrentUser: (data: any) => dispatch(setCurrentUserAction(data)),
  };
}

export default connect(
  null,
  mapDispatchToProps,
)(WechatIM);

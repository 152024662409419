import Im from '@/page/WechatIM';
import Lab from '@/page/Lab';

export default [
  // {
  //     path: "/",
  //     exact: true,
  //     render: () => "欢迎来到首页"
  // },
  // {
  //     path: "/accountList",
  //     component: AccountList,
  //     menu: {
  //         Icon: "user",
  //         text: "账号管理",
  //         key: "/accountList"
  //     }
  // },
  {
    path: '/lab',
    component: Lab,
    menu: {
      Icon: 'experiment',
      text: 'Lab',
      key: '/lab',
    },
  },
  {
    path: '/',
    component: Im,
    menu: {
      Icon: 'message',
      text: '聊天回复',
      key: '/im',
    },
  },
  // {
  //     path: "/moments",
  //     component: Moments,
  //     menu: {
  //         Icon: "message",
  //         text: "发朋友圈",
  //         key: "/moments"
  //     }
  // }
];

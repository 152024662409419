import React, { useCallback, useState, useEffect } from 'react';
import { List, Layout, Button, Modal } from 'antd';
import moment from 'moment';
import eventBus from '@/ws/event';
import request from '@/http/request';

const { confirm, destroyAll } = Modal;

const itemStyle = {
  borderRadius: '4px',
  padding: '12px 8px',
  margin: '0 12px 8px 0',
  background: '#fff',
};

interface ReissueMessageProps {
  currGroupId: string;
  currSessionId: string | number;
  activeKey: string;
  getSessionList: () => {};
}

let page = 0;

const ReissueMessage: React.FC<ReissueMessageProps> = props => {
  const [reissueList, setReissueList] = useState<any[]>([]);
  const [isEnd, setIsEnd] = useState(true);
  const getReissueList = useCallback(
    p => {
      request
        .get('/im/v2/message/schedule/undelivered', {
          page: p,
          perpage: 4,
          roomId: props.currGroupId,
        })
        .then((res: any) => {
          setReissueList(p === 0 ? res.data.list : [...reissueList, ...res.data.list]);
          setIsEnd(res.data.total < (p + 1) * 6);
        });
    },
    [props.currGroupId, reissueList],
  );

  useEffect(() => {
    page = 0;
    getReissueList(page);
  }, [props.currGroupId]);

  const onConfirm = useCallback(
    (index: number, flag: boolean) => {
      let params: any = {};
      let url = '/im/v2/message/schedule/undelivered/ignore';
      if (flag) {
        // 补发
        params = {
          sessionId: props.currSessionId,
          id: reissueList[index].id,
        };
        url = '/im/v2/message/schedule/undelivered/send';
      } else {
        // 忽略
        params = {
          roomId: props.currGroupId,
          id: index === -1 ? 0 : reissueList[index].id,
        };
      }
      request
        .post(url, params)
        .then((res: any) => {
          let list1 = [];
          if (index !== -1) {
            list1 = [...reissueList];
            list1.splice(index, 1);
          }
          setReissueList(list1);
          if (flag) {
            eventBus.emit('getSessionMessageList', { replace: false, isNew: true });
          }
          if ((isEnd || index === -1) && !list1.length) {
            setIsEnd(true)
            props.getSessionList();
          }
        })
        .catch((err: any) => {
          console.log(err);
        });
    },
    [reissueList, setReissueList, setIsEnd, props],
  );

  const openConfirmModal = (index: number, flag: boolean) => {
    confirm({
      className: 'confirm-modal',
      icon: null,
      content: flag ? '确认补发？' : '确认忽略？',
      okText: flag ? '发送' : '忽略',
      cancelText: '取消',
      onOk() {
        destroyAll();
        onConfirm(index, flag);
      },
      onCancel() {
        console.log('close');
      },
    });
  };
  return (
    <Layout className="meterial-library">
      <Button
        style={{
          position: 'absolute',
          top: '32px',
          left: '228px',
        }}
        size="small"
        onClick={() => {
          openConfirmModal(-1, false);
        }}
      >
        忽略全部
      </Button>
      <div className="scroll-box" id="scroll-box-4">
        {reissueList.length ? (
          <List
            size="small"
            dataSource={reissueList}
            renderItem={(item: any, index) => (
              <List.Item
                key={item.msg}
                style={itemStyle}
                extra={
                  <div className="message-block">
                    {item.msg ? (
                      <>
                        <div style={{ textAlign: 'right', paddingRight: '10px' }}>
                          {moment(`${item.createAt}`).format('MM-DD HH:mm:ss')}
                        </div>
                        {item.msg.msgType === 3 ? (
                          <img
                            style={{ maxWidth: '120px', maxHeight: '120px' }}
                            src={item.msg.content}
                            alt="发送失败图片"
                          />
                        ) : (
                          <div>{item.msg.content}</div>
                        )}
                        <p>______________________________________________</p>
                        <p>
                          {(() => {
                            const failReasons = item.failReason.split('\n');
                            return failReasons.length
                              ? failReasons.map((r: string) => <div>{r}</div>)
                              : '';
                          })()}
                        </p>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <Button
                            size="small"
                            onClick={() => {
                              openConfirmModal(index, false);
                            }}
                          >
                            忽略
                          </Button>
                          <Button
                            size="small"
                            type="primary"
                            ghost
                            onClick={() => {
                              openConfirmModal(index, true);
                            }}
                          >
                            补发
                          </Button>
                        </div>
                      </>
                    ) : (
                      ''
                    )}
                  </div>
                }
              ></List.Item>
            )}
          />
        ) : (
          ''
        )}
        {!isEnd ? (
          <Button
            style={{ margin: '0 auto', display: 'block' }}
            onClick={() => getReissueList((page += 1))}
          >
            加载更多
          </Button>
        ) : (
          ''
        )}
      </div>
    </Layout>
  );
};

export default ReissueMessage;

import React from 'react';
import { Layout } from 'antd';
import { connect } from 'react-redux';
import ChatPanelHeader from './components/ChatPanelHeader';
import ChatWindow from './components/ChatWindow';
import '@/page/WechatIM/container/ChatPanel/index.less';
import { createMergeWxMessageListAction, createTopSingleSessionAction } from '@/store/action';
import { fetchMessageList } from '@/http';
import request from '@/http/request';

// let throttleSessionList = false;

function getLastSuccessMessageId(list: any[]) {
  const len = list.length;
  if (!len) return '';
  // 从后向前遍历，获取非mock的mid（mock数据的mid < 0）
  for (let i = len - 1; i >= 0; i -= 1) {
    const { mid } = list[i];
    if (mid && typeof mid === 'string') {
      return mid;
    }
  }
  return '';
}

interface Prop {
  sessionId?: number;
  mergeMessage: (
    data: any,
    sessionId: any,
    isNew: boolean,
    isUpdate: boolean,
    isReplace: boolean,
  ) => any;
  topSession: (data: any) => any;
}

class ChatPanel extends React.Component<Prop, any> {
  constructor(props: Prop) {
    super(props);
    this.state = {
      metaInfo: {},
      innerMessageList: [],
    };
  }

  componentDidMount() {
    this.getSessionMessageList(true, false);
    this.getMetaInfo();

    // 定时刷新消息列表
    this.timer = setInterval(() => {
      this.getSessionMessageList(true, false);
    }, 5000);
  }

  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  timer: any = null;

  throttleSessionListData: any = [];

  isEnd: boolean = false;

  nextIndex: any = '';

  getMetaInfo = () => {
    // 需要调用接口 获取群信息/子会话信息。替换以前meta接口
    request.get(`/im/v2/sessions/${this.props.sessionId}/meta`).then((res: any) => {
      const { data } = res;
      this.setState({
        metaInfo: data,
      });
    });
  };

  getMessageList = async (param: any, replace: boolean) => {
    const oldList = this.state.innerMessageList;
    try {
      let start = '';
      const { sessionId, isNew, limit } = param;
      // 如果redux中存有数据，则直接从中读取messageList的数组数据
      // 若读取到store中的messageList数据，判断isNew后：
      // 为true：取store中messageList的最新一条消息的mid作为start值，作为拉取从当前start值到最新推送消息的请求参数
      // 为false：取store中messageList的第一条消息的mid作为start值，作为拉取从store的message的mid值到最新推送消息的请求参数
      if (Array.isArray(oldList) && oldList.length > 0) {
        if (isNew) {
          start = getLastSuccessMessageId(oldList);
        }
        if (!isNew && !replace) {
          start = oldList[oldList.length - 1].mid;
        }
      }
      // 获取历史数据
      const getHistoryDataCodition = this.nextIndex && !isNew && !replace;
      if (getHistoryDataCodition && oldList && oldList.length > 0) {
        start = this.nextIndex;
      }
      const data = await fetchMessageList({ sessionId, limit, start, upDownFlag: isNew ? 1 : 0 });
      // 获取历史数据时才需要刷新isEnd以及nextIndex的值
      // 否则每次向下拉取新消息时，isEnd总会返回true，这样再向上拉取时由于isEnd和nextIndex是新消息的刷新数据，就会导致出错
      if (!isNew) {
        this.nextIndex = data.nextIndex;
        this.isEnd = data.isEnd;
      }

      if (Array.isArray(data.list) && data.list.length > 0) {
        if (replace) {
          this.nextIndex = data.nextIndex;
          this.isEnd = data.isEnd;
          this.setState({
            innerMessageList: data.list,
          });
        } else {
          // 获取redux中对应sessionId的messageList
          let resData = oldList;
          // 更新新消息到聊天窗口
          // 分为：假数据上屏、真数据替换（拼接）、新messageList拼接
          if (isNew) {
            // 为避免出现重复的数据，若在己方发送消息时，对方在同一时间发送了消息，则需要去重处理
            // 该情况下，data长度为大于 1 的数组
            if (data.length > 1) {
              resData = resData.reduce((prevList: any, originItem: any) => {
                if (data.find((newItem: any) => newItem.mid === originItem.mid)) {
                  return prevList;
                }
                prevList.push(originItem);
                return prevList;
              }, []);
            }

            let resArr: any[] = [];
            if (Array.isArray(data)) {
              resArr = data;
            } else {
              resArr.push(...data.list);
            }
            this.setState({
              innerMessageList: resData.concat(resArr),
            });
            return;
          }

          // 处理旧数据,此时data为具体消息对象
          // 将接受到的数据替换到历史数据中
          if (replace) {
            let tempData: any = resData;
            tempData = tempData.map((item: any) => {
              return item.mid === data.mid
                ? {
                    ...item,
                    ...data,
                  }
                : item;
            });
          }

          this.setState({
            innerMessageList: data.list.concat(resData),
          });
          return;
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  getSessionMessageList = (replace: boolean = true, isNew: boolean = true, limit: number = 30) => {
    const { sessionId } = this.props;
    const { innerMessageList } = this.state;
    const param = {
      chatType: 2,
      limit: innerMessageList && innerMessageList.length >= limit ? innerMessageList.length : limit,
      isNew,
      sessionId,
    };
    this.getMessageList(param, replace);
  };

  handlerScrollToTop = () => {
    if (this.isEnd) return;
    this.getSessionMessageList(false, false);
  };

  // 处理当前会话信息
  // PREF: 性能优化
  // handlerSession = (receiveData: any) => {
  //   const { topSession } = this.props;
  //   const tempData: any = { ...receiveData };
  //   // 消息位置重排
  //   // 这里会导致页面卡顿, 添加时间锁及队列来批量更新会话
  //   this.throttleSessionListData = {
  //     ...this.throttleSessionListData,
  //     [tempData.id]: tempData,
  //   };
  //   if (!throttleSessionList) {
  //     throttleSessionList = true;
  //     setTimeout(() => {
  //       throttleSessionList = false;
  //       topSession(
  //         Object.keys(this.throttleSessionListData).map(key => this.throttleSessionListData[key]),
  //       );
  //       this.throttleSessionListData = {};
  //     }, 1200);
  //   }
  // };

  // updateMessageList = (data: any) => {
  //   this.props.mergeMessage(data, this.props.sessionId, false, false, true);
  // };

  render() {
    const { metaInfo } = this.state;
    return (
      <Layout.Content className="main-layout">
        {metaInfo && Object.keys(metaInfo).length ? <ChatPanelHeader {...metaInfo} /> : ''}
        <ChatWindow
          messageList={this.state.innerMessageList}
          onScrollToTop={this.handlerScrollToTop}
          wxId={metaInfo.owner ? metaInfo.owner.wxId : ''}
        />
      </Layout.Content>
    );
  }
}

function mapDispatchToProps(dispatch: any) {
  return {
    mergeMessage: (
      data: any,
      sessionId: any,
      isNew: boolean,
      isUpdate: boolean,
      isReplace: boolean,
    ) => dispatch(createMergeWxMessageListAction(data, sessionId, isNew, isUpdate, isReplace)),
    topSession: (data: any) => dispatch(createTopSingleSessionAction(data)),
  };
}

export default connect(
  null,
  mapDispatchToProps,
)(ChatPanel);

import qs from 'qs';
import axios, { AxiosResponse } from 'axios';
import { message } from 'antd';
import store from '@/store';

message.config({
  duration: 1,
  maxCount: 1,
});

const codeMessage: any = {
  200: '服务器成功返回请求的数据。',
  201: '新建或修改数据成功。',
  202: '一个请求已经进入后台排队（异步任务）。',
  204: '删除数据成功。',
  400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  401: '用户没有权限（令牌、用户名、密码错误）。',
  403: '用户得到授权，但是访问是被禁止的。',
  404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
  406: '请求的格式不可得。',
  410: '请求的资源被永久删除，且不会再得到的。',
  422: '当创建一个对象时，发生一个验证错误。',
  500: '服务器发生错误，请检查服务器。',
  502: '网关错误。',
  503: '服务不可用，服务器暂时过载或维护。',
  504: '网关超时。',
};

const successHandler = (notNotify?: boolean) => (res: AxiosResponse<any>) => {
  const { url } = res.config;
  const urlRule = /(\/api\/im\/v2\/sessions)|(\/api\/v2\/label)/gi;
  const exceptCondition2 = url && !urlRule.test(url);
  if (exceptCondition2) {
    if (!notNotify) {
      message.success(res.data.msg);
    }
  }
  return res.data;
};

const instance = axios.create({
  baseURL: '/api',
  timeout: 50000,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
  },
});
function handleError(error: any) {
  const { response } = error;
  if (response && response.status) {
    const { status } = response;
    if (status === 200) {
      const { code, data, msg } = response.data;
      message.error(`${code || 'failed'}：${(typeof data === 'string' && data) || msg}`);
    } else {
      const errorText = codeMessage[status] || response.statusText;
      message.error(`${status}：${errorText}`);
    }
  }
  if (error.code === 401) {
    const host = window.location.host.split('.');
    host[0] = 'op';
    window.location.href = `//${host.join('.')}/user/login`;
  }
  return Promise.reject(error);
}
instance.interceptors.request.use(
  configs => {
    if (store && configs.params) {
      const baseReq: any = {};
      if (store) {
        const { clients } = store.getState() as any;

        if (clients.current) {
          baseReq.wxAlias = clients.current.wxAlias;
          baseReq.wxId = clients.current.wxId;
        }
        configs.params = Object.assign(configs.params, baseReq); //eslint-disable-line
      }
    }
    return configs;
  },
  err => Promise.reject(err.msg),
);

instance.interceptors.response.use((response: any) => {
  if (response.data.code === 200) {
    return response;
  }
  if (response.data.code === 400 && response.data.data === '不能重复相同标签') {
    response.data.msg = response.data.data;
  }
  return Promise.reject({ response }); // eslint-disable-line
});
const request = {
  get(url: string, data = {}): any {
    return instance
      .get(url, {
        params: data,
      })
      .then(res => res.data)
      .catch(handleError);
  },

  post(url: string, data = {}, type = 'formData', notNotify?: boolean): any {
    let dataObj = data;
    if (type === 'formData') {
      dataObj =
        data instanceof FormData
          ? data
          : qs.stringify(data, { arrayFormat: 'repeat', indices: false });
    }
    return instance
      .post(url, dataObj)
      .then(successHandler(notNotify))
      .catch(handleError);
  },

  put(url: string, data = {}, notNotify?: boolean, type = 'formData'): any {
    let dataObj = data;
    if (type === 'formData') {
      dataObj =
        data instanceof FormData
          ? data
          : qs.stringify(data, { arrayFormat: 'repeat', indices: false });
    }
    return instance
      .put(url, dataObj)
      .then(successHandler(notNotify))
      .catch(handleError);
  },

  delete(url: string, data = {}, notNotify?: boolean): any {
    return instance
      .delete(url, {
        params: data,
      })
      .then(successHandler(notNotify))
      .catch(handleError);
  },
};

export default request;

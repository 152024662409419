import React from 'react';
import { Input, List, Divider } from 'antd';
import debounce from 'lodash/debounce';
import { connect } from 'react-redux';
import { State, Target, WxClient } from '@/store/types/state';
import './index.less';
import request from '@/http/request';
import styles from '../UserItem/UserItem.module.less';

interface Prop {
  currentClient?: WxClient;
  target: Target;
  current?: any;
  // setCurrentTarget: (data: Target) => any;
  onClick: (e: any) => any;
  openCreateQunMember: () => any;
}

class Search extends React.Component<Prop> {
  state = {
    loading: false,
    visible: false,
    searchGroup: {
      list: [],
      total: 0,
    },
    searchGroupShowMore: false,
    query: '',
  };

  start: number = 0;

  hasMore: boolean = true;

  scroller: React.RefObject<HTMLObjectElement> = React.createRef();

  // 查看全部/折叠；type：1-好友；type:2-群;
  foldSwitch = (type: number) => {
    const CHECKMOREPARAMS = {
      query: this.state.query,
      start: 0,
    };
    this.setState(
      (prevState: any) => {
        return {
          searchGroupShowMore: !prevState.searchGroupShowMore,
        };
      },
      () => {
        this.getGroupsSearch({
          ...CHECKMOREPARAMS,
          count: this.state.searchGroupShowMore ? 9999 : 6,
        });
      },
    );
  };

  foldFn = (type: number) => {};

  handleChange = (e: any) => {
    e.persist();
    this.setState(
      {
        query: e.target.value,
      },
      () => {
        this.search();
      },
    );
  };

  resetSearch = () => {
    this.start = 0;
    this.hasMore = true;
  };

  handlerFocus = () => {
    setTimeout(() => {
      if (!this.state.visible) {
        this.setState({
          visible: true,
        });
      }
    }, 200);
  };

  handlerBlur = () => {
    setTimeout(() => {
      if (this.state.visible) {
        this.setState({
          visible: false,
          query: '',
          searchGroup: {
            list: [],
            total: 0,
          },
        });
      }
    }, 200);
  };

  handlerItemClick = (groupId: any) => () => {
    request
      .get('/im/v2/session', {
        groupId,
      })
      .then((res: any) => {
        this.props.onClick(res.data);
      });
  };

  handlerScroll = () => {
    const scroller: any = this.scroller.current;
    if (
      scroller.scrollTop !== 0 &&
      scroller.scrollTop >= scroller.scrollHeight - scroller.offsetHeight - 50
    ) {
      this.getMore();
    }
  };

  search = debounce(() => {
    this.$_search();
  }, 500);

  getMore = () => {
    const { loading } = this.state;
    if (loading || !this.hasMore) return;
    this.$_search();
  };

  getGroupsSearch = (params: any) => {
    if (this.props.current !== null) {
      return request
        .get('/im/v2/group/search', {
          ...params,
        })
        .then((res: any) => {
          this.setState({
            searchGroup: res.data || {},
          });
        });
    }
    return null;
  };

  $_search = () => {
    if (this.state.query === '') return;
    this.setState({
      loading: true,
    });
    const params = {
      query: this.state.query,
      start: this.start,
      count: 6,
    };
    Promise.all([this.getGroupsSearch(params)]).then(() => {
      this.setState({
        loading: false,
      });
    });
  };

  render() {
    const {
      loading,
      visible,
      query,
      searchGroup: { list: searchGroupList, total: searchGroupCount },
    } = this.state;
    return (
      <div className={`search-container ${visible ? 'visible-container' : ''}`}>
        <Input
          className="sider-search"
          onBlur={this.handlerBlur}
          onChange={this.handleChange}
          onFocus={this.handlerFocus}
          placeholder="输入关键字搜索"
          spellCheck={false}
          value={query}
          allowClear
        />
        {
          <div
            className={`scroll-container ${visible ? 'visible' : 'hidden'}`}
            onScroll={this.handlerScroll}
            ref={this.scroller}
          >
            <List
              className="scroll-container-list"
              dataSource={searchGroupList || []}
              renderItem={(item: any) => {
                return (
                  <div style={{ position: 'relative' }}>
                    <div
                      className={styles['user-item']}
                      onClick={this.handlerItemClick(item.groupId)}
                    >
                      <img
                        className={styles['user-item-avatar']}
                        src={item.groupHeadImg || '/defaultHead.jpg'}
                        alt="head"
                      />
                      <div className={styles['user-item-info']}>
                        <div className={styles['user-item-name']}>
                          {item.groupName || '未命名'}
                          <br />
                        </div>
                        <div className={styles['user-item-wxid']}>分组：{item.divisionName}</div>
                      </div>
                    </div>
                    <Divider
                      style={{
                        margin: '0px 16px',
                        width: '90%',
                        minWidth: 'unset',
                        position: 'absolute',
                      }}
                    />
                  </div>
                );
              }}
              split={false}
            />
            {(searchGroupList && !searchGroupList.length) || !searchGroupList ? (
              <div className="scroll-container-empty-prompt" style={{ color: '#262626' }}>
                <img className="group-img" alt="groups" src="./group.png" />
                {query ? '无匹配的群' : '关键字搜索群'}
              </div>
            ) : (
              ''
            )}
            {(() => {
              if (searchGroupCount > 6) {
                if (searchGroupList && searchGroupList.length <= 6) {
                  return (
                    <div
                      className="scroll-container-loadmore"
                      onMouseDown={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        this.foldSwitch(2);
                      }}
                    >
                      查看全部（{searchGroupCount}）
                    </div>
                  );
                }
                return (
                  <div
                    className="scroll-container-loadmore"
                    onMouseDown={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      this.foldSwitch(2);
                    }}
                  >
                    收起
                  </div>
                );
              }
              return '';
            })()}
          </div>
        }
      </div>
    );
  }
}

function mapStateToProps(state: State) {
  return {
    target: state.currentTarget,
    current: state.clients.current,
  };
}

export default connect(mapStateToProps)(Search);

import React, { Component } from 'react';
import { Layout, Tabs, Button, Modal, Icon, Row, Col } from 'antd';
import './index.less';
import { connect } from 'react-redux';
import { State, Target, Info } from '@/store/types/state';
import {
  createSetCurrentTargetAction,
  createGetWxFriendListAction,
  createGetWxSessionListAction,
  setCurrentWindowTabAction,
  createGetMediaLibraryListAction,
  createSyncSendMessage,
  createSendTextMessageAction,
  createSendImageMessageAction,
} from '@/store/action';
import MessageRecords from '@/components/MessageRecords';
import eventBus from '@/ws/event';
import IconFont from '@/components/IconFont';
import GroupPanel from './components/GroupPanel';
import QuickReplaySetting from './components/QuickReplaySetting';
import QuickReplayInfo from './components/QuickReplayInfo';
import MaterialLibrary from './components/MaterialLibrary';
import RecommendInfo from './components/RecommendInfo';
import ReissueMessage from './components/ReissueMessage';
import PlayInfo from './components/PlayPanel/PlayInfo';
import SendData from './components/SendData';

const { TabPane } = Tabs;

const headTitle = (text: string) => (
  <div className="functionWindow-tabs-header">
    <span className="im-title-font-large">{text}</span>
  </div>
);

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  mediaList: any[];
  target: Target;
  info: Info;
  clients: any;
  currentSiderTab: string;
  currentWindowTab: string;
  setCurrentTarget: (data: Target) => any;
  getFriendList: () => any;
  getSessionList: () => any;
  setCurrentWindowTab: (data: string) => void;
  getMediaList: (e: {}) => void;
  syncMessage: (e: {}) => void;
  sendTextMsg: (param: any) => any;
  sendImageMsg: (param: any) => any;
}
interface OwnState {
  tabPosition: any;
  detailData: any;
  originData: any;
  detailSignal: boolean;
  chatType: number;
  role: number;
  chatId: string;
  showQuickReplyModal: boolean;
  isDeleteFriend: boolean;
  tabIndex: string;
  windowWidth: number;
  messageRecordLocationMid: string;
  momentsList: any[];
  refresh: number;
  visibleMessage: string;
  momentsIsEnd: boolean;
}
class OperationPanel extends Component<Props, OwnState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      tabPosition: 'right',
      detailData: null,
      originData: null,
      detailSignal: true,
      chatId: (this.props.target && this.props.target.chatId) || '',
      chatType: (this.props.target && this.props.target.chatType) || 0,
      role: 0,
      showQuickReplyModal: false,
      isDeleteFriend: false,
      tabIndex: '0',
      windowWidth: 410,
      messageRecordLocationMid: '',
      momentsList: [],
      refresh: 0,
      visibleMessage: '',
      momentsIsEnd: false,
    };
  }

  qunChild: any;
  // labelChild: any;

  quickReplyChild: any;

  lastId: number = 0;

  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    const status =
      nextProps.target &&
      nextProps.target.chatId &&
      nextProps.target.chatId !== undefined &&
      prevState.chatId !== nextProps.target.chatId;

    let windowWidth = 410;
    if (
      nextProps.currentWindowTab === '5' ||
      nextProps.currentWindowTab === '7' ||
      nextProps.currentWindowTab === '0' ||
      nextProps.currentWindowTab === '9'
    ) {
      windowWidth = 510;
    }
    if (status) {
      return {
        ...prevState,
        chatType: nextProps.target.chatType,
        chatId: nextProps.target.chatId,
        windowWidth,
      };
    }
    if (prevState.windowWidth !== windowWidth) {
      return {
        ...prevState,
        windowWidth,
      };
    }
    return prevState;
  }

  componentDidMount() {
    eventBus.on('messageRecordLocation', async (mid: string) => {
      await this.props.setCurrentWindowTab('5');
      this.setState({
        windowWidth: 510,
        messageRecordLocationMid: mid,
      });
    });
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.currentWindowTab[0] !== prevProps.currentWindowTab[0]) {
      if (this.props.currentWindowTab[0] === '4') {
        this.props.getMediaList({ mediaType: 1 });
      }
    }
  }

  tabChange = (key: any) => {
    this.setState({
      tabIndex: key,
      windowWidth: ['0', '9', '5', '7'].indexOf(key) > -1 ? 510 : 410,
    });
    if (key === '4') {
      this.setWindowTab('1');
    } else {
      this.props.setCurrentWindowTab(key);
    }
  };

  setWindowTab = (e: string) => {
    this.props.setCurrentWindowTab('4'.concat(e));
    let mediaType = 0;
    if (e === '1') {
      mediaType = 1;
    } else if (e === '2') {
      mediaType = 3;
    } else {
      mediaType = 49;
    }
    this.props.getMediaList({ mediaType });
  };

  openLab = () => {
    window.open(`${window.location.origin}/lab`);
  };

  render() {
    const {
      tabPosition,
      showQuickReplyModal,
      chatId,
      chatType,
      windowWidth,
      messageRecordLocationMid,
    } = this.state;
    const { currentWindowTab, mediaList, target, clients, info } = this.props;
    return (
      <Layout.Sider
        className="functionWindow"
        width={windowWidth}
        breakpoint="xl"
        collapsedWidth={windowWidth}
      >
        <div className="side-right-bar-tabs">
          <Tabs
            className="functionWindow-tabs"
            tabPosition={tabPosition}
            onChange={this.tabChange}
            activeKey={currentWindowTab[0]}
            tabBarStyle={{ height: '100vh' }}
          >
            <TabPane
              key="1"
              className="functionWindow-tabs-item"
              tab={
                <span className="tabs-item">
                  <IconFont style={{ fontSize: '25px' }} iconfont="iconyou-kehuziliao"></IconFont>
                  <p>客户资料</p>
                </span>
              }
            >
              {chatId !== '' &&
              this.props.target !== null &&
              this.props.target.type === 'TARGET_TYPE_SESSION' ? (
                <>
                  {chatType === 2 ? (
                    <GroupPanel
                      currSessionId={(this.props.target && this.props.target.id) || 0}
                      currGroupId={chatId}
                      onRef={(ref: any) => {
                        this.qunChild = ref;
                      }}
                    />
                  ) : (
                    <>
                      <div className="functionWindow-tabs-header">
                        <span
                          className="im-title-font-large"
                          style={{ height: '32px', lineHeight: '32px' }}
                        >
                          群资料
                          <IconFont
                            style={{ fontSize: '15px', marginLeft: '10px' }}
                            iconfont="iconshuaxin"
                          ></IconFont>
                        </span>
                      </div>
                      <Row style={{ margin: '16px' }}>
                        <Col span={6}>群聊名称</Col>
                        <Col span={18}>
                          <span className="im-title-font-small">
                            {(info && info.wxGroup && info.wxGroup.groupName) || '未命名'}
                          </span>
                        </Col>
                      </Row>
                      <Row style={{ margin: '16px' }}>
                        <Col span={6}>所属分组</Col>
                        <Col span={18}>
                          <span className="im-title-font-small">
                            {(info && info.wxGroup && info.wxGroup.division.name) || '无'}
                          </span>
                        </Col>
                      </Row>
                    </>
                  )}
                </>
              ) : null}
            </TabPane>
            <TabPane
              key="9"
              className="functionWindow-tabs-item"
              tab={
                <span className="tabs-item">
                  <Icon type="book" style={{ fontSize: '24px' }} />
                  <p>剧本播放</p>
                </span>
              }
            >
              <PlayInfo currGroupId={chatId} currentWindowTab={currentWindowTab} />
            </TabPane>
            <TabPane
              key="0"
              className="functionWindow-tabs-item"
              tab={
                <span className="tabs-item">
                  <Icon type="database" style={{ fontSize: '24px' }} />
                  <p>发送数据</p>
                </span>
              }
            >
              <SendData currGroupId={chatId} currentWindowTab={currentWindowTab} />
            </TabPane>
            <TabPane
              key="2"
              tab={
                <span className="tabs-item">
                  <IconFont style={{ fontSize: '25px' }} iconfont="iconyou-kuaijiehuifu"></IconFont>
                  <p>快捷回复</p>
                </span>
              }
            >
              <div className="functionWindow-tabs-header">
                <span className="im-title-font-large">快捷回复</span>
                <Button
                  className="im-btn-default im-btn-icon"
                  onClick={() => {
                    this.setState({ showQuickReplyModal: true });
                  }}
                  icon="plus"
                >
                  添加快捷回复
                </Button>
              </div>
              <Modal
                visible={showQuickReplyModal}
                width={1040}
                style={{ top: 40 }}
                title="快捷回复模版"
                onCancel={() => {
                  this.setState({ showQuickReplyModal: false });
                  this.quickReplyChild.init();
                }}
                footer={null}
              >
                <QuickReplaySetting />
              </Modal>
              <QuickReplayInfo
                {...this.props.target}
                onRef={(ref: any) => {
                  this.quickReplyChild = ref;
                }}
              />
            </TabPane>
            <TabPane
              key="3"
              tab={
                <span className="tabs-item">
                  <IconFont style={{ fontSize: '25px' }} iconfont="icontuijianshangpin"></IconFont>
                  <p>推荐商品</p>
                </span>
              }
            >
              <RecommendInfo
                target={target}
                info={info}
                syncMessage={this.props.syncMessage}
                sendText={this.props.sendTextMsg}
                sendImg={this.props.sendImageMsg}
              />
            </TabPane>
            <TabPane
              key="4"
              tab={
                <span className="tabs-item">
                  <IconFont style={{ fontSize: '25px' }} iconfont="iconsucaiku"></IconFont>
                  <p>素材库</p>
                </span>
              }
            >
              {(() => headTitle('素材库'))()}
              <MaterialLibrary
                mediaList={mediaList}
                getMediaList={this.setWindowTab}
                target={target}
                activeKey={currentWindowTab[1]}
                syncMessage={this.props.syncMessage}
              />
            </TabPane>
            <TabPane
              key="5"
              tab={
                <span className="tabs-item">
                  <IconFont style={{ fontSize: '25px' }} iconfont="iconliaotianjilu"></IconFont>
                  <p>聊天记录</p>
                </span>
              }
            >
              {this.props.target && this.props.target.id && this.props.info ? (
                <>
                  {(() => headTitle('聊天记录'))()}
                  <MessageRecords
                    currClientWxId={clients.current && clients.current.wxId}
                    friendId={chatId}
                    sessionId={
                      this.props.target.chatType === 3
                        ? this.props.info.topic.parentSessionId
                        : this.props.target.id
                    }
                    messageRecordLocationMid={messageRecordLocationMid}
                  />
                </>
              ) : (
                ''
              )}
            </TabPane>
            <TabPane
              key="6"
              tab={
                <span className="tabs-item">
                  <IconFont style={{ fontSize: '25px' }} iconfont="iconyou-xitongshezhi"></IconFont>
                  <p>系统设置</p>
                </span>
              }
            >
              {(() => headTitle('系统设置'))()}
              <div className="functionWindow-tabs-card">
                <div className="functionWindow-tabs-card-actions">
                  <Button
                    type="primary"
                    onClick={() => {
                      const host = window.location.host.split('.');
                      host[0] = 'op';
                      window.location.replace(`//${host.join('.')}`);
                    }}
                  >
                    进入后台
                  </Button>
                </div>
              </div>
            </TabPane>
            <TabPane
              key="7"
              tab={
                <span className="tabs-item">
                  <Icon type="eye" style={{ fontSize: '24px' }} />
                  <p>补发消息</p>
                </span>
              }
            >
              {(() => headTitle('补发消息'))()}
              {chatType === 2 ? (
                <ReissueMessage
                  currGroupId={chatId}
                  currSessionId={(this.props.target && this.props.target.id) || 0}
                  activeKey={currentWindowTab[0]}
                  getSessionList={this.props.getSessionList}
                ></ReissueMessage>
              ) : (
                ''
              )}
            </TabPane>

            <TabPane
              key="8"
              tab={
                <span className="tabs-item">
                  <Icon type="experiment" style={{ fontSize: '24px' }} />
                  <p>lab</p>
                </span>
              }
            >
              <Button onClick={this.openLab} style={{ marginTop: '10px' }}>
                打开多会话窗口页面
              </Button>
            </TabPane>
          </Tabs>
        </div>
      </Layout.Sider>
    );
  }
}

function mapStateToProps(state: State) {
  return {
    target: state.currentTarget,
    currentSiderTab: state.currentSiderTab,
    currentWindowTab: state.currentWindowTab,
    mediaList: state.mediaList,
    clients: state.clients,
    info: state.info,
    message: state.messageMap,
    sessionList: state.sessionList,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    setCurrentTarget: (data: Target) => dispatch(createSetCurrentTargetAction(data)),
    getSessionList: () => dispatch(createGetWxSessionListAction({ limit: 5000 })),
    getFriendList: () => dispatch(createGetWxFriendListAction({})),
    setCurrentWindowTab: (data: string) => dispatch(setCurrentWindowTabAction(data)),
    getMediaList: (data: any = {}) =>
      dispatch(createGetMediaLibraryListAction({ ...data, perpage: 5000 })),
    syncMessage: (param: any) => dispatch(createSyncSendMessage(param)),
    sendTextMsg: (param: any) => {
      dispatch(createSendTextMessageAction(param));
    },
    sendImageMsg: (param: any) => {
      dispatch(createSendImageMessageAction(param));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OperationPanel);

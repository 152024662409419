import React from 'react';
import zh_CN from 'antd/es/locale/zh_CN';
import { Modal, ConfigProvider, Button, Select, message, Icon, Input } from 'antd';
import { connect } from 'react-redux';
import { State } from '@/store/types/state';
import { createSyncSendMessage } from '@/store/action';
import request from '@/http/request';
import { setDraftToLocal, getLocalDraftBySessionId } from '@/util/draft';
import DomainModal from '@/components/DomainModal';
import ShowModal from '@/components/ShowModal';
// import { iteratee } from 'lodash';
// import LuckMoenyIcon from './LuckyMoney';
import './index.less';
import LinkSendDialog from './LinkSendDialog';
import MessageSender from '../../../../../component/MessageSender';
import EmojiSuggestions from '../../../../../component/EmojiSuggestions';

const { Option } = Select;
interface ChatSubProps {
  message?: any;
  leftHeader?: any;
  rightHeader?: any;
  bottomBlock?: any;
  target: any;
  // saveStorage?: (val1: any, val2: any) => any;
  sendImage?: (e: any, s?: any, a?: boolean) => any;
  sendText?: (e: any, mentions?: any[]) => any;
  sendLuckMoney?: (redPack: number, price: number, event: any) => void;
  sendLink?: (e: any) => void;
  onRef?: (e: any) => any;
  syncMessage: (data: any) => void;
}
interface OwnState {
  senderMessage: any;
  pos: any;
  pasteVisible: boolean;
  pasteSource: any;
  blob: any;
  editorValue: string;
  sessionId?: number;
  newPlainTextValue: string;
  mentions?: any[];
  memberList?: any[];
  stamp?: number;
  linkVisible: boolean;
  linkSourceObj: any;
  notAt: boolean;
  fissionVisible: boolean;
  domainVisible: boolean;
  fissionLinkValue: string;
  activityList: any;
  selectRedeemValue: number;
  activityId: number | null;
  linkTableVisible: boolean;
  rewardUsers: any[];
}

class ChatSub extends React.Component<ChatSubProps, OwnState> {
  constructor(props: ChatSubProps) {
    super(props);
    this.state = {
      sessionId: props.target.id || -1,
      editorValue: '',
      senderMessage: '',
      pos: { start: 0, end: 0 },
      pasteVisible: false,
      pasteSource: '',
      blob: '',
      newPlainTextValue: '',
      mentions: [],
      memberList: [],
      stamp: 0,
      linkVisible: false,
      linkSourceObj: {},
      notAt: false,
      fissionVisible: false,
      domainVisible: false,
      fissionLinkValue: '',
      activityList: [],
      selectRedeemValue: 1,
      activityId: null,
      linkTableVisible: false,
      rewardUsers: [],
    };
  }

  static getDerivedStateFromProps(props: ChatSubProps, state: OwnState) {
    return {
      sessionId: props.target.id,
      editorValue: props.target.id !== state.sessionId ? '' : state.editorValue, // 切换tab时清空输入框值
      newPlainTextValue: props.target.id !== state.sessionId ? '' : state.newPlainTextValue,
    };
  }

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    if (this.props.target.chatId !== undefined) this.fetchMemberList(this.props.target.chatId);
  }

  componentDidUpdate(prevProps: ChatSubProps) {
    if (
      this.props.target.chatId !== undefined &&
      this.props.target.chatId !== prevProps.target.chatId
    ) {
      this.fetchMemberList(prevProps.target.chatId);
    }
    if (
      this.props.message &&
      this.props.message.rawMsg &&
      this.props.message.rawMsg !== '' &&
      this.props.message.stamp !== this.state.stamp
    ) {
      this.props.syncMessage({
        msgType: 1,
        rawMsg: '',
      });
      this.updateText();
    }
  }

  fetchMemberList = (chatId: string) => {
    console.log(chatId);
    const inputValue = getLocalDraftBySessionId(this.state.sessionId);
    if (this.props.target.isSingle || this.props.target.isSingle === undefined) {
      this.setState({
        editorValue: inputValue,
        newPlainTextValue: inputValue,
      });
      return;
    }
    request
      .get('/v2/group/members', {
        groupId: this.props.target.chatId,
        start: 0,
        count: 1000,
        sortByInviteCount: false,
      })
      .then((res: any) => {
        this.setState({
          memberList: res.data.list,
          editorValue: inputValue,
          newPlainTextValue: inputValue,
        });
      });
  };

  showFissionModal = () => {
    this.setState({ fissionVisible: true });
    this.getActivityList();
  };

  showDomainModal = () => {
    ShowModal({
      props: {
        roomId: this.props.target.chatId,
      },
      width: 800,
      destroyOnClose: 'destroyOnClose',
      title: '域名管理',
      footer: null,
      childrens: [DomainModal],
    });
  };

  getActivityList = () => {
    request
      .get('/im/v2/fission/activity/list', {
        roomId: this.props.target.chatId,
      })
      .then((res: any) => {
        this.setState({
          activityList: res.data.list,
        });
      });
  };

  postFissionLink = () => {
    if (this.state.activityId === null) {
      message.error('请选择必选项!');
    } else {
      request
        .post('/im/v2/fission/link', {
          roomId: this.props.target.chatId,
          activityId: this.state.activityId,
        })
        .then((res: any) => {
          this.setState({
            fissionLinkValue: res.data.link,
          });
        });
    }
  };

  sendFissionLink = () => {
    this.handleOkSendLink({
      id: true,
      title: '邀请有礼',
      linkUrl: this.state.fissionLinkValue,
      description: '点击兑换奖品&查看排行榜',
      mediaUrl:
        'https://hibaru.oss-cn-hangzhou.aliyuncs.com/static/img/lALPD4d8sBCXZoLMzszI_200_206.png',
    });
    this.setState({
      fissionLinkValue: '',
      rewardUsers: [],
      fissionVisible: false,
    });
  };

  updateText = () => {
    if (!this.props.message) {
      // 切换会话, 清空
      this.setState({ newPlainTextValue: '', editorValue: '', mentions: [] });
    } else {
      const { rawMsg, msgType, atList = [], stamp, notAt } = this.props.message;
      const { newPlainTextValue, mentions = [], pos } = this.state;
      if (!msgType) {
        // 判断是不是文本信息 msgType = 0: 文字, 1: 图片, 2: 链接
        if (msgType === 0 && atList.length > 0) {
          // @人
          const param: any = {};
          const plainText: any = ''.concat(
            newPlainTextValue.slice(0, pos.start),
            this.props.message.newPlainTextValue || rawMsg,
            newPlainTextValue.slice(pos.end),
          );
          param.stamp = stamp;
          param.newPlainTextValue = plainText;
          param.editorValue = ''.concat(
            newPlainTextValue.slice(0, pos.start),
            this.props.message.editorValue || rawMsg,
            newPlainTextValue.slice(pos.end),
          );
          param.mentions = mentions.concat(atList);
          param.pos = {
            start: plainText.length,
            end: plainText.length,
          };
          this.handleChange(param);
        } else {
          // 快捷回复, 收藏
          this.setState({ newPlainTextValue: rawMsg, editorValue: rawMsg, mentions: [] });
        }
      } else if (msgType === 1) {
        // 如果是图片 弹窗显示
        this.setState(
          {
            pasteVisible: true,
            pasteSource: rawMsg,
            blob: '',
            notAt,
          },
          () => {
            this.props.syncMessage({
              msgType: 1,
              stamp: 0,
              mentions: [],
            });
          },
        );
      } else if (msgType === 2) {
        this.setState({
          linkVisible: true,
          linkSourceObj: JSON.parse(rawMsg),
        });
      }
    }
  };

  handlerSendBtnClick = () => {
    const { newPlainTextValue, mentions } = this.state;
    if (!newPlainTextValue) return; // 将要发送的text
    if (this.props.sendText) {
      this.props.sendText(newPlainTextValue, mentions);
      // 清空当前会话的草稿缓存
      setDraftToLocal(this.state.sessionId, '');
    }
    this.setState({
      editorValue: '',
      newPlainTextValue: '',
      mentions: [],
    });
  };

  handlerPaste = (e: any) => {
    const { clipboardData } = e;
    if (clipboardData.items) {
      const { items } = clipboardData;
      let blob: any = null;
      for (let i = 0, len = items.length; i < len; i += 1) {
        if (items[i].type.indexOf('image') !== -1) {
          blob = items[i].getAsFile();
        }
      }
      if (!blob) return;
      const URLObj = window.URL;
      const source = URLObj && URLObj.createObjectURL(blob);

      this.setState({
        pasteVisible: true,
        pasteSource: source,
        blob,
      });
    }
  };

  handleChange = (e: any) => {
    // if (this.props.saveStorage) {
    //   this.props.saveStorage(e.target.value, e.target.senderValue);
    // }
    const s = this.state;
    this.setState({
      ...s,
      ...e,
    });
  };

  handlerImgTextChange = (value: string) => {
    const { newPlainTextValue, pos } = this.state;
    const newString =
      newPlainTextValue.substring(0, pos.start) +
      value +
      newPlainTextValue.substring(pos.end, newPlainTextValue.length);
    this.setState({
      editorValue: newString,
      newPlainTextValue: newString,
      pos: {
        start: pos.start + value.length,
        end: pos.start + value.length,
      },
    });
  };

  handleCancelPaste = () => {
    this.setState({
      pasteVisible: false,
    });
  };

  handleOkPaste = () => {
    if (this.props.sendImage) {
      this.props.sendImage(this.state.blob, this.state.pasteSource, this.state.notAt);
    }
    this.handleCancelPaste();
  };

  handleCancelLink = () => {
    this.setState({
      linkVisible: false,
    });
  };

  handleOkSendLink = (emitItem: any) => {
    if (this.props.sendLink) {
      if (emitItem.id) {
        this.setState(
          {
            linkTableVisible: false,
            linkSourceObj: emitItem,
          },
          () => {
            if (this.props.sendLink) {
              // 此处重复判断,为避免ts报错
              this.props.sendLink(this.state.linkSourceObj);
            }
          },
        );
      } else {
        this.props.sendLink(this.state.linkSourceObj);
      }
    }
    this.handleCancelLink();
  };

  handleBlur = (e: any) => {
    setDraftToLocal(this.state.sessionId, e.target.value || '');
  };

  handleGetRewardUsers = (roomId: string = '', activityId: number | null = null) => {
    if (roomId !== '' && activityId !== null) {
      request
        .get('/im/v2/fission/reward/users', {
          roomId,
          activityId,
        })
        .then((res: any) => {
          this.setState({
            rewardUsers: res.data.list,
          });
        });
    } else {
      message.warning('请选择活动!');
    }
  };

  render() {
    const {
      editorValue,
      fissionLinkValue,
      pos,
      pasteVisible,
      pasteSource,
      memberList,
      linkVisible,
      linkSourceObj,
      activityId,
      rewardUsers,
    } = this.state;

    return (
      <div className="chat-pannel">
        <div className="chat-pannel-header">
          <div className="chat-panel-header-left">
            <EmojiSuggestions
              onSelectChange={this.handlerImgTextChange}
              syncMessage={this.props.syncMessage}
              showEmogticon
            />
            <span className="chat-pannel-header-icon">{this.props.leftHeader}</span>
            <Icon
              type="link"
              className="chat-pannel-header-icon"
              onClick={() =>
                this.setState({
                  linkTableVisible: true,
                })
              }
            />
            <Icon type="gift" className="chat-pannel-header-icon" onClick={this.showFissionModal} />
            <Icon
              className="chat-pannel-header-icon"
              type="setting"
              onClick={this.showDomainModal}
            />
          </div>
          <div className="chat-panel-header-right">{this.props.rightHeader}</div>
        </div>
        <div
          className="chat-pannel-content"
          style={{
            height: this.props.target.isSingle ? '88px' : '100px',
            padding: '0 16px',
          }}
        >
          <MessageSender
            pos={pos}
            target={this.props.target}
            editorValue={editorValue}
            onChange={this.handleChange}
            onClick={this.handlerSendBtnClick}
            onPaste={this.handlerPaste}
            onBlur={this.handleBlur}
            memberList={memberList}
          ></MessageSender>
        </div>
        {this.props.bottomBlock}
        <ConfigProvider locale={zh_CN}>
          <Modal
            width={760}
            style={{ textAlign: 'center' }}
            centered
            title={<h3 className="send-title">发送图片</h3>}
            visible={pasteVisible}
            onCancel={this.handleCancelPaste}
            onOk={this.handleOkPaste}
          >
            <img alt="发送图片" src={pasteSource} className="see-img-modal" />
          </Modal>
          <Modal
            width={450}
            style={{ textAlign: 'center' }}
            centered
            title={<h3 className="send-title">发送网页</h3>}
            visible={linkVisible}
            onCancel={this.handleCancelLink}
            onOk={this.handleOkSendLink}
          >
            <div className="share-card" style={{ border: '1px solid #BFBFBF', margin: '0 64px' }}>
              <div className="share-title">{linkSourceObj.title}</div>
              <div className="share-content">
                <div className="share-text">{linkSourceObj.description}</div>
                <img alt="图片" className="share-img" src={linkSourceObj.mediaUrl} />
              </div>
            </div>
          </Modal>
          <Modal
            title="裂变兑奖"
            destroyOnClose
            visible={this.state.fissionVisible}
            onCancel={() =>
              this.setState({
                fissionLinkValue: '',
                rewardUsers: [],
                fissionVisible: false,
              })
            }
            footer={[
              <Button type="primary" onClick={this.sendFissionLink}>
                确认发送
              </Button>,
            ]}
          >
            <span style={{ marginRight: 10 }}>
              * 兑奖方式:{' '}
              <Select
                defaultValue="1"
                style={{ width: 120 }}
                onChange={(e: any) => {
                  this.setState({ selectRedeemValue: e });
                }}
              >
                <Option value="1">活动兑奖</Option>
              </Select>
            </span>
            <span>
              * 选择活动:{' '}
              <Select
                dropdownMatchSelectWidth={false}
                style={{ width: 120 }}
                onChange={(e: any) => {
                  this.setState({ activityId: e });
                }}
              >
                {this.state.activityList.map((it: any) => (
                  <Option value={it.id} key={it.id}>
                    {it.name}
                  </Option>
                ))}
              </Select>
            </span>
            <div>
              <div style={{ margin: '10px 0' }}>
                <Button
                  type="primary"
                  size="small"
                  style={{ marginRight: '10px' }}
                  onClick={this.postFissionLink}
                >
                  生成H5链接
                </Button>
                <Button
                  type="primary"
                  size="small"
                  onClick={() => this.handleGetRewardUsers(this.props.target.chatId, activityId)}
                >
                  获取结果
                </Button>
              </div>
              {fissionLinkValue ? (
                <Input placeholder="生成H5链接结果" value={fissionLinkValue} />
              ) : null}
              <div style={{ marginTop: '10px' }}>
                {rewardUsers.map((item, index) => (
                  <div key={item.id}>
                    <div
                      style={{
                        fontSize: '16px',
                        fontWeight: 'bold',
                        marginBottom: '10px',
                      }}
                    >
                      奖品{index + 1}：{item.name}
                    </div>
                    {item.rules.map(rule => (
                      <div key={rule.id}>
                        <div
                          style={{
                            fontSize: '14px',
                            fontWeight: 'bold',
                            marginBottom: '5px',
                          }}
                        >
                          {rule.name}
                        </div>
                        <div style={{ display: 'flex' }}>
                          {rule.users.map(user => (
                            <div>
                              <img
                                src={user.smallHeadUrl}
                                alt=""
                                style={{
                                  width: '30px',
                                  height: '30px',
                                  margin: '2px 10px',
                                }}
                              />
                              {user.nickname}
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </Modal>
          <Modal
            visible={this.state.linkTableVisible}
            title="发链接"
            destroyOnClose
            onCancel={() =>
              this.setState({
                linkTableVisible: false,
              })
            }
            maskClosable={false}
            footer={null}
            style={{ top: 20, bottom: 20 }}
            width={1000}
          >
            <LinkSendDialog onOk={this.handleOkSendLink} />
          </Modal>
        </ConfigProvider>
      </div>
    );
  }
}

function mapStateToProps(state: State) {
  return {
    message: state.messageMap[0],
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    syncMessage: (param: any) => dispatch(createSyncSendMessage(param)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatSub);

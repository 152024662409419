import React, { useState, useEffect } from 'react';
import { Input, Form, Table, Row, Col, Button, Select, Badge, Tag, Tooltip } from 'antd';
import moment from 'moment';
import request from '@/http/request';
import ShowModal from '@/components/ShowModal';

const CHAINS = ['零级', '一级', '两级'];

const columns = [
  {
    title: '微信',
    dataIndex: 'nickname',
    key: 'nickname',
    className: 'table-tr tr1',
    width: 350,
    render: (text: string, record: any) => {
      let tagComponent = null;
      if (record.isCurrentUser) {
        tagComponent = <Tag color="red">该用户</Tag>;
      } else if (record.linearRelative) {
        tagComponent = <Tag color="blue">上线</Tag>;
      }
      return (
        <span>
          <img
            style={{ width: '30px', border: '1px solid #ccc', borderRadius: '4px' }}
            src={record.headImg}
            alt=""
          />
          <Tooltip title={record.nickname}>
            <span style={{ padding: '0 10px' }}>
              {record.nickname.length > 5
                ? `${record.nickname.substring(0, 5)}...`
                : record.nickname}
            </span>
          </Tooltip>
          {tagComponent}
        </span>
      );
    },
  },
  {
    title: '微信号',
    dataIndex: 'wxId',
    key: 'wxId',
    render: (text: string, record: any) => {
      return <span>{record.wxId || 'wxId'}</span>;
    },
  },
  {
    title: '进群时间',
    dataIndex: 'joinTime',
    key: 'joinTime',
    render: (joinTime: string) => (
      <span style={{ fontSize: '10px' }}>{moment(joinTime).format('YYYY-MM-DD HH:mm:ss')}</span>
    ),
  },
  {
    title: '状态',
    dataIndex: 'status',
    key: 'status',
    render: (status: number) => {
      if (status === 0) return <Badge status="success" text="正常"></Badge>;
      if (status === 1) {
        return (
          <span style={{ fontSize: '10px' }}>
            <Badge status="default" text="不在群内"></Badge>
          </span>
        );
      }

      if (status === 2) {
        return (
          <span style={{ fontSize: '10px' }}>
            <Badge status="error" text="已加入黑名单"></Badge>
          </span>
        );
      }

      return null;
    },
  },
  {
    title: '邀请人数',
    dataIndex: 'currInvite',
    key: 'currInvite',
    render: (val: number, record: any) => {
      return val === 0 ? (
        0
      ) : (
        <span style={{ color: '#1890ff' }}>
          {val}/{record.totalInvite}
        </span>
      );
    },
  },
];

const RelationChainModel = (props: any) => {
  const RelationModel = () => {
    const [inputValue, setInputValue] = useState('');
    const [dataList, setDataList] = useState([]);
    const [passwordError, setPasswordError] = useState(false);
    const [selectKeys, setSelectKeys] = useState([]);
    const [level, setLevel] = useState(0);

    useEffect(() => {
      const { toWxid, fromWxid } = props;
      request
        .get(`/im/v2/groups/${toWxid}/family_tree`, {
          memberWxId: fromWxid,
          span: level,
        })
        .then((res: any) => {
          setDataList(res.data.familyTree || []);
        });
    }, [level]);

    const rowSelection = {
      onChange: (selectedRowKeys: any) => {
        setSelectKeys(selectedRowKeys);
      },
      getCheckboxProps: (record: any) => {
        return {
          disabled: record.status === 2,
        };
      },
    };

    return (
      <div>
        <Form style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Form.Item key="1" label="层级关系" style={{ display: 'flex' }}>
            <Select
              style={{ width: '200px' }}
              defaultValue={CHAINS[0]}
              onChange={(e: any) => {
                setLevel(e);
              }}
            >
              <Select.Option value="0" key="0">
                {CHAINS[0]}
              </Select.Option>
              <Select.Option value="1" key="1">
                {CHAINS[1]}
              </Select.Option>
              <Select.Option value="2" key="2">
                {CHAINS[2]}
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            key="2"
            label="密码："
            style={{ display: 'flex' }}
            validateStatus={passwordError ? 'error' : 'success'}
            help={passwordError ? '密码错误，请重新输入' : ''}
          >
            <Input.Password
              style={{ width: '200px' }}
              placeholder="请输入当前账号密码"
              value={inputValue}
              onChange={e => {
                setPasswordError(false);
                setInputValue(e.target.value);
              }}
            />
          </Form.Item>
        </Form>
        <Table
          columns={columns}
          rowSelection={rowSelection}
          dataSource={dataList}
          indentSize={35}
          rowKey={(record: any) => record.wxId}
          pagination={false}
          size="small"
          defaultExpandAllRows
        ></Table>
        <Row gutter={15} type="flex" justify="end" align="middle" style={{ paddingTop: '24px' }}>
          <Col>
            已选择<span style={{ color: 'blue' }}>{selectKeys.length}</span>人
          </Col>
          <Col>
            <Button onClick={() => ShowModal.close()}>取消</Button>
          </Col>
          <Col>
            <Button
              type="primary"
              disabled={
                (selectKeys.length > 1 && inputValue.length === 0) || selectKeys.length === 0
              }
              onClick={() => {
                request
                  .post('/im/v2/wx_user/black/list', {
                    wxId: selectKeys,
                    password: inputValue,
                  })
                  .then(() => {
                    ShowModal.close();
                  })
                  .catch((error: any) => {
                    console.log('error', error);
                    if (error.response && error.response.data && error.response.data.code === 400) {
                      setPasswordError(true);
                    }
                  });
              }}
            >
              加入黑名单
            </Button>
          </Col>
        </Row>
      </div>
    );
  };

  ShowModal({
    props,
    width: 1000,
    hasFooter: false,
    destroyOnClose: 'destroyOnClose',
    title: '查看关系链',
    childrens: [RelationModel],
  });
};

export default RelationChainModel;

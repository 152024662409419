import React from 'react';
import request from '@/http/request';
import ShowModal from '@/components/ShowModal';

const DeleteBlackModal = (props: any) => {
  const BlackModal = () => {
    return <div style={{ textAlign: 'left' }}>确定从黑名单中移除吗</div>;
  };

  ShowModal({
    props,
    width: 220,
    destroyOnClose: 'destroyOnClose',
    title: '移除黑名单',
    childrens: [BlackModal],
    onOk: () => {
      request
        .delete('/v2/wx_user/black/list', {
          blackWxId: props.wxId,
        })
        .then(() => {
          props.onOk();
        });
    },
  });
};

export default DeleteBlackModal;

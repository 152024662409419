import { BaseAction } from '../types/action';
import { ACTION_SET_TAB_UNREAD_COUNT } from '../constant';
import { TabUnReadCount } from '../types/state';

export function createSetTabUnReadCountAction(data: TabUnReadCount): BaseAction {
  return {
    type: ACTION_SET_TAB_UNREAD_COUNT,
    data,
  };
}

import React from 'react';
import { Tabs, Divider, Switch, Button, Badge, Select, Popconfirm, Alert } from 'antd';
import { connect } from 'react-redux';
import { State, WxSession, Target, Info, TabUnReadCount } from '@/store/types/state';
import {
  createGetWxSessionListAction,
  createSetCurrentTargetAction,
  deleteSyncSendMessageAction,
  createUpdateWxMessageListStatus,
  setCurrentTabAction,
  setCurrentSessionListFilterCondition,
  setCurrentWindowTabAction,
  createGetMockSessionListAction,
  createUpdateCurrClientInfoAction,
  creatSetSessionInfoAction,
  createSetTabUnReadCountAction,
} from '@/store/action';
import eventBus from '@/ws/event';
import request from '@/http/request';
import { TARGET_TYPE_SESSION } from '@/store/constant';
import ChatItem from '../../../../component/ChatItem';
import Search from '../Search';
import './index.less';

const tabFontSize = {
  fontSize: '14px',
};
const { Option } = Select;

interface Prop {
  clients: any;
  currentTarget: Target;
  info: Info;
  tabUnReadCount: any;
  currentSiderTab: string;
  currentSessionListFilterCondition: any;
  sessionList: WxSession[];
  getSessionList: () => void;
  setCurrentTarget: (data: Target | null) => any;
  deleteSyncMessage: () => any;
  updateMessageListStatus: (data: any, sessionid: number) => any;
  setCurrentTab: (data: string) => void;
  setCurrentSessionListFilterConditionFn: (data: any) => void;
  setCurrentWindowTab: (data: string) => void;
  setMockSessionlist: (data: any) => any;
  updateSingleClient: (data: any) => any;
  setSessionInfo: (data: any) => any;
  setTabUnReadCount: (data: TabUnReadCount) => any;
}

interface OwnState {
  pubStatus: number;
  dispatchStatus: number;
  divisionList: any[];
}

class SiderTab extends React.Component<Prop, OwnState> {
  constructor(props: Prop) {
    super(props);
    this.state = {
      pubStatus: 0,
      dispatchStatus: 0,
      divisionList: [],
    };
  }

  componentDidMount() {
    // 会话列表初始轮询
    this.pollingFn();
    eventBus.on('updateSessionList', () => this.hackScrollMove());
    eventBus.on('handlerChatItemClick', (item: any) => this.handlerChatItemClick(item));
    request.get('/im/v2/filters/pub_msg').then((res: any) => {
      this.setState({
        pubStatus: res.data.status,
      });
    });
    this.getDispatchStatus();
    this.getDivisions();
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.currentSiderTab !== prevProps.currentSiderTab) {
      this.props.getSessionList();
    }
  }

  getDispatchStatus = () => {
    request.get('/im/v2/user/dispatch_topic').then((res: any) => {
      this.setState({
        dispatchStatus: res.data.dispatch,
      });
    });
  };

  componentWillUnmount() {
    this.pollingClearFn();
  }

  pollingTimer: any = '';

  scrollBoxRef: any = '';

  getDivisions = () => {
    request.get('/im/v2/group/user/divisions?page=0&perpage=1000').then(res => {
      this.setState({
        divisionList: res.data.list,
      });
    });
  };

  setCacheSessionList = () => {
    const {
      setMockSessionlist,
      clients: { current: currentClient },
    } = this.props;
    const currClientWxId = currentClient && currentClient.wxId;
    if (!currClientWxId && localStorage.getItem(`${currClientWxId}-sessionList`)) return;
    const storageData = localStorage.getItem(`${currClientWxId}-sessionList`) || '';
    let cache: any;
    try {
      cache = JSON.parse(storageData);
    } catch (e) {
      console.log(e);
    }
    if (cache) {
      setMockSessionlist(cache);
    }
  };

  // hack方法，滚动1px解决lazy-load组件带来的渲染问题
  hackScrollMove = () => {
    if (this.scrollBoxRef) {
      this.scrollBoxRef.scrollTop = this.scrollBoxRef.scrollTop + 1;
    }
  };

  handlerChatItemClick = (chatItem: any) => {
    if (!chatItem) return;
    const number = chatItem.newUnread || 0;
    const { id, chatType } = chatItem;

    if (this.props.currentTarget && this.props.currentTarget.id === id) return;
    this.props.setCurrentTarget({
      id,
      type: TARGET_TYPE_SESSION,
      isSingle: chatType === 3,
      chatId: chatItem.chatId,
      currSessionUnreadNum: number,
      chatType,
      nickname: chatItem.nickname,
      avatar: chatItem.headImg,
    });
    // 重置请求messageList的控制参数
    this.props.updateMessageListStatus(
      {
        messageIsEnd: false,
        messageNextIndex: '',
      },
      id,
    );
    // 需要调用接口 获取群信息/子会话信息。替换以前meta接口
    request.get(`/im/v2/sessions/${id}/meta`).then((res: any) => {
      const { data } = res;
      this.props.setSessionInfo({
        topic: {},
        showAite: true,
        ...data,
      });
      this.props.updateSingleClient({
        ...data.owner,
        headImg: res.data.owner.avatar,
      });
    });

    this.props.deleteSyncMessage();
    if (chatItem.newUnread) {
      let { qunUnReadCount, subUnReadCount } = this.props.tabUnReadCount;
      if (chatType === 3) {
        subUnReadCount -= 1;
      } else {
        qunUnReadCount -= 1;
      }
      this.props.setTabUnReadCount({ qunUnReadCount, subUnReadCount });
    }
  };

  handlerTabChange = (key: any) => {
    if (this.props.currentSiderTab !== key) {
      this.props.setCurrentTab(key);
      this.props.setCurrentTarget(null);
    }
  };

  pollingFn = () => {
    this.pollingTimer = setInterval(() => {
      this.props.getSessionList();
    }, 20000);
  };

  /*
   * 会话列表轮询
   */
  pollingClearFn = () => {
    clearInterval(this.pollingTimer);
  };

  handlerChangePubStatus = () => {
    const status = 1 - this.state.pubStatus;
    this.setState(() => ({
      pubStatus: status,
    }));
    request
      .put('/im/v2/filters/pub_msg')
      .then((res: any) => {
        if (res.code === 200) {
          eventBus.emit('getSessionMessageList', { replace: true, isNew: false });
        }
      })
      .catch((err: any) => {
        this.setState({
          pubStatus: 1 - status,
        });
        console.log(err);
      });
  };

  handlerChangeDispatchStatus = () => {
    const status = 1 - this.state.dispatchStatus;
    this.setState(() => ({
      dispatchStatus: status,
    }));
    request
      .put('/im/v2/user/dispatch_topic', { dispatch: status })
      .then((res: any) => {
        if (res.code === 200) {
          console.log(res);
          // eventBus.emit('getSessionMessageList', { replace: true, isNew: false });
        }
      })
      .catch((err: any) => {
        this.setState({
          dispatchStatus: 1 - status,
        });
        console.log(err);
      });
  };

  closeAllMessage = () => {
    request.delete('/im/v2/topics').then(() => {
      this.props.setCurrentTarget(null);
      this.props.getSessionList();
    });
  };

  handlerSearchItemClick = async (target: Target) => {
    // TODO: 建立群会话, 因为选中target时还有其它事件要完成,暂时使用emit
    // this.props.setCurrentTarget(target);
    await eventBus.emit('handlerChatItemClick', target);
    // const dom = document.querySelector('.ant-tabs-tabpane-active .chatitem-active').scrollTop;
    // 切换后滚动到当前位置
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    document.querySelector('.ant-tabs-tabpane-active .chat-item-active')!.scrollIntoView();
  };

  // 会话筛选；0：全部；1：只看未读；2：只看好友；3：只看群聊；5：只看失败
  sessionFilter = (val: any) => {
    console.log(val);
    const {
      currentSessionListFilterCondition,
      setCurrentSessionListFilterConditionFn,
      getSessionList,
      setCurrentTarget,
    } = this.props;
    setCurrentSessionListFilterConditionFn({ ...currentSessionListFilterCondition, ...val });
    getSessionList();
    setCurrentTarget(null);
  };

  render() {
    const {
      sessionList,
      currentTarget,
      currentSiderTab,
      clients,
      currentSessionListFilterCondition,
    } = this.props;
    const { dispatchStatus, divisionList } = this.state;
    const sessionItem = (
      <div
        className="scroll-box"
        ref={node => {
          this.scrollBoxRef = node;
        }}
      >
        {sessionList
          .filter(item => item.chatType === parseInt(currentSiderTab, 10))
          .map(item => (
            <div
              key={item.id}
              data-session-id={item.id}
              style={{
                position: 'relative',
              }}
              className={`${item.imTopStatus ? 'chat-item-stick ' : ''} ${
                item.undelivered ? 'chat-item-failed ' : ''
              } ${currentTarget && item.id === currentTarget.id ? 'chat-item-active' : ''}`}
            >
              {/* <Link
                to={`/${(item.chatType === 3 ? 'topic_' : 'group_') + convertNumMap(`${item.id}`)}`}
              > */}
              <ChatItem
                item={item}
                clients={clients}
                currentTarget={currentTarget}
                chatItemClick={() => this.handlerChatItemClick(item)}
              />
              {/* </Link> */}

              <Divider
                style={{
                  margin: '0px 16px',
                  width: '90%',
                  minWidth: 'unset',
                  position: 'absolute',
                }}
              />
            </div>
          ))}
      </div>
    );
    const { qunUnReadCount, subUnReadCount, undeliveredCount } = this.props.tabUnReadCount;
    return (
      <Tabs
        defaultActiveKey="3"
        animated={false}
        activeKey={currentSiderTab}
        className="sider-tab"
        onChange={this.handlerTabChange}
        tabBarStyle={tabFontSize}
      >
        <Tabs.TabPane
          key="3"
          className="chat-topic"
          tab={
            <span style={tabFontSize}>
              <Badge count={subUnReadCount > 0 ? subUnReadCount : ''} overflowCount={99999999}>
                <span>分配的会话</span>
              </Badge>
            </span>
          }
        >
          <div className="sub-tabs">
            <p>会话数：{sessionList.length}</p>
            <div className="im-title-font-small">
              会话分配：
              <Popconfirm
                title={dispatchStatus ? '确认停止分配会话？' : '确认开启分配会话？'}
                onConfirm={this.handlerChangeDispatchStatus}
                okText="确认"
                cancelText="取消"
              >
                <Switch
                  size="small"
                  checkedChildren="开"
                  unCheckedChildren="关"
                  checked={!!dispatchStatus}
                />
              </Popconfirm>
            </div>
          </div>
          <div className="sub-tabs">
            <Button size="small" type="primary" ghost onClick={this.closeAllMessage}>
              批量结束会话
            </Button>
          </div>
          {sessionItem}
        </Tabs.TabPane>
        <Tabs.TabPane
          key="2"
          tab={
            <span style={tabFontSize}>
              <Badge count={qunUnReadCount > 0 ? qunUnReadCount : ''} overflowCount={99999999}>
                {undeliveredCount ? <Alert message="" banner className="warning-icon" /> : ''}
                <span>进群聊天</span>
              </Badge>
            </span>
          }
        >
          <Search onClick={this.handlerSearchItemClick} openCreateQunMember={() => {}} />
          <div className="sub-tabs">
            <div>群数：{sessionList.length}</div>
            <div className="im-title-font-small">
              显示群公告和群发消息：
              <Switch
                size="small"
                checked={!this.state.pubStatus}
                onChange={this.handlerChangePubStatus}
              />
            </div>
          </div>
          <div style={{ margin: 10 }}>
            <Select
              style={{ width: 80, marginRight: 5 }}
              onChange={val => this.sessionFilter({ focus: val })}
              value={
                currentSessionListFilterCondition.focus !== '0'
                  ? currentSessionListFilterCondition.focus
                  : '全部'
              }
            >
              <Option value="0">全部</Option>
              <Option value="1">只看未读</Option>
              <Option value="5">只看失败</Option>
            </Select>
            <Select
              showSearch
              filterOption={(input, option) =>
                option.props.children
                  .toString()
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              style={{ width: 100, marginRight: 5 }}
              dropdownMatchSelectWidth={false}
              onChange={val => this.sessionFilter({ divisionId: val })}
              value={
                currentSessionListFilterCondition.divisionId !== '0'
                  ? currentSessionListFilterCondition.divisionId
                  : '全部'
              }
            >
              <Option key="0" value="0">
                全部
              </Option>
              {divisionList.map((item: any) => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
            <Button type="primary" onClick={this.props.getSessionList} size="small">
              刷新
            </Button>
          </div>
          {sessionItem}
        </Tabs.TabPane>
      </Tabs>
    );
  }
}

function mapStateToProps(state: State) {
  const {
    currentTarget,
    clients,
    currentSiderTab,
    currentSessionListFilterCondition,
    info,
    tabUnReadCount,
  } = state;

  return {
    currentTarget,
    clients,
    currentSiderTab,
    currentSessionListFilterCondition,
    info,
    tabUnReadCount,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    getSessionList: () => dispatch(createGetWxSessionListAction({ limit: 5000 })),
    setCurrentTarget: (data: Target | null) => dispatch(createSetCurrentTargetAction(data)),
    setSessionInfo: (data: Info | null) => dispatch(creatSetSessionInfoAction(data)),
    deleteSyncMessage: () => dispatch(deleteSyncSendMessageAction()),
    updateMessageListStatus: (params: any, sessionId: number) =>
      dispatch(createUpdateWxMessageListStatus(params, sessionId)),
    setCurrentTab: (data: string) => dispatch(setCurrentTabAction(data)),
    setCurrentSessionListFilterConditionFn: (data: any) =>
      dispatch(setCurrentSessionListFilterCondition(data)),
    setCurrentWindowTab: (data: string) => dispatch(setCurrentWindowTabAction(data)),
    setMockSessionlist: (data: any) => dispatch(createGetMockSessionListAction(data)),
    updateSingleClient: (data: any) => dispatch(createUpdateCurrClientInfoAction(data)),
    setTabUnReadCount: (data: TabUnReadCount) => dispatch(createSetTabUnReadCountAction(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SiderTab);

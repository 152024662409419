import { put, takeEvery, all } from 'redux-saga/effects';
import store from '@/store';
import { ACTION_GET_CLIENT_LIST, ACTION_SET_CURRENT_CLIENT } from '../constant';
import {
  createSetClientListAction,
  createSetCurrentClientAction,
  createGetMockSessionListAction,
  createSetSyncClientAction,
  createGetWxSessionListAction,
} from '../action';

export function* getClientList() {
  try {
    const mockList = [
      {
        wxId: 'wxid_tisjxkjwu2ax22',
        wxAlias: 'vzizi28372',
        // nickname: '孜然',
        // headImg:
        //   'http://wx.qlogo.cn/mmhead/ver_1/5LrclHP04LfHtibAiboqZf8Z68l4uOsEzvicIXsfecX2So5v0oZjGcoTSShianCvUwmRHUdBzoaYsJWB8ZgCTXH7VXehzrLbn4NicSnL1YdASI7Y/0',
      },
    ];
    if (Array.isArray(mockList) && mockList.length > 0) {
      yield put(createSetClientListAction(mockList));
      yield put(createSetCurrentClientAction(mockList[0]));
    }
  } catch (error) {
    console.error(error);
  }
}

export function* syncClient(action: any) {
  const { currentSiderTab } = store.getState();
  try {
    const {
      data: { wxId: currClientWxId },
    } = action;
    // 使用缓存中的sessionList数据预先渲染会话列表
    if (
      currClientWxId &&
      localStorage.getItem(`${currClientWxId}-sessionList`) &&
      currentSiderTab === '1'
    ) {
      const storageData = localStorage.getItem(`${currClientWxId}-sessionList`) || '';
      let cache: any;
      try {
        cache = JSON.parse(storageData);
      } catch (e) {
        console.log(e);
      }
      if (cache) {
        yield put(createGetMockSessionListAction(cache));
      }
    }
    yield put(createSetSyncClientAction(action.data));

    // 请求真实会话列表数据并覆盖渲染
    // if (currentSiderTab === '1') {
    yield put(
      createGetWxSessionListAction({
        limit: 5000,
      }),
    );
    // }
  } catch (error) {
    console.error(error);
  }
}

export function* watchGetClientList() {
  yield takeEvery(ACTION_GET_CLIENT_LIST, getClientList);
}

export function* watchSyncClient() {
  yield takeEvery(ACTION_SET_CURRENT_CLIENT, syncClient);
}

export function* watchClient() {
  // yield all([watchGetClientList()]); // watchSyncClient()
  yield all([watchGetClientList(), watchSyncClient()]); // watchSyncClient()
}

import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'antd';
import clx from 'classnames';
import { formatDate } from '@/util';
import request from '@/http/request';
import ShowModal from '@/components/ShowModal';
import {
  createGetGroupMemberListAction,
  createSyncSendMessage,
  createGetWxSessionListAction,
  createSetCurrentTargetAction,
} from '@/store/action';
import { State, Target } from '@/store/types/state';
import MessageContent from './MessageContent';

const ChatInvitationPreviewModal = (props: any, wxId: string, fromWxid: string) => {
  const ChatInvitationPreview = () => {
    const { status } = props;
    return (
      <div className="chat-invitation">
        {status === 4 ? (
          ''
        ) : (
          <>
            <img alt="头像" className="chat-invitation-avatar" src={props.groupAvatar} />
            <div className="chat-invitation-member">{props.memberCount}</div>
            <div className="chat-invitation-message">{props.message}</div>
          </>
        )}
        {(() => {
          if (fromWxid === wxId) {
            // 自己发出的邀请
            return <div style={{ fontSize: '20px' }}>该邀请已发送</div>;
          }
          switch (status) {
            case 0: // 页面可用，未接受邀请
            case 2: // 页面可用，未实名
            case 3: // 页面可用，未实名
              return (
                <>
                  <Button
                    ghost
                    type="primary"
                    disabled={status === 2 || status === 3}
                    style={{ position: 'relative' }}
                    onClick={() => {
                      request
                        .post('/im/v2/group/invitation/accept', {
                          wxId,
                          url: props.acceptUrl,
                        })
                        .then(() => {
                          ShowModal.close();
                        });
                    }}
                  >
                    {status === 2 || status === 3 ? <div className="mask-btn"></div> : ''}
                    加入群聊
                  </Button>
                  <div className="chat-invitation-info">
                    {props.extraInfos &&
                      props.extraInfos.length &&
                      props.extraInfos.map((text: string, index: number) => (
                        <p key={index}>{`${index + 1}、${text}`}</p>
                      ))}
                  </div>
                </>
              );
            case 1: // 页面可用，已接受邀请
              return <div style={{ color: '#389E0D', fontSize: '20px' }}>你已接受邀请</div>;
            case 4: // 页面不可用，已失效
              return (
                <>
                  <i style={{ color: '#FF1A2E', fontSize: 80 }} className="iconfont">
                    &#xe632;
                  </i>
                  <p style={{ fontSize: '20px' }}>该邀请已失效</p>
                </>
              );
            default:
              return '';
          }
        })()}
      </div>
    );
  };
  ShowModal({
    width: 500,
    bodyStyle: {
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'center',
      height: '504px',
    },
    destroyOnClose: 'destroyOnClose',
    hasFooter: false,
    childrens: [ChatInvitationPreview],
  });
};

class Message extends React.Component<any> {
  getChatInvitationInfo = (url: any) => {
    request
      .get('/im/v2/group/invitation', {
        wxId: this.props.wxId,
        url,
      })
      .then((res: any) => {
        ChatInvitationPreviewModal(res.data, this.props.wxId, this.props.fromWxid);
      });
  };

  render() {
    const {
      fromAvatar = '',
      wxId,
      msgType,
      fromWxid,
      mid,
      fromNickname,
      createTime,
      status,
      comment,
      source,
      topicReceiverUName,
      senderUName,
    } = this.props;
    const size = fromAvatar.indexOf('wework') > -1 ? 100 : 132;
    const miniHeadImage =
      fromAvatar && fromAvatar.substr(-2) === '/0'
        ? `${fromAvatar.substr(0, fromAvatar.length - 1)}${size}`
        : fromAvatar;
    const showMsgId = <div style={{ color: '#eee' }}>[{mid}]</div>;

    // 判断 message 类型; 影响展示位置
    let type = 'other';
    if (fromWxid === wxId) {
      type = 'self'; // right
    } else if (msgType === 10000 || msgType === 10002) {
      type = 'sys'; // center
    } else {
      type = 'other'; // left
    }

    return (
      <div className={`${type} message-item`} key={mid} data-message-id={mid}>
        {msgType < 10000 &&
          (wxId !== fromWxid ? (
            <img
              alt=""
              className="message-avatar"
              style={{ marginRight: 6 }}
              src={miniHeadImage || '/defaultHead.jpg'}
            />
          ) : (
            <img
              alt=""
              className="message-avatar "
              style={{ marginRight: 6 }}
              src={miniHeadImage || '/defaultHead.jpg'}
            />
          ))}

        <div className="message-wrapper">
          <div>
            {msgType < 10000 ? (
              <div
                className={clx('date', {
                  'self-date': type === 'self',
                })}
              >
                <span style={{ color: '#8C8C8C', fontSize: 13 }}>
                  {fromWxid === wxId ? '' : fromNickname || ''}
                </span>
                &nbsp;&nbsp;{createTime ? formatDate(new Date(createTime).getTime()) : ''}
              </div>
            ) : (
              <div
                className={clx('date', {
                  'self-date': type === 'self',
                })}
              >
                {createTime ? formatDate(new Date(createTime).getTime()) : ''}
              </div>
            )}
            <MessageContent
              isMe={this.props.fromWxid === wxId}
              type={type}
              {...this.props}
              getChatInvitationInfo={this.getChatInvitationInfo}
              handleImgUrl={(url: string, mid1: string) => this.props.handleImgUrl(url, mid1)}
            ></MessageContent>
            <div className={`date ${type}`}>
              {(() => {
                if (source === 2) {
                  return '系统自动回复';
                }
                if (topicReceiverUName) {
                  return `分配给${topicReceiverUName}`;
                }
                if (senderUName) {
                  return `[${senderUName}]发送消息`;
                }
                return '';
              })()}
            </div>
            <div className={`date ${type}`}>
              {(() => {
                if (status === 1) {
                  return '消息已撤回';
                }
                return comment || '';
              })()}
            </div>
            {/* enable_log=true 时添加 id 显示 */}
            {window.location.href.indexOf('enable_log=true') > -1 ? showMsgId : null}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: State) {
  return {
    currentGroupInfo: state.currentGroupInfo,
    target: state.currentTarget,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    getGroupMemberList: (data: any, merge: boolean) =>
      dispatch(createGetGroupMemberListAction(data, merge)),
    syncMessage: (param: any) => dispatch(createSyncSendMessage(param)),
    getSessionList: () => dispatch(createGetWxSessionListAction({ limit: 5000 })),
    setCurrentTarget: (data: Target | null) => dispatch(createSetCurrentTargetAction(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Message);

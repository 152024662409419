/* eslint-disable */
import eventBus from './event';
import { message } from 'antd';
import { getParamValue } from '@/util';
import { WebSocketType } from './types';

let count = 0;

function createWebSocket(url: string, outlock: boolean): WebSocketType | null {
  let lock = false;
  lock = outlock;
  if (lock) return null;
  lock = true;
  const ws = new WebSocket(url);
  setTimeout(() => {
    if (ws.readyState === 1) {
      count = 0;
    }
  }, 2000);
  console.log('------>>>WS 开始建立连接<<<------');

  ws.onmessage = function(ev: any) {
    const REQUEST = new Response(ev.data).text();
    REQUEST.then((response: any) => {
      const res = JSON.parse(response);
      if (getParamValue('enable_log')) {
        console.log('------>>>收到后端推送<<<------');
        console.log(res);
      }
      eventBus.emit('message', {
        ...res,
      });
    });
  };

  ws.onclose = function(this: WebSocket, ev: CloseEvent): any {
    if (ev.code === 4444) return;
    if (count > 10) {
      message.warning('当前网络不稳定, 请刷新页面或稍后重试!');
    }
    console.log('------>>>WS 监听到链接关闭，尝试重新连接<<<------', count);
    setTimeout(() => {
      lock = false;
      createWebSocket(url, lock);
      count += 1;
    }, 500);
  };

  ws.onerror = function(this: WebSocket, ev: Event): any {
    console.error('------>>>WS 监听到错误<<<------', this, ev);
    try {
      (window as any).__bl.api(
        'websocket',
        false,
        new Date().getTime(),
        'FAILED',
        `${(window as any).user}: ${(ev.target as any).url}`,
      );
    } catch (e) {}
  };

  ws.onopen = function(this: WebSocket, ev: Event): any {
    // count = 0
    console.log('------>>>WS 连接建立成功<<<------');
  };

  return {
    send(data: any) {
      if (ws.readyState === ws.OPEN) {
        ws.send(data);
      }
    },
    onMessage(cb: (data: any) => any) {
      eventBus.on('message', cb);
    },
    wsObj: ws,
    lock: lock,
  };
}

export default createWebSocket;

import { BaseAction } from '../types/action';
import { ACTION_SET_CURRENT_TARGET, ACTION_SET_SESSION_INFO } from '../constant';
import { Target, Info } from '../types/state';

export function createSetCurrentTargetAction(data: Target | null): BaseAction {
  return {
    type: ACTION_SET_CURRENT_TARGET,
    data,
  };
}

export function creatSetSessionInfoAction(data: Info | null): BaseAction {
  return {
    type: ACTION_SET_SESSION_INFO,
    data,
  };
}

import React, { memo, useState, useEffect } from 'react';
import { Row, Table, Col, Input, Select, InputNumber, Button, Switch } from 'antd';
import request from '@/http/request';

const DomainModal = memo((props: any) => {
  const [dataList, setDataList] = useState<any[]>([]);
  const [status, setStatus] = useState<number>(0);
  const [domain, setDomain] = useState<string>('');
  const [startTimes, setStartTimes] = useState<number>(null);
  const [endTimes, setEndTimes] = useState<number>(null);
  const [page, setPage] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [load, setLoad] = useState<boolean>(false);

  useEffect(() => {
    getDomainList();
  }, [page]);

  const getDomainList = () => {
    console.log('getDomainList');
    if (load) return;
    setLoad(true);
    const data = {
      roomId: props.roomId,
      domain,
      status,
      minExposure: startTimes,
      maxExposure: endTimes,
      page,
      perpage: 10,
    };
    request.get('/im/v2/domain', data).then(res => {
      setDataList(res.data.list);
      setTotal(res.data.total);
      setLoad(false);
    });
  };

  const switchDomain = (record: any) => {
    request
      .put('/im/v2/domain', {
        domain: record.domain,
        roomId: props.roomId,
        status: record.status === 1 ? 2 : 1,
      })
      .then(() => {
        getDomainList();
      });
  };

  const columns = [
    {
      title: '域名',
      key: 'domain',
      dataIndex: 'domain',
    },
    {
      title: '次数',
      key: 'exposureCount',
      dataIndex: 'exposureCount',
    },
    {
      title: '状态',
      key: 'status',
      dataIndex: 'status',
      render: (value: number) => (value === 1 ? '使用中' : '已下线'),
    },
    {
      title: '操作',
      key: 'operation',
      dataIndex: 'operation',
      render: (text: any, record: any) => (
        <Switch
          checkedChildren="关"
          unCheckedChildren="开"
          checked={record.status === 1}
          onChange={() => {
            switchDomain(record);
          }}
        ></Switch>
      ),
    },
  ];

  const handleSearch = () => {
    if (page === 0) {
      getDomainList();
    } else {
      setPage(0);
    }
  };

  const pagination = {
    current: page + 1,
    total,
    showTotal: () => `共${total} 条`,
    pageSize: 10,
    onChange: (current: any) => {
      setPage(current - 1);
    },
  };

  return (
    <div>
      <Row type="flex" align="middle" gutter={24}>
        <Col>
          域名：
          <Input
            style={{ width: 100 }}
            value={domain}
            onInput={(e: any) => {
              setDomain(e.target.value);
            }}
          ></Input>
        </Col>
        <Col>
          状态：
          <Select
            value={status}
            onChange={(value: number) => {
              setStatus(value);
            }}
            style={{ width: 100 }}
          >
            <Select.Option key={0} value={0}>
              全部
            </Select.Option>
            <Select.Option key={1} value={1}>
              使用中
            </Select.Option>
            <Select.Option key={2} value={2}>
              已下线
            </Select.Option>
          </Select>
        </Col>
        <Col>
          次数：
          <InputNumber
            min={0}
            value={startTimes}
            onChange={(value: number) => {
              setStartTimes(value);
            }}
          ></InputNumber>
          -
          <InputNumber
            min={0}
            value={endTimes}
            onChange={(value: number) => {
              setEndTimes(value);
            }}
          ></InputNumber>
        </Col>
        <Col>
          <Button type="primary" onClick={handleSearch}>
            搜索
          </Button>
        </Col>
      </Row>
      <Table
        pagination={pagination}
        style={{ marginTop: 20 }}
        dataSource={dataList}
        columns={columns}
      ></Table>
    </div>
  );
});

export default DomainModal;

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Icon, Modal, Input, message } from 'antd';
import { State, Target, WxClient, Info } from '@/store/types/state';
import request from '@/http/request';
import { createSetCurrentTargetAction, createCreateWxSessionAction } from '@/store/action';
import './index.less';

import { TARGET_TYPE_SESSION } from '@/store/constant';

const { TextArea } = Input;
const APPLYADDFRIENDAPI = '/v2/group/friend/apply';

interface Props {
  groupId: number;
  memberWxId: number;
  currentClient?: WxClient;
  // 当前账号wxId
  info: Info;
  wxId: number;
  createSession: (chatId: any) => any;
  setCurrentTarget: (data: Target) => any;
}
interface StateInterface {
  userInfo: {
    isFriend: boolean;
    // 群成员对象的wxId
    wxId: number;
    wxAlias: string;
    nickname: string;
    groupRemarkName: string;
    avatar: string;
    sex: number;
    country: string;
    province: string;
    city: string;
  };
  addFriendModalShow: boolean;
  addFriendContent: string;
}

class UserCard extends Component<Props, StateInterface> {
  static defaultProps = {
    groupId: 0,
    memberWxId: 0,
    wxId: 0,
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      userInfo: {
        isFriend: false,
        wxId: 0,
        wxAlias: '',
        nickname: '',
        groupRemarkName: '',
        avatar: '',
        sex: 0,
        country: '',
        province: '',
        city: '',
      },
      addFriendModalShow: false,
      addFriendContent: '',
    };
  }

  componentDidMount() {
    this.getUserInfo();
  }

  applyAddFriendFn = () => {
    const { addFriendContent } = this.state;
    const {
      info: { wxGroup },
      memberWxId,
      currentClient,
    } = this.props;
    request
      .post(APPLYADDFRIENDAPI, {
        targetWxId: memberWxId,
        chatRoomId: wxGroup.groupWxId,
        verifyContent: addFriendContent,
        wxId: currentClient && currentClient.wxId,
      })
      .then((res: any) => {
        if (res.data && !res.data.success) {
          message.error(res.data.message);
        }
        this.switchAddFriendModal();
      });
  };

  addFriendfn = () => {
    this.switchAddFriendModal();
  };

  addFriendContentChange = (content: string) => {
    this.setState({
      addFriendContent: content,
    });
  };

  switchAddFriendModal = () => {
    this.setState((prevState: any) => ({
      addFriendModalShow: !prevState.addFriendModalShow,
    }));
  };

  getUserInfo = () => {
    const {
      info: { wxGroup },
      memberWxId,
      currentClient,
    } = this.props;
    request
      .get(`/im/v2/groups/${wxGroup.groupWxId}/members/${memberWxId}`, {
        wxId: currentClient && currentClient.wxId,
      })
      .then((res: any) => {
        this.setState({
          userInfo: res.data,
        });
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  render() {
    const {
      userInfo: {
        isFriend,
        wxId,
        nickname,
        groupRemarkName,
        avatar,
        sex,
        country,
        province,
        city,
        wxAlias,
      },
      addFriendModalShow,
    } = this.state;
    return (
      <Fragment>
        <Modal
          zIndex={9999}
          title="添加朋友"
          visible={addFriendModalShow}
          onCancel={this.switchAddFriendModal}
          onOk={this.applyAddFriendFn}
        >
          <TextArea
            className="add-friend-input"
            onChange={(e: any) => {
              this.addFriendContentChange(e.target.value);
            }}
          ></TextArea>
          <p className="add-friend-prompt">你需要发送验证请求，对方通过后才能添加其为朋友</p>
        </Modal>
        <div className="user-card">
          <div className="user-card-baseinfo">
            <div className="user-card-baseinfo-left">
              <div>
                <p className="nickname-title">
                  {nickname}
                  {sex === 1 ? (
                    <i
                      style={{ color: '#45b6ef', fontSize: 20, marginLeft: 5 }}
                      className="iconfont"
                    >
                      &#xe636;
                    </i>
                  ) : (
                    <i
                      style={{ color: '#f37e7d', fontSize: 20, marginLeft: 5 }}
                      className="iconfont"
                    >
                      &#xe63a;
                    </i>
                  )}
                </p>
                <div style={{ marginBottom: 12 }}>
                  <strong>微信号：</strong>
                  <p>{wxAlias || wxId || ''}</p>
                </div>
                <strong>该成员在本群的昵称：</strong>
                {groupRemarkName || ''}
              </div>
            </div>
            <img
              className="user-card-baseinfo-avarar"
              src={avatar || '/defaultHead.jpg'}
              alt="head"
            />
          </div>
          <div className="user-card-more">
            <div style={{ marginBottom: 15 }}>
              <strong>备注名：</strong>
              <span className="field-value">{groupRemarkName}</span>
            </div>
            <div>
              <strong>地 区：</strong>
              <span className="field-value">{country + province + city}</span>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state: State) {
  return {
    currentClient: state.clients.current,
    info: state.info,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    setCurrentTarget: (data: Target) => dispatch(createSetCurrentTargetAction(data)),
    createSession: (chatId: string) => dispatch(createCreateWxSessionAction({ chatId })),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserCard);

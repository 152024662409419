import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Layout, Button, Modal, Select, Empty } from 'antd';
import {
  createGetClientListAction,
  createGetWxSessionListAction,
  setCurrentUserAction,
  createSetTabUnReadCountAction,
  setCurrentTabAction,
} from '@/store/action';
import { State, TabUnReadCount, WxSession } from '@/store/types/state';
import request from '@/http/request';
import { getParamValue } from '@/util';

import ChatPanel from './container/ChatPanel';
import './index.less';

const { Option } = Select;

class Lab extends PureComponent<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      sessionIdList: [],
      choosedIdList: [],
      showModal: false,
      lineBreak: false,
    };
  }

  componentDidMount() {
    const ids = getParamValue('ids');
    if (ids) {
      const idList = ids.split(',').map((id: string) => Number(id));
      const width = document.body.offsetWidth / (idList.length || 1);
      this.setState({
        sessionIdList: idList,
        choosedIdList: idList,
        lineBreak: width < 230,
      });
    }
    this.props.getClientList('init');
    this.getCurrUser();
    this.props.setCurrentTab(2);
  }

  initLoading: boolean = true;

  getCurrUser = () => {
    request.get('/v2/current_user').then((res: any) => {
      (window as any).user = res.data.username;
      this.props.setCurrentUser({ id: res.data.id });
    });
  };

  handleClick = () => {
    this.props.getSessionList();
    this.setState({
      showModal: true,
    });
  };

  handleChange = (val: number[]) => {
    this.setState({
      choosedIdList: val,
    });
  };

  renderSessionList = () => {
    const { sessionIdList, lineBreak } = this.state;

    if (!sessionIdList.length) {
      return <Empty className="lab-empty" image={Empty.PRESENTED_IMAGE_SIMPLE} />;
    }

    if (lineBreak) {
      const row1 = sessionIdList.slice(0, sessionIdList.length / 2);
      const row2 = sessionIdList.slice(sessionIdList.length / 2);
      return (
        <div className="line-break-box">
          <div className="session-line-1">
            {row1.map((id: number) => (
              <ChatPanel sessionId={id} key={id} />
            ))}
          </div>
          <div className="session-line-2">
            {row2.map((id: number) => (
              <ChatPanel sessionId={id} key={id} />
            ))}
          </div>
        </div>
      );
    }

    return sessionIdList.map((id: number) => <ChatPanel sessionId={id} key={id} />);
  };

  render() {
    const { showModal, choosedIdList } = this.state;
    const { sessionList } = this.props;
    return (
      <>
        <Layout className="lab">{this.renderSessionList()}</Layout>
        <Button
          className="btn-edit"
          type="default"
          shape="circle"
          icon="menu"
          size="large"
          onClick={this.handleClick}
        />

        <Modal
          className="lab-modal"
          visible={showModal}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              showModal: false,
            });
          }}
          width={760}
          style={{ textAlign: 'center' }}
          centered
          title="选择群聊"
          cancelText="取消"
          okText="确认"
          onOk={() => {
            window.location.href = `${window.origin}/lab?ids=${choosedIdList.join(',')}`;
          }}
        >
          <Select
            mode="multiple"
            size="large"
            placeholder="请选择群聊"
            onChange={this.handleChange}
            style={{ width: '100%' }}
            optionFilterProp="children"
            value={choosedIdList}
          >
            {sessionList.length
              ? sessionList.map((session: WxSession) => (
                  <Option key={session.id} value={session.id}>
                    {session.nickname}
                  </Option>
                ))
              : null}
          </Select>
        </Modal>
      </>
    );
  }
}

function mapDispatchToProps(dispatch: any) {
  return {
    getClientList: (type?: string) => dispatch(createGetClientListAction(type)),
    getSessionList: () => dispatch(createGetWxSessionListAction({ limit: 5000 })),
    setCurrentTab: (data: string) => dispatch(setCurrentTabAction(data)),
    setCurrentUser: (data: any) => dispatch(setCurrentUserAction(data)),
    setTabUnReadCount: (data: TabUnReadCount) => dispatch(createSetTabUnReadCountAction(data)),
  };
}

function mapStateToProps(state: State) {
  return {
    sessionList: state.sessionList,
    clients: state.clients,
    message: state.messageMap,
    currentSiderTab: state.currentSiderTab,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Lab);

import { Info } from '../types/state';
import { BaseAction } from '../types/action';
import { ACTION_SET_SESSION_INFO } from '../constant/index';

export default (state: Info | null = null, action: BaseAction) => {
  switch (action.type) {
    case ACTION_SET_SESSION_INFO:
      return { ...state, ...action.data };
    default:
      return state;
  }
};

import React, { Component } from 'react';
import { List, Tooltip, message, Empty, Tabs, Icon, Layout } from 'antd';
import request from '@/http/request';

const { TabPane } = Tabs;

interface State {
  activeKey: string;
  currMediaIndex: any;
  closeIndex: any;
}

class MaterialLibrary extends Component<any, State> {
  constructor(props: {}) {
    super(props);
    this.state = {
      activeKey: this.props.activeKey || '1',
      currMediaIndex: '',
      closeIndex: '',
    };
  }

  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    if (nextProps.activeKey && nextProps.activeKey !== prevState.activeKey) {
      return {
        activeKey: nextProps.activeKey,
      };
    }
    return {};
  }

  handleGetList = (e: string) => {
    this.setState({
      activeKey: e,
    });
    this.props.getMediaList(e);
  };

  deleteMedia = async (e: any, id: string) => {
    e.stopPropagation();
    await request.delete(`/im/v2/media/${id}`, {}, true);
    this.props.getMediaList(this.state.activeKey);
  };

  render() {
    const { activeKey, currMediaIndex, closeIndex } = this.state;
    const mediaList = (this.props.mediaList && this.props.mediaList.list) || [];
    const list = mediaList.map((item: any) => ({
      id: item.id,
      content: item.content,
      mediaType: item.mediaType,
      contentObject: item.contentObject,
    }));
    const itemDom = (item: any) => {
      if (activeKey === '1' && item.mediaType === 1) {
        // 文本
        return <div className="text-block">{item.content}</div>;
      } else if (activeKey === '2' && (item.mediaType === 3 || item.mediaType === 4)) {
        // 图片
        return (
          <img width="auto" height={82} alt="img" style={{ maxWidth: '90%' }} src={item.content} />
        );
      } else if (activeKey === '3' && item.mediaType === 49 && item.contentObject) {
        // 链接
        const content = item.contentObject;
        return (
          <div className="share-card">
            <div className="share-title">{content.title}</div>
            <div className="share-content">
              <div className="share-text">{content.description}</div>
              <img alt="图片" className="share-img" src={content.mediaUrl} />
            </div>
            <a
              href={content.linkUrl}
              rel="noopener noreferrer"
              target="_blank"
              onClick={(e: any) => {
                e.stopPropagation();
              }}
            >
              查看原文
            </a>
          </div>
        );
      }
      return '';
    };
    return (
      <Layout className="meterial-library">
        <div className="scroll-box" id="scroll-box-4">
          <Tabs
            activeKey={activeKey}
            onChange={this.handleGetList}
            tabBarStyle={{ border: 0, textAlign: 'center', marginBottom: '4px' }}
          >
            {['文本', '图片', '网页'].map((name, idx) => (
              <TabPane key={`${idx + 1}`} tab={<span style={{ fontSize: '14px' }}>{name}</span>}>
                {list.length ? (
                  <List
                    size="small"
                    // bordered
                    dataSource={list}
                    // style={{ borderBottom: '1px solid rgba(38,38,38,0.17)' }}
                    renderItem={(item: any, index) => (
                      <List.Item
                        key={index}
                        style={{
                          background: currMediaIndex === index ? 'rgba(38,38,38,0.07)' : '',
                          borderBottom: '1px solid rgba(38,38,38,0.17)',
                          padding: '12px 16px 12px 8px',
                        }}
                        extra={
                          <Tooltip placement="left" title={activeKey === '1' ? item.content : ''}>
                            <div
                              className="message-block"
                              onClick={() => {
                                this.setState({ currMediaIndex: index });
                                if (!(this.props.target && this.props.target.id)) {
                                  message.error('请选择会话');
                                  return;
                                }
                                this.props.syncMessage({
                                  rawMsg:
                                    item.content &&
                                    item.content.replace(/(^\n*)|(^\r)|(^\r\n)/, ''),
                                  msgType: activeKey === '1' ? 0 : activeKey === '2' ? 1 : 2, // eslint-disable-line
                                });
                              }}
                              onMouseEnter={() => {
                                this.setState({ closeIndex: index });
                              }}
                            >
                              {itemDom(item)}
                              {closeIndex === index ? (
                                <Icon
                                  type="close"
                                  className="close-media"
                                  onClick={(e: any) => this.deleteMedia(e, item.id)}
                                />
                              ) : (
                                ''
                              )}
                            </div>
                          </Tooltip>
                        }
                      ></List.Item>
                    )}
                  />
                ) : (
                  <Empty
                    image="/null.png"
                    description="未设置素材"
                    style={{ color: 'rgba(89, 89, 89, 0.4)', height: 220 }}
                  />
                )}
              </TabPane>
            ))}
          </Tabs>
        </div>
      </Layout>
    );
  }
}

export default MaterialLibrary;

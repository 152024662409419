import React, { PureComponent } from 'react';
import { PageHeader, Icon, message } from 'antd';
import SearchRecord from './Components/SearchRecord';
import './index.less';
import SearchBar from './Components/SearchBar';
import RecordPannel from './Components/RecordPannel';
import AllRecordList from './Components/AllRecordList';
import eventBus from '@/ws/event';

export default class MessageRecords extends PureComponent<any, any> {
  static defaultProps = {
    showSearch: true,
  };

  constructor(props: any) {
    super(props);
    this.state = {
      query: { q: '' },
      showSearch: false,
      showSearchDetail: false,
      page: 1,
      // 是否通过会话区@定位过来的，默认为false
      // 若为会话区@定位过来的，则需要此字段来决定从定位结果页直接返回到聊天记录页，而非中间的搜索结果页
      isAtLocation: false,
    };
  }

  scrollList: any = null;

  componentDidUpdate(prevProps: any) {
    if (prevProps.sessionId !== this.props.sessionId) {
      this.reset();
    }
    // 监听通过@我列表点击进行的聊天记录定位mid值
    if (prevProps.messageRecordLocationMid !== this.props.messageRecordLocationMid) {
      this.goToMessage({ mid: this.props.messageRecordLocationMid });
      this.atMeLocationFn();
    }
  }

  atMeLocationFn = () => {
    this.setState({
      isAtLocation: true,
    });
  };

  reset = () => {
    this.setState({
      showSearch: false,
      showSearchDetail: false,
      query: {},
      page: 1,
      isAtLocation: false,
    });
  };

  OnShowSearch = () => {
    this.setState({
      showSearch: true,
      isAtLocation: false,
    });
  };

  goToMessage = (messageTemp: any) => {
    this.setState(
      {
        showSearch: false,
        showSearchDetail: true,
        mid: messageTemp.mid,
      },
      () => {
        if (this.scrollList) this.scrollList.goToMessage(messageTemp);
      },
    );
  };

  onSearchChange = (query: any) => {
    this.setState({
      query,
    });
  };

  onTimerChange = (startTime: string, endTime: string) => {
    this.setState((perState: any) => {
      const state = perState;
      state.query.startTime = endTime;
      state.query.endTime = startTime;
      return state;
    });
  };

  onPageChange = (page: any) => {
    this.setState({
      page,
    });
  };

  // 搜搜结果详情页的返回控制
  searchResultDetailBack = (showSearch: boolean = true, showSearchDetail: boolean = false) => {
    this.setState({
      showSearch,
      showSearchDetail,
    });
  };

  render() {
    const { mid, showSearch, query, showSearchDetail, page, isAtLocation } = this.state;
    const { sessionId } = this.props;
    if (showSearch) {
      // 搜索结果页面
      return (
        <PageHeader
          onBack={() => {
            this.setState({
              showSearch: false,
              query: {
                q: '',
              },
            });
          }}
          title="搜索结果"
          backIcon={<Icon type="left" style={{ color: '#8C8C8C' }} />}
        >
          <SearchRecord
            query={query}
            page={page}
            {...this.props}
            onClick={this.goToMessage}
            onPageChange={this.onPageChange}
          ></SearchRecord>
        </PageHeader>
      );
    }
    if (showSearchDetail) {
      // 搜索结果详情页面
      return (
        <div className="fixed">
          <PageHeader
            onBack={() => this.searchResultDetailBack(!isAtLocation)}
            title="返回"
            backIcon={<Icon type="left" style={{ color: '#8C8C8C' }} />}
          >
            <AllRecordList
              {...this.props}
              mid={mid}
              ref={(node: any) => {
                this.scrollList = node;
              }}
              canUp
              key={1}
              searchBar={false}
            ></AllRecordList>
          </PageHeader>
        </div>
      );
    }
    return (
      <div>
        <SearchBar
          onSearch={this.OnShowSearch}
          query={query}
          onChange={this.onSearchChange}
        ></SearchBar>
        <RecordPannel {...this.props} key={sessionId}></RecordPannel>
      </div>
    );
  }
}

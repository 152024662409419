import { put, takeEvery, call, all, select } from 'redux-saga/effects';
import request from '@/http/request';
import {
  ACTION_GET_MESSAGE_LIST,
  ACTION_SEND_TEXT_MESSAGE,
  ACTION_SEND_IMAGE_MESSAGE,
  ACTION_SEND_VIDEO_MESSAGE,
  ACTION_SEND_LUCKY_MONEY_MESSAGE,
  ACTION_SEND_LINK_MESSAGE,
} from '../constant';
import {
  createSetWxMessageListAction,
  createMergeWxMessageListAction,
  createTopSingleSessionAction,
  deleteSyncSendMessageAction,
  createUpdateWxMessageListStatus,
} from '../action';
import {
  fetchMessageList,
  fetchSendTextMessage,
  fetchSendImageMessage,
  fetchSendVideoMessage,
  fetchSendLuckMoneyMessage,
  fetchSendCustomLuckMoneyMessage,
  fetchSendLinkMessage,
} from '../../http';
import { State } from '../types/state';

function getLastSuccessMessageId(list: any[]) {
  const len = list.length;
  if (!len) return '';
  // 从后向前遍历，获取非mock的mid（mock数据的mid < 0）
  for (let i = len - 1; i >= 0; i -= 1) {
    const { mid } = list[i];
    if (mid && typeof mid === 'string') {
      return mid;
    }
  }
  return '';
}

// mock数据构建
// param: 发送消息时，组件传递过来的参数
// now: 当前时间定值
// mockType,mock数据类型,0：普通mock数据；1：发送失败的mock数据
function* priorityPreview(param: any, now: any, mockType: number = 0): any {
  const userInfo = yield select((state: State) => ({
    fromWxid: state.clients.current && state.clients.current.wxId,
    fromAvatar: (state.clients.current && state.clients.current.headImg) || '/defaultHead.jpg',
  }));
  let msgContentObj: any = {};
  switch (param.msgType) {
    case 1:
      msgContentObj = { content: param.content };
      break;
    case 3:
      msgContentObj = { imageUrl: param.imgSource };
      break;
    case 49:
      msgContentObj = {
        url: param.linkUrl || '',
        title: param.title,
        desc: param.description,
        imageUrl: param.mediaUrl,
      };
      break;
    default:
      break;
  }
  return {
    clientMsgId: now,
    session: {
      msgTime: new Date(),
      lastMsg: {
        mid: mockType === 0 ? now : -now,
        sessionId: param.sessionId,
        createTime: new Date(),
        msgType: param.msgType,
        msgContent: msgContentObj,
        ...userInfo,
      },
    },
  };
}

export function* getMessageList(action: any) {
  try {
    let start = '';
    const {
      param: { sessionId, isNew, nextIndex, topicId, chatType },
      replace,
    } = action;
    // 如果redux中存有数据，则直接从中读取messageList的数组数据
    const messageList = yield select(state => state.messageMap[sessionId]);
    // 若读取到store中的messageList数据，判断isNew后：
    // 为true：取store中messageList的最新一条消息的mid作为start值，作为拉取从当前start值到最新推送消息的请求参数
    // 为false：取store中messageList的第一条消息的mid作为start值，作为拉取从store的message的mid值到最新推送消息的请求参数
    if (Array.isArray(messageList) && messageList.length > 0) {
      if (isNew) {
        start = getLastSuccessMessageId(messageList);
      }
      if (!isNew && !replace) {
        start = messageList[messageList.length - 1].mid;
      }
    }
    // 获取历史数据
    const getHistoryDataCodition = nextIndex && !isNew && !replace;
    if (getHistoryDataCodition && messageList && messageList.length > 0) {
      start = nextIndex;
    }
    const data = yield call(fetchMessageList, {
      ...action.param,
      start,
      upDownFlag: isNew ? 1 : 0,
    });
    // 获取历史数据时才需要刷新isEnd以及nextIndex的值
    // 否则每次向下拉取新消息时，isEnd总会返回true，这样再向上拉取时由于isEnd和nextIndex是新消息的刷新数据，就会导致出错
    if (!isNew) {
      yield put(
        createUpdateWxMessageListStatus(
          {
            messageIsEnd: data.isEnd,
            messageNextIndex: data.nextIndex,
          },
          sessionId,
        ),
      );
    }

    if (Array.isArray(data.list) && data.list.length > 0) {
      if (replace) {
        yield put(createSetWxMessageListAction(data.list, sessionId, topicId, chatType));
      } else {
        yield put(
          createMergeWxMessageListAction(
            data.list,
            sessionId,
            isNew,
            false,
            false,
            topicId,
            chatType,
          ),
        );
      }
    }
  } catch (error) {
    console.error(error);
  }
}

// 消息发送
export function* sendMessage(action: any) {
  // const now = Date.now();
  const now = Math.floor(Math.random() * 100 + 1) * 10000000000000 + new Date().getTime();
  const { param } = action;
  const mockSessionItem = yield priorityPreview(param, now);
  const failMockSessionitem = yield priorityPreview(param, now, 1);

  // 先推送mock数据，而后当真实数据返回后再进行替换
  yield put(createMergeWxMessageListAction(mockSessionItem, param.sessionId, true));

  // 加上对应mock数据对应的标记发送
  param.clientMsgId = now;
  let resData: any;
  // 消息发送
  if (param.msgType === 1) {
    try {
      resData = yield call(fetchSendTextMessage, param);
      if (param.recommendInfo) {
        const {
          recommendInfo: { couponAmount, payPrice, source, itemId },
        } = param;
        const { mid, sessionId, topicId } = resData.data.session.lastMsg;
        request.post('/im/v2/commodity/records', {
          couponAmount: parseFloat(couponAmount) * 100,
          payPrice: parseFloat(payPrice) * 100,
          source,
          itemId,
          name: param.recommendInfo.title,
          mid,
          sessionId,
          topicId,
        });
      }
      try {
        (window as any).__bl.api(
          'response_text',
          false,
          new Date().getTime(),
          200,
          JSON.stringify(resData),
        );
      } catch (e) {
        console.log(e);
      }
    } catch (e) {
      yield put(createMergeWxMessageListAction(failMockSessionitem, param.sessionId, true, true));
    }
  } else if (param.msgType === 3) {
    try {
      resData = yield call(fetchSendImageMessage, param);
      try {
        (window as any).__bl.api(
          'response_image',
          false,
          new Date().getTime(),
          200,
          JSON.stringify(resData),
        );
      } catch (e) {
        console.log(e);
      }
    } catch (e) {
      yield put(createMergeWxMessageListAction(failMockSessionitem, param.sessionId, true, true));
    }
  } else if (param.msgType === 43) {
    // 视频消息上屏
    try {
      resData = yield call(fetchSendVideoMessage, param);
      try {
        (window as any).__bl.api(
          'response_image',
          false,
          new Date().getTime(),
          200,
          JSON.stringify(resData),
        );
      } catch (e) {
        console.log(e);
      }
    } catch (e) {
      yield put(createMergeWxMessageListAction(failMockSessionitem, param.sessionId, true, true));
    }
  } else if (param.msgType === 49) {
    let method = fetchSendLinkMessage;
    if (param.hasOwnProperty('redPackId')) {
      method = param.redPackId === -1 ? fetchSendCustomLuckMoneyMessage : fetchSendLuckMoneyMessage;
    }
    try {
      resData = yield call(method, param);
      if (resData && param.event) param.event(); // 避免快速点击发多次请求，在回调里用flag控制
    } catch (e) {
      yield put(createMergeWxMessageListAction(failMockSessionitem, param.sessionId, true, true));
    }
  }
  if (!resData) return;
  const {
    data,
    data: { session },
  } = resData;

  // 若账户离线或特殊情况发送失败，则将错误提示的mock数据推送覆盖
  // switch (code) {
  //   case 400:
  //   case 1104:
  //   case 1105:
  //     yield put(createMergeWxMessageListAction(failMockSessionitem, param.sessionId, true, true));
  //     return;
  //   default:
  //     break;
  // }

  // 将获取到的真实数据替换mock上屏数据
  yield put(createMergeWxMessageListAction(data, param.sessionId, true, true));
  // 自己发的消息没有websocket的推送通知，不会触发流程置顶，因此需要手动触发
  yield put(createTopSingleSessionAction([session]));
  // 清除快捷回复内存消息
  yield put(deleteSyncSendMessageAction());
}

export function* watchGetMessageList() {
  yield takeEvery(ACTION_GET_MESSAGE_LIST, getMessageList);
}

export function* watchSendTextMessage() {
  yield takeEvery(ACTION_SEND_TEXT_MESSAGE, sendMessage);
}

export function* watchSendImageMessage() {
  yield takeEvery(ACTION_SEND_IMAGE_MESSAGE, sendMessage);
}

export function* watchSendVideoMessage() {
  yield takeEvery(ACTION_SEND_VIDEO_MESSAGE, sendMessage);
}

export function* watchSendLuckMoneyMessage() {
  yield takeEvery(ACTION_SEND_LUCKY_MONEY_MESSAGE, sendMessage);
}

export function* watchSendLinkMessage() {
  yield takeEvery(ACTION_SEND_LINK_MESSAGE, sendMessage);
}

export function* watchMessage() {
  yield all([
    watchGetMessageList(),
    watchSendTextMessage(),
    watchSendImageMessage(),
    watchSendVideoMessage(),
    watchSendLuckMoneyMessage(),
    watchSendLinkMessage(),
  ]);
}

import React, { memo, useState } from 'react';
import './index.less';

interface IProps {
  resizeOrientation: 'left' | 'right';
  minWidth: number;
  maxWidth: number;
  children: React.ReactNode;
}

const DragSider: any = memo((props: IProps) => {
  const minWidth = props.minWidth || 200;
  const maxWidth = props.maxWidth || 304;

  const [navWidth, setNavWidth] = useState((maxWidth + minWidth) / 2);

  const mouseDown = (dE: any) => {
    const startX = dE.clientX;
    const resizeMove = (mE: MouseEvent) => {
      const endX = mE.clientX;
      let moveLen = 0;
      if (props.resizeOrientation === 'left') {
        moveLen = navWidth - (endX - startX);
      } else {
        moveLen = navWidth + (endX - startX);
      }
      if (moveLen < minWidth) {
        moveLen = minWidth;
      }
      if (moveLen > maxWidth) {
        moveLen = maxWidth;
      }
      setNavWidth(moveLen);
    };

    const resizeUp = () => {
      document.removeEventListener('mousemove', resizeMove);
      document.removeEventListener('mouseup', resizeUp);
    };

    document.addEventListener('mousemove', resizeMove);
    document.addEventListener('mouseup', resizeUp);
  };

  const paddLeft = {
    paddingLeft: '4px',
  };

  const paddRight = {
    paddingRight: '4px',
  };

  return (
    <div style={{ width: `${navWidth}px`, height: '100%', position: 'relative' }}>
      <div
        onMouseDown={mouseDown}
        className={`${props.resizeOrientation === 'left' ? 'resize-left' : 'resize-right'}`}
      />

      <div style={props.resizeOrientation === 'left' ? paddLeft : paddRight}>{props.children}</div>
    </div>
  );
});

export default DragSider;

import { put, takeEvery, call, all } from 'redux-saga/effects';
import { TARGET_TYPE_SESSION } from '@/store/constant';
import store from '@/store';
import eventBus from '@/ws/event';
import { ACTION_GET_SESSION_LIST, ACTION_CREATE_SESSION } from '../constant';
import {
  createSetWxSessionListAction,
  createSetCurrentTargetAction,
  setCurrentTabAction,
  createTopSingleSessionAction,
  createGetWxSessionListAction,
  createSetTabUnReadCountAction,
} from '../action';
import { fetchSessionList, fetchCreateSession } from '../../http';

export function* getSessionList(action: any) {
  const {
    currentSiderTab,
    currentSessionListFilterCondition,
    clients: {
      current: { wxId: currClientWxId },
    },
  } = store.getState();
  try {
    const {
      data: { list, undeliveredCount },
    } = yield call(fetchSessionList, {
      limit: 5000,
      ...action.param,
      type: currentSiderTab,
      divisionId: currentSessionListFilterCondition.divisionId,
      focus: currentSessionListFilterCondition.focus,
    });
    if (Array.isArray(list)) {
      let filterList = list;
      // 针对账号进行会话列表缓存
      if (parseInt(currentSessionListFilterCondition.focus, 10) > 0 && currentSiderTab === '2') {
        switch (parseInt(currentSessionListFilterCondition.focus, 10)) {
          case 1:
            filterList = list.filter(item => item.newUnread > 0);
            break;
          case 5:
            filterList = list.filter(item => item.undelivered > 0);
            break;
          default:
            break;
        }
      }
      localStorage.setItem(
        `${currClientWxId}-sessionList`,
        JSON.stringify(filterList.slice(0, 20)),
      );
      yield put(createSetWxSessionListAction(filterList));
      if (currentSiderTab === '2') {
        yield put(createSetTabUnReadCountAction({ undeliveredCount }));
      }
      yield () => eventBus.emit('updateSessionList');
    }
  } catch (error) {
    console.error(error);
  }
}

export function* createSession(action: any) {
  const {
    currentSiderTab,
    clients: {
      current: { wxId: currClientWxId },
    },
  } = store.getState();
  try {
    const { data } = yield call(fetchCreateSession, { ...action.param, wxId: currClientWxId });
    const { id: sessionId } = data;
    // 若当前处于“群聊” 筛选条件下，切要新建一个单聊，则将筛选切换回”全部“
    // 目的是避免列表区-会话目标没有与当前会话对象处于同步显示
    yield put(
      createGetWxSessionListAction({
        limit: 5000,
        type: currentSiderTab,
      }),
    );
    yield put(
      createSetCurrentTargetAction({
        ...data,
        id: sessionId,
        type: TARGET_TYPE_SESSION,
      }),
    );
    yield put(setCurrentTabAction('1'));
    // 新建的会话需要移动到顶部
    yield put(createTopSingleSessionAction({ ...data }));
    yield () => eventBus.emit('updateSessionList');
  } catch (error) {
    console.error(error);
  }
}

export function* watchGetSessionList() {
  yield takeEvery(ACTION_GET_SESSION_LIST, getSessionList);
}

export function* watchCreateSession() {
  yield takeEvery(ACTION_CREATE_SESSION, createSession);
}

export function* watchSession() {
  yield all([watchCreateSession(), watchGetSessionList()]);
}

import React from 'react';
import { connect } from 'react-redux';
import { Dropdown, Menu, Popconfirm, Popover, Button, message } from 'antd';
import clx from 'classnames';
import { env, formatDate } from '@/util';
import request from '@/http/request';
import UserCard from '@/components/UserCard';
import ShowModal from '@/components/ShowModal';
import {
  createGetGroupMemberListAction,
  createSyncSendMessage,
  createGetWxSessionListAction,
  createSetCurrentTargetAction,
  createGetWxMessageListAction,
} from '@/store/action';
import { State, Target } from '@/store/types/state';
import BlackPreviewModal from '@/components/BlackPreviewModal';
import DeleteBlackModal from '@/components/DeleteBlackModal';
import RelationChainModel from '@/components/RelationChainModel';
import MessageContent from './MessageContent';

const ChatInvitationPreviewModal = (props: any, wxId: string, fromWxid: string) => {
  const ChatInvitationPreview = () => {
    const { status } = props;
    return (
      <div className="chat-invitation">
        {status === 4 ? (
          ''
        ) : (
          <>
            <img alt="头像" className="chat-invitation-avatar" src={props.groupAvatar} />
            <div className="chat-invitation-member">{props.memberCount}</div>
            <div className="chat-invitation-message">{props.message}</div>
          </>
        )}
        {(() => {
          if (fromWxid === wxId) {
            // 自己发出的邀请
            return <div style={{ fontSize: '20px' }}>该邀请已发送</div>;
          }
          switch (status) {
            case 0: // 页面可用，未接受邀请
            case 2: // 页面可用，未实名
            case 3: // 页面可用，未实名
              return (
                <>
                  <Button
                    ghost
                    type="primary"
                    disabled={status === 2 || status === 3}
                    style={{ position: 'relative' }}
                    onClick={() => {
                      request
                        .post('/im/v2/group/invitation/accept', {
                          wxId,
                          url: props.acceptUrl,
                        })
                        .then(() => {
                          ShowModal.close();
                        });
                    }}
                  >
                    {status === 2 || status === 3 ? <div className="mask-btn"></div> : ''}
                    加入群聊
                  </Button>
                  <div className="chat-invitation-info">
                    {props.extraInfos &&
                      props.extraInfos.length &&
                      props.extraInfos.map((text: string, index: number) => (
                        <p key={index}>{`${index + 1}、${text}`}</p>
                      ))}
                  </div>
                </>
              );
            case 1: // 页面可用，已接受邀请
              return <div style={{ color: '#389E0D', fontSize: '20px' }}>你已接受邀请</div>;
            case 4: // 页面不可用，已失效
              return (
                <>
                  <i style={{ color: '#FF1A2E', fontSize: 80 }} className="iconfont">
                    &#xe632;
                  </i>
                  <p style={{ fontSize: '20px' }}>该邀请已失效</p>
                </>
              );
            default:
              return '';
          }
        })()}
      </div>
    );
  };
  ShowModal({
    width: 500,
    bodyStyle: {
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'center',
      height: '504px',
    },
    destroyOnClose: 'destroyOnClose',
    hasFooter: false,
    childrens: [ChatInvitationPreview],
  });
};

// 删除群成员
const DeleteQunMemberItem = (props: any) => {
  const { itemData } = props;
  const onRemoveFromGroup = () => {
    request
      .post('/v1/group/member/remove', {
        group_id: itemData.toWxid,
        member_list: itemData.fromWxid,
        from_wx_id: itemData.wxId,
        mid: itemData.mid,
      })
      .then(() => {
        if (itemData.target.chatType === 3) {
          const id = itemData.target.chatId && itemData.target.chatId.split('@topic')[0];
          request.delete(`/im/v2/topics/${id}`).then(() => {
            itemData.getSessionList();
            itemData.setCurrentTarget(null);
          });
        } else {
          setTimeout(
            itemData.getGroupMemberList(
              {
                groupId: itemData.target.chatId,
                start: 0,
                count: 1000,
              },
              false,
            ),
            1500,
          );
        }
      });
  };

  return (
    <Popconfirm
      title={`确认删除${itemData.fromNickname}？`}
      okText="确认"
      cancelText="取消"
      onConfirm={() => onRemoveFromGroup()}
    >
      <div>移除该成员</div>
    </Popconfirm>
  );
};

// 加入黑名单
const AddToBlackItem = (props: any) => {
  const { itemData } = props;
  const addToBlackList = () => {
    if (itemData.target.chatType === 3) {
      const id = itemData.target.chatId && itemData.target.chatId.split('@topic')[0];
      request.delete(`/im/v2/topics/${id}`).then(() => {
        itemData.getSessionList();
        itemData.setCurrentTarget(null);
      });
    } else {
      setTimeout(() => {
        const param = {
          chatType: 3,
          topicId: itemData.target.chatId,
          limit: 30,
          isNew: false,
          sessionId: itemData.target.id,
          nextIndex: '',
        };
        itemData.getGroupMemberList(
          {
            groupId: itemData.target.chatId,
            start: 0,
            count: 1000,
          },
          false,
        );
        itemData.getMessageList(param, true);
      }, 1500);
    }
  };

  const removeFromBlackList = () => {
    setTimeout(() => {
      const param = {
        chatType: 3,
        topicId: itemData.target.chatId,
        limit: 30,
        isNew: false,
        sessionId: itemData.target.id,
        nextIndex: '',
      };
      itemData.getGroupMemberList(
        {
          groupId: itemData.target.chatId,
          start: 0,
          count: 1000,
        },
        false,
      );
      itemData.getMessageList(param, true);
    }, 1500);
  };

  return (
    <>
      {itemData.fromBlackUser ? (
        <div
          onClick={() =>
            DeleteBlackModal({ ...itemData, onOk: removeFromBlackList, wxId: itemData.fromWxid })
          }
        >
          移除黑名单
        </div>
      ) : (
        <div
          onClick={() =>
            BlackPreviewModal({ ...itemData, onOk: addToBlackList, wxId: itemData.fromWxid })
          }
        >
          加入黑名单
        </div>
      )}
    </>
  );
};

class Message extends React.Component<any> {
  handleAt = () => {
    const { fromNickname, fromWxid } = this.props;
    this.props.syncMessage({
      rawMsg: `\u200b@${fromNickname}\u200c`,
      editorValue: ` @[${fromNickname}](${fromWxid}) `,
      newPlainTextValue: ` \u200b@${fromNickname}\u200c `,
      msgType: 0,
      stamp: new Date().getTime(),
      atList: [{ id: fromWxid, display: `\u200b@${fromNickname}\u200c`, childIndex: 0 }],
    });
  };

  // 跳转到个人号聊天对应会话窗口
  openStandardIm = () => {
    const { fromWxid } = this.props;
    const { chatId } = this.props.target;

    const currentUser = sessionStorage.getItem('currentUser');

    request.get(`/im/v2/accounts/${fromWxid}/info`).then((res: any) => {
      const { userId } = res.data;
      if (userId === +currentUser) {
        window.open(
          `//${window.location.host.indexOf('wework') > -1 ? 'bee.wework' : 'bee'}.${env &&
            `${env}.`}yipiandian.com?wxId=${fromWxid}&chatId=${chatId}`,
        );
      } else {
        message.warn('该账户不在你名下，分配到你名下后再操作');
      }
    });
  };

  MenuItemList = (props: any, menuItem: number[]) => {
    return (
      <Menu selectable={false}>
        {menuItem.indexOf(3) !== -1 ? (
          <Menu.Item key="3">
            <div onClick={this.handleAt}>@{props.fromNickname}</div>
          </Menu.Item>
        ) : null}
        {menuItem.indexOf(1) !== -1 ? (
          <Menu.Item key="1">
            <DeleteQunMemberItem itemData={props} />
          </Menu.Item>
        ) : null}
        {menuItem.indexOf(2) !== -1 ? (
          <Menu.Item key="2">
            <AddToBlackItem itemData={props} />
          </Menu.Item>
        ) : null}
        {menuItem.indexOf(4) !== -1 ? (
          <Menu.Item key="4">
            <div
              onClick={() => RelationChainModel({ fromWxid: props.fromWxid, toWxid: props.toWxid })}
            >
              查看关系链
            </div>
          </Menu.Item>
        ) : null}
        {menuItem.indexOf(5) !== -1 ? (
          <Menu.Item
            key="5"
            onClick={() => {
              this.openStandardIm();
            }}
          >
            <div>个人号聊天</div>
          </Menu.Item>
        ) : null}
      </Menu>
    );
  };

  // 头像右键菜单
  DropdownMenu = () => {
    const {
      target: { isSingle },
      wxId,
      fromWxid,
      currentGroupInfo,
    } = this.props;

    const isQunMasterAndNoMe =
      !isSingle &&
      currentGroupInfo &&
      currentGroupInfo.groupMember &&
      currentGroupInfo.groupMember.role > 0 &&
      wxId !== fromWxid;

    if (isSingle) {
      return this.MenuItemList(this.props, [1, 2, 4, 5]);
    }
    if (isQunMasterAndNoMe) {
      return this.MenuItemList(this.props, [1, 2, 3, 4, 5]);
    }
    return this.MenuItemList(this.props, [1, 2, 3, 4, 5]);
  };

  getChatInvitationInfo = (url: any) => {
    request
      .get('/im/v2/group/invitation', {
        wxId: this.props.wxId,
        url,
      })
      .then((res: any) => {
        ChatInvitationPreviewModal(res.data, this.props.wxId, this.props.fromWxid);
      });
  };

  render() {
    const {
      fromAvatar = '',
      wxId,
      msgType,
      fromWxid,
      currentGroupInfo,
      mid,
      fromNickname,
      createTime,
      status,
      comment,
      source,
      topicReceiverUName,
      senderUName,
      fromBlackUser,
    } = this.props;
    const size = fromAvatar.indexOf('wework') > -1 ? 100 : 132;
    const miniHeadImage =
      fromAvatar && fromAvatar.substr(-2) === '/0'
        ? `${fromAvatar.substr(0, fromAvatar.length - 1)}${size}`
        : fromAvatar;
    const showMsgId = <div style={{ color: '#eee' }}>[{mid}]</div>;

    // 判断 message 类型; 影响展示位置
    let type = 'other';
    if (fromWxid === wxId) {
      type = 'self'; // right
    } else if (msgType === 10000 || msgType === 10002) {
      type = 'sys'; // center
    } else {
      type = 'other'; // left
    }

    return (
      <div className={`${type} message-item`} key={mid} data-message-id={mid}>
        {msgType < 10000 &&
          (wxId !== fromWxid ? (
            <Dropdown overlay={this.DropdownMenu()} trigger={['contextMenu']}>
              <Popover
                key={currentGroupInfo && currentGroupInfo.group && currentGroupInfo.group.groupWxId}
                placement="rightBottom"
                trigger="click"
                content={
                  <UserCard
                    groupId={
                      currentGroupInfo && currentGroupInfo.group && currentGroupInfo.group.groupWxId
                    }
                    memberWxId={fromWxid}
                    wxId={wxId}
                  ></UserCard>
                }
              >
                <img
                  alt=""
                  className="message-avatar"
                  style={{ marginRight: 6 }}
                  src={miniHeadImage || '/defaultHead.jpg'}
                />
              </Popover>
            </Dropdown>
          ) : (
            <Dropdown overlay={this.MenuItemList(this.props, [5])} trigger={['contextMenu']}>
              <img
                alt=""
                className="message-avatar"
                style={{ marginRight: 6 }}
                src={miniHeadImage || '/defaultHead.jpg'}
              />
            </Dropdown>
          ))}

        <div className="message-wrapper">
          <div>
            {msgType < 10000 ? (
              <div
                className={clx('date', {
                  'self-date': type === 'self',
                })}
              >
                <span style={{ color: '#8C8C8C', fontSize: 13 }}>
                  {fromWxid === wxId ? '' : fromNickname || ''}
                </span>
                {fromBlackUser ? (
                  <span style={{ color: '#ff5500' }}>&nbsp;&nbsp;(黑名单)</span>
                ) : null}
                &nbsp;&nbsp;{createTime ? formatDate(new Date(createTime).getTime()) : ''}
              </div>
            ) : (
              <div
                className={clx('date', {
                  'self-date': type === 'self',
                })}
              >
                {createTime ? formatDate(new Date(createTime).getTime()) : ''}
              </div>
            )}
            <MessageContent
              isMe={this.props.fromWxid === wxId}
              type={type}
              {...this.props}
              getChatInvitationInfo={this.getChatInvitationInfo}
              handleImgUrl={(url: string, mid1: string) => this.props.handleImgUrl(url, mid1)}
            ></MessageContent>
            <div className={`date ${type}`}>
              {(() => {
                if (source === 2) {
                  return '系统自动回复';
                }
                if (topicReceiverUName) {
                  return `分配给${topicReceiverUName}`;
                }
                if (senderUName) {
                  return `[${senderUName}]发送消息`;
                }
                return '';
              })()}
            </div>
            <div className={`date ${type}`}>
              {(() => {
                if (status === 1) {
                  return '消息已撤回';
                }
                return comment || '';
              })()}
            </div>
            {/* enable_log=true 时添加 id 显示 */}
            {window.location.href.indexOf('enable_log=true') > -1 ? showMsgId : null}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: State) {
  return {
    currentGroupInfo: state.currentGroupInfo,
    target: state.currentTarget,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    getGroupMemberList: (data: any, merge: boolean) =>
      dispatch(createGetGroupMemberListAction(data, merge)),
    syncMessage: (param: any) => dispatch(createSyncSendMessage(param)),
    getSessionList: () => dispatch(createGetWxSessionListAction({ limit: 5000 })),
    setCurrentTarget: (data: Target | null) => dispatch(createSetCurrentTargetAction(data)),
    getMessageList: (param: any, replace: boolean) =>
      dispatch(createGetWxMessageListAction(param, replace)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Message);

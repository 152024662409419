import React from 'react';
import { Button, message, Upload, Icon, Switch } from 'antd';
import './index.less';
import { connect } from 'react-redux';
import {
  createSendTextMessageAction,
  createSendImageMessageAction,
  createSendLuckyMoneyMessageAction,
  createSendLinkMessageAction,
  createSendVideoMessageAction,
  createGetWxSessionListAction,
  createSetCurrentTargetAction,
  creatSetSessionInfoAction,
} from '@/store/action';
import { State, Target, Info } from '@/store/types/state';
import request from '@/http/request';
import eventBus from '@/ws/event';
import IconFont from '@/components/IconFont';
import ChatSub from './ChatSub';

interface Prop {
  m1: any;
  m2: any;
  target: Target;
  info: Info;
  current: any;
  sessionList: any;
  sendTextMsg: (param: any) => any;
  sendImageMsg: (param: any) => any;
  sendVideoMsg: (param: any) => any;
  sendLuckMoneyMsg: (param: any) => any;
  sendLinkMsg: (param: any) => any;
  getSessionList: () => void;
  setCurrentTarget: (data: Target | null) => any;
  setSessionInfo: (data: any) => any;
}

interface IState {
  windControl: number;
}

class ChatBox extends React.Component<Prop, IState> {
  state = {
    windControl: 0,
  };

  child: any;

  atMeItemClick = (item: any) => {
    // 定位到聊天记录
    eventBus.emit('messageRecordLocation', item.mid);
  };

  handlerFileChange = (e: any) => {
    const RULE = /image/gi;
    if (!RULE.test(e.file.type)) {
      message.warning('仅支持发送图片');
      return;
    }
    const URLObj = window.URL;
    const source = URLObj && URLObj.createObjectURL(e.file);
    this.handlerSendImage(e.file, source);
  };

  handlerVideoFileChange = (e: any) => {
    const URLObj = window.URL;
    const source = URLObj && URLObj.createObjectURL(e.file);
    this.handlerSendVideo(e.file, source);
  };

  handlerSendImage = (file: any, source?: any) => {
    if (typeof file === 'undefined') return;
    // 所有发送图片都不需要 @成员
    // if (!notAt) {
    //   this.handlerSendText('');
    // }
    if (!file || typeof file === 'string') {
      this.props.sendImageMsg({
        sessionId: this.props.target.id,
        imageUrl: source || '',
        imgSource: source || '',
        msgType: 3,
      });
    } else {
      const FILE = new FormData();
      FILE.append('content-type', 'multipart/form-data');
      FILE.append('file', file, file.name);
      this.props.sendImageMsg({
        sessionId: this.props.target.id,
        imgName: file.name,
        imgData: FILE,
        imgSource: source || '',
        msgType: 3,
      });
    }
    // this.offlineNotification();
  };

  handlerSendVideo = (file: any, source?: any) => {
    if (typeof file === 'undefined') return;
    if (!file || typeof file === 'string') {
      this.props.sendVideoMsg({
        sessionId: this.props.target.id,
        videoUrl: source || '',
        videoSource: source || '',
        msgType: 43,
      });
    } else {
      const FILE = new FormData();
      FILE.append('content-type', 'multipart/form-data');
      FILE.append('file', file, file.name);
      this.props.sendVideoMsg({
        sessionId: this.props.target.id,
        videoName: file.name,
        videoData: FILE,
        videoSource: source || '',
        msgType: 43,
      });
    }
  };

  handlerSendText = (m: any, mentions?: any[]) => {
    const { target, info } = this.props;
    let newMentions = mentions;
    let newM = m;
    if (target.chatType === 3 && info && info.showAite) {
      newMentions = [
        {
          childIndex: 0,
          display: info.topic.invokerNickname,
          id: info.topic.invokeWxId,
          index: 0,
          plainTextIndex: 0,
        },
      ];
      newM = `\u200b@${newMentions[0].display}\u200c ${m}`;
    }
    this.props.sendTextMsg({
      sessionId: this.props.target.id,
      content: newM,
      atList: newMentions && newMentions.length > 0 ? newMentions.map((item: any) => item.id) : [],
      msgType: 1,
      convertAnti: this.state.windControl,
    });
    this.setState({
      windControl: 0,
    });
    // this.offlineNotification();
  };

  handleSendLuckMoney = (redPackId: number, amount: number, event: any) => {
    this.props.sendLuckMoneyMsg({
      sessionId: this.props.target.id,
      redPackId,
      amount,
      msgType: 49,
      imageUrl: 'https://hibaru.oss-cn-hangzhou.aliyuncs.com/static/img/红包利是.png',
      title: '点我领红包',
      desc: '我已等候多时，还不把我领走~',
      event,
    });
  };

  handlerSendLink = (linkSourceObj: any) => {
    this.props.sendLinkMsg({
      sessionId: this.props.target.id,
      linkUrl: linkSourceObj.linkUrl,
      mediaUrl: linkSourceObj.mediaUrl,
      title: linkSourceObj.title,
      description: linkSourceObj.description,
      msgType: 49,
    });
  };

  offlineNotification = () => {
    if (!this.props.current.statusOnline) {
      message.error('当前帐号已离线');
    }
  };

  setRef = (ref: any) => {
    this.child = ref;
  };

  closeMessage = () => {
    const {
      target,
      info: { topic },
      sessionList,
    } = this.props;
    const id = topic.id || (target.chatId && target.chatId.split('@topic')[0]);
    request.delete(`/im/v2/topics/${id}`).then(() => {
      const index = sessionList && sessionList.findIndex((node: any) => node.id === target.id);
      if (index < sessionList.length - 1) {
        eventBus.emit('handlerChatItemClick', sessionList[index + 1]);
        // window.history.replaceState(
        //   {},
        //   '',
        //   `topic_${convertNumMap(`${sessionList[index + 1].id}`)}`,
        // );
      } else {
        this.props.setCurrentTarget(null);
        // window.history.replaceState({}, '', 'null');
      }
      this.props.getSessionList();
    });
  };

  switchReply = () => {
    const {
      info: { showAite },
    } = this.props;
    this.props.setSessionInfo({
      ...this.props.info,
      showAite: !showAite,
    });
  };

  render() {
    const leftDomProps = {
      name: 'file',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onChange: this.handlerFileChange,
      beforeUpload: () => {
        return false;
      },
      accept: '.png, .jpg, .jpeg, .gif',
    };
    const videoProps = {
      name: 'file',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onChange: this.handlerVideoFileChange,
      beforeUpload: () => {
        return false;
      },
      accept: '.mp4',
    };
    const leftDom = (
      <>
        <Upload {...leftDomProps}>
          <IconFont
            style={{ fontSize: 20, marginLeft: 5, cursor: 'poiner' }}
            iconfont="icontupian"
          ></IconFont>
        </Upload>
        <Upload {...videoProps}>
          <Icon type="video-camera" style={{ fontSize: 20, marginLeft: 10, cursor: 'poiner' }} />
        </Upload>
      </>
    );
    const bottomDom = (
      <div className="chat-pannel-bottom">
        {this.props.target.chatType === 3 ? (
          <Button size="large" className="send-btn-default" onClick={this.closeMessage}>
            结束会话
          </Button>
        ) : (
          ''
        )}
        <Switch
          checkedChildren="关"
          unCheckedChildren="开"
          checked={!!this.state.windControl}
          onChange={() => {
            this.setState(preState => {
              const status = preState.windControl === 1 ? 0 : 1;
              return { windControl: status };
            });
          }}
        ></Switch>
        风控
        <Button
          size="large"
          type="primary"
          ghost
          className="send-btn-default"
          onClick={() => {
            this.child.handlerSendBtnClick();
          }}
        >
          发送
        </Button>
        {/* <Button onClick={() => {
          console.log('红包')
          this.props.sendLuckMoneyMsg({
            sessionId: this.props.target.id,
            msgType: 49,
          })
        }}>红包</Button> */}
      </div>
    );
    const { target, info } = this.props;
    return (
      <>
        {target.chatType === 3 && info ? (
          <div className="qun-single-tips">
            <span style={{ color: '#1890ff' }}>
              {info.showAite ? `@${info.topic.invokerNickname}` : ''}
              <span style={{ color: '#aaa' }}> 发送以下信息到 </span>
              <span style={{ color: '#000' }}> {info.wxGroup.groupName}</span>
            </span>
            <Button
              size="small"
              type="primary"
              ghost
              className="send-btn-default"
              style={{ float: 'right', height: '20px', lineHeight: '20px' }}
              onClick={this.switchReply}
            >
              {info.showAite ? '转为群回复' : '转为@Ta'}
            </Button>
          </div>
        ) : (
          ''
        )}
        <ChatSub
          leftHeader={leftDom}
          bottomBlock={bottomDom}
          sendImage={this.handlerSendImage}
          sendText={this.handlerSendText}
          sendLink={this.handlerSendLink}
          sendLuckMoney={this.handleSendLuckMoney}
          onRef={this.setRef}
          target={this.props.target}
        ></ChatSub>
      </>
    );
  }
}

function mapStateToProps(state: State) {
  return {
    target: state.currentTarget,
    current: state.clients && state.clients.current,
    sessionList: state.sessionList,
    info: state.info,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    sendTextMsg: (param: any) => {
      dispatch(createSendTextMessageAction(param));
    },
    sendImageMsg: (param: any) => {
      dispatch(createSendImageMessageAction(param));
    },
    sendVideoMsg: (param: any) => {
      dispatch(createSendVideoMessageAction(param));
    },
    sendLuckMoneyMsg: (param: any) => {
      dispatch(createSendLuckyMoneyMessageAction(param));
    },
    sendLinkMsg: (param: any) => {
      dispatch(createSendLinkMessageAction(param));
    },
    getSessionList: () => dispatch(createGetWxSessionListAction({ limit: 5000 })),
    setCurrentTarget: (data: Target | null) => dispatch(createSetCurrentTargetAction(data)),
    setSessionInfo: (data: Info | null) => dispatch(creatSetSessionInfoAction(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatBox);

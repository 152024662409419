import { Target, WxSession } from '@/store/types/state';

export function setDraftToLocal(sessionId?: number, value?: string) {
  try {
    let notSendList: any[] = JSON.parse(sessionStorage.getItem('notSendList') || '[]');
    let isInsert = true;
    let targetIndex = -1;
    notSendList = notSendList.map((item: any, index: number) => {
      const ele = item;
      if (ele.id === sessionId) {
        isInsert = false;
        targetIndex = index;
        ele.draftContent = value;
      }
      return ele;
    });

    if (isInsert && value) {
      notSendList.push({
        id: sessionId,
        draftContent: value,
      });
    } else if (!value && targetIndex >= 0) {
      notSendList.splice(targetIndex, 1);
    }
    sessionStorage.setItem('notSendList', JSON.stringify(notSendList));
  } catch (error) {
    console.log(error);
  }
}

export function getLocalDraftBySessionId(sessionId?: number) {
  let inputValue = '';
  try {
    const notSendList = JSON.parse(sessionStorage.getItem('notSendList') || '[]');
    notSendList.forEach((item: any) => {
      if (item.id === sessionId) {
        inputValue = item.draftContent;
      }
    });
  } catch (error) {
    console.log(error);
  }
  return inputValue;
}

export function combineDraftAndSessionList(sessionList?: WxSession[], target?: Target) {
  let result: WxSession[] = [];
  try {
    if (sessionList) {
      const notSendList = JSON.parse(sessionStorage.getItem('notSendList') || '[]');
      const map = new Map();
      sessionList.forEach((item: any) => map.set(item.id, item));
      notSendList.forEach((item: any) => {
        if (map.get(item.id)) {
          if (target && target.id === item.id) {
            map.set(item.id, { ...map.get(item.id) });
          } else {
            map.set(item.id, { ...map.get(item.id), ...item });
          }
        }
      });
      result = Array.from(map.values());
    }
  } catch (error) {
    console.log(error);
  }
  return result;
}
